import { Link as RouterLink, LinkProps } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(RouterLink)`
  color: ${props => props.theme.text};
  text-decoration: underline;

  a:hover {
    color: ${props => props.theme.brandPurple};
  }
`

const Link = (props: LinkProps): JSX.Element => <StyledLink {...props} />
export default Link
