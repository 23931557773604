import React from 'react'
import { isArray, isEmpty } from 'lodash'
import styled from 'styled-components'

import { useFilters } from 'src/providers/FiltersProvider'

const StyledFilterCount = styled.div`
  font-size: 12px;
  margin-top: 4px;

  span {
    cursor: pointer;
    color: ${props => props.theme.buttonPrimaryBg};

    &:hover{
      text-decoration: underline;
    }
  }
`

const FilterCount: React.FC = () => {
  const { selectedFilters, allFilters, resetFilters } = useFilters()
  const filterCount: number = Object.entries(selectedFilters)
    .filter(([filterName, value]) => {
      const filterInfo = allFilters.find(f => f.name === filterName || filterName.includes(f.name))
      return filterInfo && !isEmpty(value)
    })
    .map(([filterName, value]) => isArray(value) ? value : [value])
    .reduce((prev, curr) => prev + curr.length, 0)

  if (filterCount < 1) {
    return null
  }

  return (
    <StyledFilterCount data-testid="filters-selected-value">
      {filterCount} Filters selected – <span onClick={resetFilters}>Remove</span>
    </StyledFilterCount>
  )
}

export default FilterCount
