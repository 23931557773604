import React, { ChangeEventHandler } from 'react'

import { SelectedFilter, SelectedFilterValue } from 'src/providers/FiltersProvider'

import { Filter, FilterOption } from './types'

type CheckboxProps = {
  filter: Filter,
  option: FilterOption,
  onCheck: (changeObject: SelectedFilter) => void,
  onUnCheck: (changeObject: SelectedFilter) => void,
  selection?: SelectedFilterValue
}

const Checkbox: React.FC<CheckboxProps> = ({ filter, option: { value, label }, onCheck, onUnCheck, selection }: CheckboxProps) => {
  const handleCheckboxChange: ChangeEventHandler<HTMLInputElement> = React.useCallback((event) => {
    if (event.currentTarget.checked) {
      onCheck({
        name: filter.name,
        value: event.currentTarget.value
      })
      return
    }

    onUnCheck({
      name: filter.name,
      value: event.currentTarget.value
    })
  }, [])

  return (
    <div>
      <label>
        <input
          type="checkbox"
          value={value}
          onChange={handleCheckboxChange}
          name={filter.name}
          checked={selection?.includes(value) ?? false}
        /> {label}
      </label>
    </div>
  )
}

export default Checkbox
