import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components'

import { ButtonProps, ButtonStyles } from './Button'

const StyledLinkButton = styled(Link)`
  ${ButtonStyles}
`

type LinkButtonProps = LinkProps & Omit<ButtonProps, 'onClick'>

const LinkButton: React.FC<LinkButtonProps> = ({ children, variation, slim, ...rest }: LinkButtonProps): JSX.Element => {
  const classess = [variation ?? 'primary', slim && 'slim']

  return (
    <StyledLinkButton className={classess.join(' ')}{...rest}>
      {children}
    </StyledLinkButton>
  )
}

export default LinkButton
