import React from 'react'
import { useLocalStorage } from 'react-use'
import { DefaultTheme } from 'styled-components'

import { DARK_THEME, LIGHT_THEME } from 'src/Themes'

type AppState = {
  theme: DefaultTheme,
  navOpen: boolean,
  changeTheme: (themeName: string) => void,
  toggleNav: () => void
}

const defaultState: AppState = {
  theme: LIGHT_THEME,
  navOpen: true,
  changeTheme: () => {},
  toggleNav: () => {}
}

const AppStateContext = React.createContext(defaultState)

export const useAppState = (): AppState => React.useContext(AppStateContext)

type AppStateStorage = {
  navOpen?: boolean,
  theme?: string
}

const DEFAULT_STATE: AppStateStorage = { theme: 'LIGHT', navOpen: true }

export const AppStateProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [appState, saveAppState] = useLocalStorage<AppStateStorage>('app-state', DEFAULT_STATE)
  const [theme, setTheme] = React.useState(appState?.theme === 'DARK' ? DARK_THEME : LIGHT_THEME ?? LIGHT_THEME)
  const [navOpen, setNavOpen] = React.useState<boolean>(appState?.navOpen ?? true)

  const handleToggleNav = React.useCallback(() => {
    setNavOpen(prev => {
      const newState = !prev
      saveAppState(prevAppState => ({
        ...prevAppState,
        navOpen: newState
      }))
      return newState
    })
  }, [])

  const handleChangeTheme = React.useCallback((themeName: string) => {
    setTheme(prev => {
      const newTheme = themeName === 'DARK' ? DARK_THEME : LIGHT_THEME
      saveAppState(prevAppState => ({
        ...prevAppState,
        theme: newTheme.name
      }))

      return newTheme
    })
  }, [])

  const value = {
    theme,
    navOpen,
    changeTheme: handleChangeTheme,
    toggleNav: handleToggleNav
  }

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  )
}
