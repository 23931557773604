import React from 'react'
import { ColumnDef, RowSelectionState } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import numeral from 'numeral'

import { BreakdownIndustry } from 'src/ApiResponseTypes'
import { createIndustryTree, IndustryRow } from 'src/components/IndustriesTable/utils'
import ExpandableTable from 'src/components/Table/ExpandableTable'
import useTable from 'src/components/Table/useTable'
import { ColumnPreset } from 'src/components/Table/utils'
import { useLocales } from 'src/providers/LocaleProvider'
import Box from 'src/ui/Box'
import { DEFAULT_NUMERAL_FORMAT } from 'src/utils/formatting'

type IndustriesTabProps = {
  initialSelection?: RowSelectionState,
  onChange: (selection: RowSelectionState) => void,
  industries: BreakdownIndustry[]
}

const IndustriesTab: React.FC<IndustriesTabProps> = ({ onChange, initialSelection, industries }: IndustriesTabProps) => {
  const { moneyFormat } = useLocales()
  const expandableList: IndustryRow[] = React.useMemo(() => createIndustryTree(industries), [industries])

  const columns: Array<ColumnDef<IndustryRow>> = React.useMemo(() => [
    {
      id: 'label',
      accessorKey: 'label',
      header: 'Name',
      enableSorting: true,
      meta: { flex: 2 }
    },
    {
      id: 'sales',
      accessorKey: 'sales',
      header: 'Net sales',
      enableSorting: true,
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    },
    {
      id: 'potential',
      accessorKey: 'potential',
      header: 'Potential',
      enableSorting: true,
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    },
    {
      id: 'active',
      accessorKey: 'active',
      header: 'Active',
      enableSorting: true,
      cell: (item) => `${numeral(item.getValue()).format(DEFAULT_NUMERAL_FORMAT)}`,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    },
    {
      id: 'passive',
      accessorKey: 'passive',
      header: 'Passive',
      enableSorting: true,
      cell: (item) => `${numeral(item.getValue()).format(DEFAULT_NUMERAL_FORMAT)}`,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    },
    {
      id: 'hunting',
      accessorKey: 'hunting',
      header: 'NB Target',
      enableSorting: true,
      cell: (item) => `${numeral(item.getValue()).format(DEFAULT_NUMERAL_FORMAT)}`,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    }
  ]
  , [])

  const { table } = useTable<IndustryRow>({
    id: 'market-analysis-industries',
    columns,
    data: expandableList,
    getSubRows: row => row.subRows,
    getRowId: row => row.external_id,
    enableRowSelection: true,
    enableExpanding: true,
    initialState: {
      rowSelection: initialSelection,
      pagination: { pageIndex: 0, pageSize: 20 }
    }
  })

  const selectedRows: RowSelectionState = table.getState().rowSelection

  React.useEffect(() => {
    onChange(selectedRows)
  }, [selectedRows])

  React.useEffect(() => {
    if (isEmpty(initialSelection)) {
      table.resetRowSelection()
    }
  }, [initialSelection])

  return (
    <Box><ExpandableTable table={table} title="Industries"/></Box>
  )
}

export default IndustriesTab
