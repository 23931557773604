import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import { useAppState } from 'src/providers/AppStateProvider'
import Tooltip from 'src/ui/Tooltip'

import Navigation, { NAV_CLOSED_WIDTH, NAV_OPEN_WIDTH } from './Navigation'

const Container = styled.main`
  display: flex;
  flex-direction: row;
  height: 100%;
  color: ${props => props.theme.text};
  container: container / inline-size;

  * {
    scrollbar-color: ${props => props.theme.scrollbarThumb} ${props => props.theme.scrollbarTrack};
  }

  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: ${props => props.theme.scrollbarThumb};
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.scrollbarThumb};
    border-radius: 6px;
    border: solid 3px ${props => props.theme.scrollbarThumb};
  }

  ::-webkit-scrollbar-track {
    border: solid 3px ${props => props.theme.scrollbarTrack};
  }

  ::-webkit-scrollbar-corner {
    background: ${props => props.theme.scrollbarCorner};
  }
`

type StyledContentProps = { $isOpen: boolean }
const Content = styled('div') <StyledContentProps>`
  width: calc(100% - ${props => props.$isOpen ? NAV_OPEN_WIDTH : NAV_CLOSED_WIDTH}px);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

const AppWrapper = styled.div`
  background: ${props => props.theme.appBackground};
  height: 100%;
`

const Layout: React.FC = () => {
  const { navOpen } = useAppState()
  return (
    <AppWrapper>
      <>
        <Container>
          <Navigation navOpen={navOpen}/>
          <Content $isOpen={navOpen}>
            <Outlet />
          </Content>
        </Container>
        <Tooltip id="global-tooltip" />
      </>
    </AppWrapper>
  )
}
export default Layout
