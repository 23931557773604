import React from 'react'
import ReactSelect, { MultiValue } from 'react-select'
import styled, { useTheme } from 'styled-components'

import { MultiFilterChangeObject, SelectedFilterValue } from 'src/providers/FiltersProvider'

import { selectStyles } from './Select'
import { Filter, FilterOption } from './types'

const StyledLabel = styled.div`
  font-size: 14px;
  padding: 2px 1px;
`

const MultiValueLabel: React.FC = (a: any) => {
  return (
    <StyledLabel>
      {a.children}
    </StyledLabel>
  )
}

type MultiSelectProps = {
  filter: Filter,
  onChange: (changeObjects: MultiFilterChangeObject) => void,
  selection?: SelectedFilterValue
}

const MultiSelect: React.FC<MultiSelectProps> = ({ filter, onChange, selection, ...rest }: MultiSelectProps) => {
  const theme = useTheme()
  const handleMultiSelectChange = React.useCallback((newValues: MultiValue<FilterOption | null>) => {
    const values: string[] = []
    newValues.forEach((item) => {
      if (item?.value) {
        values.push(item.value)
      }
    })

    onChange({
      name: filter.name,
      values
    })
  }, [])

  const selectedOptions: FilterOption[] = React.useMemo(() => filter.options.filter((option) => selection?.includes(option.value)), [selection])

  return (
    <div {...rest}>
      <ReactSelect
        value={selectedOptions}
        isMulti
        options={filter.options}
        onChange={handleMultiSelectChange}
        menuPortalTarget={document.body}
        closeMenuOnSelect={false}
        placeholder="Search"
        components={{
          MultiValueLabel
        }}
        styles={{
          ...selectStyles(theme),
          control: (baseStyles, state) => ({
            ...baseStyles,
            padding: '10px 10px',
            borderRadius: '30px',
            background: theme.contentBackground,
            color: theme.text,
            borderWidth: 1,
            borderColor: state.isFocused ? theme.brandPink : theme.borderColor,
            boxShadow: 'none',
            ':hover': {
              borderColor: theme.brandPink
            }
          }),
          multiValue: (baseStyles) => ({
            ...baseStyles,
            background: theme.brandPurple,
            color: theme.contentBackground,
            borderRadius: '16px',
            padding: '3px 8px'
          }),
          multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            fontSize: '14px'
          }),
          multiValueRemove: () => ({
            color: '#ffffff',
            cursor: 'pointer',
            marginLeft: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }),
          indicatorsContainer: (baseStyles, state) => ({
            ...baseStyles,
            svg: {
              fill: theme.borderColor
            }
          })
        }}
      />
    </div>
  )
}

export default MultiSelect
