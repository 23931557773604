import React from 'react'

import Box from './Box'
import Flex from './Flex'
import LoadingIndicator from './LoadingIndicator'

const FullPageLoadingIndicator: React.FC = () => {
  return (
    <Flex
      gridArea="left"
      gridColumn="left / right">
      <Box>
        <LoadingIndicator fullPage/>
      </Box>
    </Flex>
  )
}
export default FullPageLoadingIndicator
