import React from 'react'

import { KpiApiResponse } from 'src/ApiResponseTypes'
import { KpiCardWrapper } from 'src/components/KpiCard'
import { CardId, KpiCardProps } from 'src/components/KpiCard/KpiCardBase'
import { API } from 'src/config/paths'
import useAsyncDataLoader from 'src/hooks/useAsyncDataLoader'
import { useLocales } from 'src/providers/LocaleProvider'

import { getCardUnit } from './KpiCardWrapper'

type ConnectedKpiCardsProps = {
  cards: CardId[],
  filters?: Record<string, string | string[]>
}
const DATA_URL = `${API}/api/kpi`

const ConnectedKpiCards: React.FC<ConnectedKpiCardsProps> = ({ cards, filters }: ConnectedKpiCardsProps) => {
  const { data, loadData } = useAsyncDataLoader<KpiApiResponse>(DATA_URL)
  const locales = useLocales()

  const cardsData: KpiCardProps[] = cards
    .map((cardKey) => ({
      ...(data?.[cardKey]),
      id: cardKey,
      title: locales[cardKey] ?? cardKey,
      unit: getCardUnit(cardKey)
    }))

  React.useEffect(() => {
    loadData({
      ...filters,
      kpi: cards
    })
  }, [filters, cards])

  return (
    <KpiCardWrapper data={cardsData} style={{ gridTemplateColumns: 'repeat(2, 1fr)' }} filters={filters}/>
  )
}

export default ConnectedKpiCards
