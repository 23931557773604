import React from 'react'
import { TooltipProps } from 'recharts'

import ChartTooltip from 'src/ui/ChartTooltip'
import ChartTooltipTable from 'src/ui/ChartTooltipTable'

type SalesGraphTooltipProps = TooltipProps<number, string> & {
  yFormatter: (value: number) => string
}

const SalesGraphTooltip: React.FC<SalesGraphTooltipProps> = ({ payload, yFormatter }: SalesGraphTooltipProps) => {
  if (!payload?.[0]) {
    return null
  }

  const date = payload[0].payload.date

  return (
    <ChartTooltip>
      <h3>{date}</h3>
      <ChartTooltipTable payload={payload} />
    </ChartTooltip>
  )
}

export default SalesGraphTooltip
