import React from 'react'
import { generatePath } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'
import numeral from 'numeral'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'

import { CompaniesApiResponse, Company } from 'src/ApiResponseTypes'
import { BaseTableProps } from 'src/components/Table/BaseTable'
import ConnectedTable from 'src/components/Table/ConnectedTable'
import { ColumnPreset } from 'src/components/Table/utils'
import { API, Path } from 'src/config/paths'
import { getWhitespaceColumn } from 'src/pages/offerings/utils'
import { useFilters } from 'src/providers/FiltersProvider'
import { useLocales } from 'src/providers/LocaleProvider'
import Checkbox from 'src/ui/Checkbox'
import Link from 'src/ui/Link'
import { MenuItem } from 'src/ui/Menu'
import RadioButton from 'src/ui/RadioButton'
import Tag from 'src/ui/Tag'
import { PERCENTAGE_NUMERAL_FORMAT } from 'src/utils/formatting'

import { FilterOption } from '../Filters/types'

import LoadCompaniesToExcel from './LoadCompaniesToExcel'

const DATA_URL = `${API}/api/companies`

type Entities = FilterOption[]

export const getExtraColumns = (
  moneyFormat: string,
  extraColumns: string[],
  entities?: Entities
): Array<ColumnDef<Company>> => {
  if (!entities) {
    return []
  }

  const extraCols: Array<ColumnDef<Company>> = []
  entities.forEach((entity) => {
    if (extraColumns.includes('whitespace')) {
      extraCols.push({
        id: `whitespace-${entity.value}`,
        header: `Whitespace\n${entity.label}`,
        enableSorting: false,
        accessorKey: 'not-used',
        cell: ({ row: { original: row } }) => getWhitespaceColumn(entity, row),
        ...ColumnPreset.VERTICAL
      })
    }

    if (extraColumns.includes('potential')) {
      extraCols.push({
        id: `potential-${entity.value}`,
        header: `Potential\n${entity.label}`,
        enableSorting: false,
        accessorFn: (row) => row.potential_by_offering[entity.value] ?? 0,
        cell: ({ getValue }) => `${numeral(getValue()).format(moneyFormat)}`,
        ...ColumnPreset.VERTICAL_WIDE
      })
    }

    if (extraColumns.includes('sales')) {
      extraCols.push({
        id: `sales-${entity.value}`,
        header: `Sales\n${entity.label}`,
        enableSorting: false,
        accessorFn: (row) => row.sales_by_offering[entity.value] ?? 0,
        cell: ({ getValue }) => `${numeral(getValue()).format(moneyFormat)}`,
        ...ColumnPreset.VERTICAL_WIDE
      })
    }
  })

  return extraCols
}
export const getCompanyColumns = (moneyFormat: string, extraColumns: string[], entities?: Entities): Array<ColumnDef<Company>> => {
  const optionalColumns = getExtraColumns(moneyFormat, extraColumns, entities)
  return [
    {
      id: 'label',
      accessorKey: 'label',
      header: 'Name',
      cell: ({ row: { original: data } }) => (
        <Link to={generatePath(Path.COMPANY, { companyId: data.business_id })}>
          {data.label}
        </Link>
      ),
      minSize: 200,
      meta: {
        flex: 2
      }
    },
    {
      id: 'risk',
      accessorKey: 'max_risk',
      header: 'Risk',
      cell: (item) => item.getValue()
        ? <Tag variation="danger">{numeral(item.getValue()).format(PERCENTAGE_NUMERAL_FORMAT)}</Tag>
        : '',
      meta: { flex: 1 }
    },
    {
      id: 'readiness',
      accessorKey: 'max_readiness',
      header: 'Readiness',
      cell: (item) => item.getValue()
        ? <Tag variation="success">{numeral(item.getValue()).format(PERCENTAGE_NUMERAL_FORMAT)}</Tag>
        : '',
      meta: { flex: 1 }
    },
    {
      id: 'industry',
      accessorFn: (row) => row.industry.label,
      header: 'Industry',
      enableSorting: false,
      meta: { flex: 1 }
    },
    {
      id: 'turnover',
      accessorKey: 'turnover',
      header: 'Revenue',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`
    },
    {
      id: 'sales',
      accessorKey: 'sales',
      header: 'Net sales (LTM)',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`
    },
    {
      id: 'potential',
      accessorKey: 'potential',
      header: 'Potential',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`
    },
    {
      id: 'projection',
      accessorKey: 'projection',
      header: 'Projected',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      enableSorting: false,
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`
    },
    ...optionalColumns
  ]
}

type CompaniesTableProps = Omit<BaseTableProps<Company>, 'table'> & {
  title?: string
}

const CompaniesTable: React.FC<CompaniesTableProps> = ({ title, ...rest }: CompaniesTableProps) => {
  const { moneyFormat } = useLocales()
  const { allFilters } = useFilters()

  const [extraColumns, setExtraColumns] = React.useState<string[]>([])
  const [detailLevel, setDetailLevel] = React.useState<string>('businessUnit')
  const extraData = detailLevel === 'offering' ? allFilters[2]?.options : allFilters[1]?.options

  const handleDetailChange = React.useCallback((detail: string) => {
    setDetailLevel(detail)
  }, [])

  const columns: Array<ColumnDef<Company>> = React.useMemo(() => getCompanyColumns(moneyFormat, extraColumns, extraData)
    , [extraData, extraColumns])

  const handleChange: MenuClickEventHandler = React.useCallback(({ domEvent, key }) => {
    domEvent.preventDefault()

    setExtraColumns(prev => {
      if (prev.includes(key)) {
        return prev.filter(i => i !== key)
      }
      return [...prev, key]
    })
  }, [])

  return (
    <>
      <ConnectedTable<CompaniesApiResponse, Company>
        id="companies"
        dataUrl={DATA_URL}
        columns={columns}
        responseKey="companies"
        title={title ?? 'Companies'}
        actions={[
          <RadioButton
            small
            name="dataDetails"
            key="businessUnit"
            value="businessUnit"
            label="Business unit"
            onChange={handleDetailChange}
            selection={detailLevel}/>,
          <RadioButton
            small
            name="dataDetails"
            key="offering"
            value="offering"
            label="Offering"
            onChange={handleDetailChange}
            selection={detailLevel}/>
        ]}
        secondaryActions={[
          <LoadCompaniesToExcel key="excel"/>,
          <MenuItem key="whitespace" onClick={handleChange}>
            <Checkbox
              readOnly
              name="whitespace"
              checked={extraColumns.includes('whitespace')}/> Whitespace
          </MenuItem>,
          <MenuItem key="sales" onClick={handleChange}>
            <Checkbox
              readOnly
              name="sales"
              checked={extraColumns.includes('sales')}/> Sales
          </MenuItem>,
          <MenuItem key="potential" onClick={handleChange}>
            <Checkbox
              readOnly
              name="potential"
              checked={extraColumns.includes('potential')}/> Potential
          </MenuItem>

        ]}
        initialState={{ sorting: [{ id: 'label', desc: false }] }}
        {...rest} />
    </>
  )
}

export default CompaniesTable
