import { matchPath } from 'react-router-dom'
import { uniqBy } from 'lodash'

import { Path, SITEMAP } from 'src/config/paths'

import { BreadcrumbItem } from './types'

export const getActivePaths = (activePath: string): string[] => {
  const activePaths: string[] = []

  const segments = activePath.split('/')
  segments.reduce((val, segment) => {
    const nextVal = `${val}${segment}/`
    Object.values(Path).forEach((path) => {
      const match = matchPath(path, nextVal)
      if (match) {
        activePaths.push(path)
      }
    })
    return nextVal
  }, '')

  return activePaths
}

export const getActivePages = (activePaths: string[]): BreadcrumbItem[] => {
  const pages: BreadcrumbItem[] = []
  activePaths.forEach((path) => {
    let found: boolean = false
    SITEMAP.forEach((page) => {
      if (page.path === path) {
        found = true
        pages.push(page)
      }

      if (page.children !== undefined) {
        page.children.forEach((subpage) => {
          if (subpage.path === path) {
            found = true
            pages.push(subpage)
          }
        })
      }
    })

    if (!found) {
      pages.push({
        label: '',
        path
      })
    }
  })

  return uniqBy(pages, (page) => page.path)
}
