import styled from 'styled-components'

const KpiRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > * {
    border-bottom: 1px solid ${props => props.theme.borderColor};
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  > *:last-child {
    border-bottom: none;
  }
`
export default KpiRow
