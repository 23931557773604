export const toTitleCase = (str?: string): string => {
  if (!str) {
    return ''
  }

  return str
    .split('_').join(' ')
    .replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      }
    )
}
