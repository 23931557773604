import React from 'react'
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import { useTheme } from 'styled-components'

import { Penetration } from 'src/ApiResponseTypes'
import { useUserSettings } from 'src/providers/UserSettingsProvider'
import { getLabelStyles } from 'src/ui/BarChart'
import BasicTooltip from 'src/ui/BasicTooltip'

import { getChartData, legendFormatter, xFormatter } from './utils'

type PenetrationGraphProps = {
  penetration?: Penetration
}

const PenetrationGraph: React.FC<PenetrationGraphProps> = ({
  penetration
}: PenetrationGraphProps) => {
  const theme = useTheme()
  const { disableAnimations } = useUserSettings()

  if (!penetration ||
    Object.keys(penetration.companies).length < 1 ||
    Object.keys(penetration.sales).length < 1 ||
    Object.keys(penetration.arpa).length < 1) {
    return <span>Not enough data</span>
  }

  const chartData = React.useMemo(() => getChartData(penetration), [penetration])

  return (
    <ResponsiveContainer
      minHeight="350px"
      width='100%'
      height='100%'
    >
      <ComposedChart data={chartData} margin={{ top: 30, left: 0 }}>
        <CartesianGrid
          strokeDasharray='1'
          stroke={theme.borderColor}
          vertical={false}/>
        <XAxis
          dataKey='xTick'
          tickFormatter={xFormatter}
          tick={getLabelStyles(theme)}
        />

        <Legend formatter={legendFormatter} />

        <Bar
          isAnimationActive={!disableAnimations}
          dataKey="companies"
          fill={theme.dataColors[0]}
          yAxisId="companies" />

        <Line
          isAnimationActive={!disableAnimations}
          type="linear"
          dataKey="sales"
          stroke={theme.dataColors[1]}
          fill={theme.dataColors[1]}
          strokeWidth={1.5}
          yAxisId="sales"
        />

        <Line
          isAnimationActive={!disableAnimations}
          type="linear"
          dataKey="arpa"
          stroke={theme.dataColors[2]}
          fill={theme.dataColors[2]}
          strokeWidth={1.5}
        />

        <Tooltip content={<BasicTooltip />} />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default PenetrationGraph
