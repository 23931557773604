import moment from 'moment'
import numeral from 'numeral'

import { GraphData, ISODate } from 'src/ApiResponseTypes'
import { DEFAULT_NUMERAL_FORMAT } from 'src/utils/formatting'
import { toTitleCase } from 'src/utils/toTitleCase'

import { ProjectionGraphDataObject } from './types'

export const xFormatter = (value: string): string => value
export const yFormatter = (value: number): string => numeral(value).format(DEFAULT_NUMERAL_FORMAT)
export const legendFormatter = (value: string): string => toTitleCase(value)

export const getChartData = (
  history: GraphData,
  projections: GraphData
): ProjectionGraphDataObject[] => Object.keys(projections)
  .map((key) => key as keyof GraphData)
  .map((key) => {
    const projectionDate = moment(key)
    const projectionValue: number = projections[key]

    const salesDate = moment(key).subtract(1, 'year')
    const salesKey: keyof GraphData = salesDate.format('YYYY-MM-DD') as ISODate
    const salesValue = history[salesKey] ?? undefined

    const diffPercentage = Math.round(projectionValue / salesValue * 100) - 100
    return {
      sales: salesValue,
      projection: projectionValue,
      diffPercentage,
      xTick: moment(key).format('MMM'),
      dates: {
        sales: salesDate,
        projection: projectionDate
      },
      month: salesDate.format('MMMM')
    }
  })
