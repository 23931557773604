import React from 'react'
import { ColumnDef } from '@tanstack/react-table'

import { BusinessGroup, CompaniesApiResponse, Company, Offering } from 'src/ApiResponseTypes'
import { getCompanyColumns } from 'src/components/CompaniesTable/CompaniesTable'
import LoadCompaniesToExcel from 'src/components/CompaniesTable/LoadCompaniesToExcel'
import ConnectedTable from 'src/components/Table/ConnectedTable'
import { API } from 'src/config/paths'
import { useLocales } from 'src/providers/LocaleProvider'

const DATA_URL = `${API}/api/companies`

type CustomersTableProps = {
  entity: BusinessGroup | Offering
}

const CustomersTable: React.FC<CustomersTableProps> = ({ entity }: CustomersTableProps) => {
  const { moneyFormat } = useLocales()
  const columns: Array<ColumnDef<Company>> = React.useMemo(() => [
    ...getCompanyColumns(moneyFormat, ['whitespace'], [{
      value: entity.external_id,
      label: entity.label
    }])
  ], [])

  return <ConnectedTable<CompaniesApiResponse, Company>
    id="offering-customers"
    dataUrl={DATA_URL}
    columns={columns}
    responseKey="companies"
    title="Customers"
    showFilters
    secondaryActions={<LoadCompaniesToExcel />}
  />
}

export default CustomersTable
