import React from 'react'
import { generatePath } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'
import { ColumnDef, RowSelectionState } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import numeral from 'numeral'

import { BreakdownOffering } from 'src/ApiResponseTypes'
import SimpleTable from 'src/components/Table/SimpleTable'
import useTable from 'src/components/Table/useTable'
import { ColumnPreset } from 'src/components/Table/utils'
import { Path } from 'src/config/paths'
import { useLocales } from 'src/providers/LocaleProvider'
import Box from 'src/ui/Box'
import Link from 'src/ui/Link'
import { DEFAULT_NUMERAL_FORMAT } from 'src/utils/formatting'

type OfferingsTabProps = {
  initialSelection?: RowSelectionState,
  onChange: (selection: RowSelectionState) => void,
  offerings: BreakdownOffering[]
}

const OfferingsTab: React.FC<OfferingsTabProps> = ({ onChange, initialSelection, offerings }: OfferingsTabProps) => {
  const { moneyFormat } = useLocales()
  const columns: Array<ColumnDef<BreakdownOffering>> = React.useMemo(() => [
    {
      id: 'label',
      accessorKey: 'label',
      header: 'Name',
      cell: ({ row: { original: data } }) => <Link to={generatePath(Path.OFFERING, { offeringId: data.external_id })}>{data.label}</Link>,
      meta: { flex: 2 }
    },
    {
      id: 'sales',
      accessorKey: 'sales',
      header: 'Net sales',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(moneyFormat)
    },
    {
      id: 'potential',
      accessorKey: 'potential',
      header: 'Potential',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(moneyFormat)
    },
    {
      id: 'active',
      accessorKey: 'active',
      header: 'Active',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(DEFAULT_NUMERAL_FORMAT)
    },
    {
      id: 'passive',
      accessorKey: 'passive',
      header: 'Passive',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(DEFAULT_NUMERAL_FORMAT)
    },
    {
      id: 'hunting',
      accessorKey: 'hunting',
      header: 'NB Targets',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(DEFAULT_NUMERAL_FORMAT)
    }
  ], [])

  const { table } = useTable<BreakdownOffering>({
    id: 'market-analysis-offerings',
    columns,
    data: offerings,
    getRowId: row => row.external_id,
    enableRowSelection: true,
    initialState: {
      rowSelection: initialSelection,
      pagination: { pageIndex: 0, pageSize: 20 }
    },
    manualPagination: false
  })

  React.useEffect(() => {
    if (isEmpty(initialSelection)) {
      table.resetRowSelection()
    }
  }, [initialSelection])

  const selectedRows: RowSelectionState = table.getState().rowSelection

  useUpdateEffect(() => {
    onChange(selectedRows)
  }, [selectedRows])

  return (
    <Box><SimpleTable table={table} title="Offering groups" /></Box>
  )
}

export default OfferingsTab
