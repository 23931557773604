import React, { ChangeEventHandler } from 'react'
import styled from 'styled-components'

import Text from './Text'

const Label = styled.label`
  cursor: pointer;
  display:flex;
  align-items:center;
  justify-content: center;

  input {
    cursor: pointer;
    margin-top: -2px;
  }
`

type RadioButtonProps = {
  name: string,
  value: string,
  label: string,
  selection?: string,
  onChange: (name: string) => void,
  small?: boolean
}

const RadioButton: React.FC<RadioButtonProps> = ({ name, label, value, onChange, selection, small }: RadioButtonProps) => {
  const handleRadioChange: ChangeEventHandler<HTMLInputElement> = React.useCallback((event) => {
    const value = event.currentTarget.value
    onChange(value)
  }, [])

  return (
    <Label>
      <input
        type="radio"
        name={name}
        value={value}
        onChange={handleRadioChange}
        checked={selection === value}/> <Text small={small}>{label}</Text>
    </Label>
  )
}

export default RadioButton
