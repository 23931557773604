import React from 'react'
import styled from 'styled-components'

import { LIGHT_THEME } from 'src/Themes'

const Spinner = styled('div')`
  width: 30px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${LIGHT_THEME.brandPink};
  --_m:
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;

  @keyframes l3 {to{transform: rotate(1turn)}}
`

const Dots = styled('div')`
  width: 30px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side,${LIGHT_THEME.brandPink} 90%, #0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: stepping 2s steps(4) infinite;

  @keyframes stepping {to{clip-path: inset(0 -34% 0 0)}}
`

type StyledIndicatorProps = { $fullPage: boolean }
const IndicatorWrapper = styled('div')<StyledIndicatorProps>`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px;

  ${props => props.$fullPage && `
    width: 100%;
    height: 100%;
  `}
`

type LoadingIndicatorProps = {
  fullPage?: boolean
}
const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  fullPage = false
}: LoadingIndicatorProps) => {
  if (fullPage) {
    return (
      <IndicatorWrapper $fullPage={fullPage ?? false}>
        <Spinner/>
      </IndicatorWrapper>
    )
  }

  return (
    <IndicatorWrapper $fullPage={fullPage ?? false}>
      <Dots/>
    </IndicatorWrapper>
  )
}

export default LoadingIndicator
