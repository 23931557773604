import { Table } from '@tanstack/react-table'
import styled from 'styled-components'

const StyledSelect = styled.select`
  padding: 6px 10px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.borderColor};
  background-color: ${props => props.theme.contentBackground};
  color: ${props => props.theme.text};
  font-size: 12px;

  &:focus {
    outline: none;
  }
`

type PageSizeSelectProps<T> = {
  table: Table<T>
}

const PAGE_SIZES = [10, 20, 30, 40, 50]
const PageSizeSelect = <T,>({ table }: PageSizeSelectProps<T>): JSX.Element | null => {
  if (table.getRowModel().rows.length < PAGE_SIZES[0]) {
    return null
  }
  return (
    <div>
      <StyledSelect
        data-testid="pagination-page-size"
        value={table.getState().pagination.pageSize}
        onChange={e => {
          table.setPageSize(Number(e.target.value))
        }}
      >
        {PAGE_SIZES.map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </StyledSelect>
    </div>
  )
}

export default PageSizeSelect
