import styled from 'styled-components'

import { ReactChildren } from 'src/types'

const StyledTag = styled.div`
  display: inline-block;
  border-radius: 24px;
  padding: 4px 10px;
  font-size: 13px;
  color: #ffffff;

  &.success {
    background: ${props => props.theme.success};
  }

  &.danger {
    background: ${props => props.theme.danger};
  }

  &.info {
    background: ${props => props.theme.info};
  }
`

type TagType = 'success' | 'danger' | 'info'
type TagProps = React.HTMLAttributes<HTMLDivElement> & {
  children: ReactChildren,
  variation?: TagType
}

const Tag: React.FC<TagProps> = ({
  children,
  variation,
  ...rest
}: TagProps): JSX.Element => (
  <StyledTag className={variation ?? 'success'} {...rest}>
    {children}
  </StyledTag>
)

export default Tag
