import React from 'react'
import styled from 'styled-components'

import { useFilters } from 'src/providers/FiltersProvider'
import Text from 'src/ui/Text'

const StyledFilterButton = styled(Text)`
  cursor: pointer;
  color: ${props => props.theme.secondaryText};
  text-decoration: underline;

  &:hover{
    text-decoration: underline;
  }`

type FilterButtonProps = {
  hideWhenOpen?: boolean
}

const FilterButton: React.FC<FilterButtonProps> = ({ hideWhenOpen = false }: FilterButtonProps) => {
  const { toggleFiltersOpen, isFiltersOpen } = useFilters()

  if (hideWhenOpen && isFiltersOpen) {
    return null
  }

  return (
    <>
      <StyledFilterButton
        onClick={toggleFiltersOpen}
        data-testid="filters-sidebar-toggle"
        fontSize={12}>
        {isFiltersOpen ? 'Hide Filters' : 'Show Filters'}
      </StyledFilterButton>
    </>
  )
}

export default FilterButton
