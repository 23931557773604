import styled from 'styled-components'
import { alignItems, AlignItemsProps, flex, flexDirection, FlexDirectionProps, FlexProps, gridArea, GridAreaProps, gridColumn, GridColumnProps, height, HeightProps, justifyContent, JustifyContentProps, margin, MarginProps, overflow, OverflowProps, padding, PaddingProps, width, WidthProps } from 'styled-system'

import { STYLED_CONFIG } from 'src/utils/styled-utils'

export const LAYOUT_GAP = 16

type StyledFlexProps = FlexProps
& WidthProps
& HeightProps
& FlexDirectionProps
& OverflowProps
& GridAreaProps
& GridColumnProps
& AlignItemsProps
& JustifyContentProps
& MarginProps
& PaddingProps

type FlexDividerProps = GridAreaProps
export const FlexDivider = styled('div')
  .withConfig(STYLED_CONFIG)<FlexDividerProps>`
  background: ${props => props.theme.borderColor};
  width: 100%;
  height: 100%;
  ${gridArea};
`

const Flex = styled('div')
  .withConfig(STYLED_CONFIG)<StyledFlexProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1 1 auto;
  overflow: hidden;
  ${flex};
  ${flexDirection};
  ${width};
  ${height};
  ${overflow};
  ${gridArea};
  ${gridColumn};
  ${alignItems};
  ${justifyContent};
  ${margin};
  ${padding};
`

export default Flex
