import React from 'react'
import RcMenu, { Item, MenuProps as RcMenuProps } from 'rc-menu'
import styled from 'styled-components'

import { ReactChildren } from 'src/types'

import 'rc-menu/assets/index.css'

export const MenuItem = styled(Item)`
  padding: 12px 20px 12px 16px !important;
  cursor: pointer;
  color: ${props => props.theme.text};
  font-family: "Roboto Flex", sans-serif;
  background: ${props => props.theme.contentBackground};
  display: flex;
  align-items: center;
  gap: 5px;

  svg circle,
  svg rect,
  svg path {
    stroke: ${props => props.theme.text};
  }

  &:hover {
    background: ${props => props.theme.navItemActiveBg};
    color: ${props => props.theme.navItemActiveText};

    svg, rect,path {
      stroke:${props => props.theme.navItemActiveText};
    }
  }
`

const StyledMenu = styled(RcMenu)`
  font-size: 14px !important;
  font-family: "Roboto Flex", sans-serif !important;
  color: ${props => props.theme.text} !important;
  border-radius: 10px !important;
  overflow: hidden;

  ul,
  ul:before,
  li {
    font-family: "Roboto Flex", sans-serif !important;
  }
`

type MenuProps = RcMenuProps & {
  children: ReactChildren
}

const Menu: React.FC<MenuProps> = ({ children }: MenuProps) => {
  return (
    <StyledMenu mode="vertical">
      {children}
    </StyledMenu>
  )
}

export default Menu
