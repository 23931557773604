import React from 'react'
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useTheme } from 'styled-components'

import { GraphData } from 'src/ApiResponseTypes'
import { useUserSettings } from 'src/providers/UserSettingsProvider'
import { getLabelStyles } from 'src/ui/BarChart'

import SalesGraphTooltip from './SalesGraphTooltip'
import { getChartData, legendFormatter, xFormatter, yFormatter } from './utils'

const CustomTooltip = <SalesGraphTooltip yFormatter={yFormatter} />

type SalesGraphProps = {
  history?: GraphData,
  averages?: GraphData
}

const SalesGraph: React.FC<SalesGraphProps> = ({
  history,
  averages
}: SalesGraphProps) => {
  const theme = useTheme()
  const { disableAnimations } = useUserSettings()

  if (!history || !averages) {
    return <span>Not enough data</span>
  }

  if (Object.keys(history).length < 1 ||
    Object.keys(averages).length < 1) {
    return <span>Not enough data</span>
  }

  const chartData = React.useMemo(() => getChartData(history, averages), [history, averages])
  return (

    <ResponsiveContainer
      width='100%'
      height='100%'
      minHeight="350px"
    >
      <ComposedChart data={chartData}>
        <CartesianGrid
          strokeDasharray='1'
          stroke={theme.borderColor}
          vertical={false}/>
        <XAxis
          dataKey='xTick'
          tickFormatter={xFormatter}
          tick={getLabelStyles(theme)}
        />
        <YAxis
          type='number'
          tickSize={0}
          tickFormatter={yFormatter}
          tick={getLabelStyles(theme)}
          scale="linear"
          interval="preserveStartEnd"
          domain={['dataMin', 'dataMax']}
        />
        <Legend formatter={legendFormatter} />

        <Bar dataKey="sales" fill={theme.dataColors[0]} isAnimationActive={!disableAnimations} />
        <Line
          isAnimationActive={!disableAnimations}
          type="linear"
          dataKey="average"
          stroke={theme.dataColors[1]}
          strokeWidth={1.5}
        />

        <Tooltip formatter={legendFormatter} content={CustomTooltip} />
      </ComposedChart>
    </ResponsiveContainer>

  )
}

export default SalesGraph
