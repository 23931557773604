import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { gridArea, GridAreaProps } from 'styled-system'

import { ReactChildren } from 'src/types'
import Link from 'src/ui/Link'
import { STYLED_CONFIG } from 'src/utils/styled-utils'

export const TABS_HEIGHT = '50px'

type TabListProps = GridAreaProps

const TabList = styled('div').withConfig(STYLED_CONFIG)<TabListProps>`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  margin: 0;
  overflow: hidden;
  height: ${TABS_HEIGHT};
  ${gridArea};

  a {
    text-decoration: none;
    padding: 0 24px;
    height: ${TABS_HEIGHT};
    min-width: 100px;
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.borderColor};
    background: ${props => props.theme.navBackground};
    color: ${props => props.theme.navItemText};
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.active,
    &:hover {
      padding: 3px 24px 1px;
    }

    &:hover {
      border-bottom: 3px solid ${props => props.theme.navItemHoverText};
      color: ${props => props.theme.navItemHoverText};
    }

    &.active {
      border-bottom: 3px solid ${props => props.theme.navItemHoverText};
      background: ${props => props.theme.navItemActiveBg};
      color: ${props => props.theme.navItemActiveText};
    }
  }
`

export type Tab = {
  path: string,
  label: string,
  children?: ReactChildren
}

export type TabProps = GridAreaProps & {
  tabs: Tab[]
}

const Tabs: React.FC<TabProps> = ({ tabs, ...res }: TabProps) => {
  const { pathname } = useLocation()
  return (
    <TabList {...res}>
      {tabs.map(({ label, path, children }) => (
        <Link
          role="tab"
          key={path}
          to={path}
          className={path === pathname ? 'active' : ''}
        >
          <span>{label}</span>
          {children}
        </Link>
      ))}
    </TabList>
  )
}
export default Tabs
