import React from 'react'
import { isEmpty } from 'lodash'
import styled, { css } from 'styled-components'

import { useFilters } from 'src/providers/FiltersProvider'
import Button from 'src/ui/Button'
import ErrorText from 'src/ui/ErrorText'
import Flex from 'src/ui/Flex'
import LoadingIndicator from 'src/ui/LoadingIndicator'

import ErrorBoundary from '../ErrorBoundary'
import Icon, { StyledIcon } from '../Icon'

import Checkbox from './Checkbox'
import FilterButton from './FilterButton'
import FilterInput from './FilterInput'
import Multidimentional from './Multidimentional'
import MultiSelect from './MultiSelect'
import Radio from './Radio'
import Select from './Select'

export const FILTERS_WIDTH = 320
const StyledFilters = styled.div<{ $standalone: boolean }>`
  width: ${FILTERS_WIDTH}px;
  flex: 0 0 auto;
  overflow-y: auto;

  ${props => props.$standalone && css`
    background: ${props => props.theme.contentBackground};
    padding: 40px 16px 16px 16px;
  `}
`

const FilterItem = styled.div`
  margin-bottom: 16px;
`

const FilterContent = styled.div`
  width: 100%;
`

const FilterTitle = styled.h4`
  font-size: 13px;
  font-weight: 400;
  margin: 0 0 8px 8px;
  color: ${props => props.theme.gray}
`

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding:0;
  cursor: pointer;
  user-select: none;
  padding: 0 0 24px;

  h3 {
    margin:0;
    padding:0;
    font-size: 14px;
    text-transform: uppercase;
  }

  ${StyledIcon} {
    cursor: pointer;
  }
`

type FiltersProps = {
  showFilterButtonWhenClosed?: boolean,
  showFilterButtonWhenOpen?: boolean,
  standalone?: boolean,
  error?: Error
}

const Filters: React.FC<FiltersProps> = ({
  showFilterButtonWhenClosed = false,
  showFilterButtonWhenOpen = true,
  standalone = false,
  error
}: FiltersProps) => {
  const {
    allFilters: filters,
    toggleFilter,
    addFilter,
    addMultipleFilters,
    updateFilterValue,
    removeFilterValue,
    clearFilter,
    resetFilters,
    selectedFilters,
    isFiltersOpen,
    toggleFiltersOpen
  } = useFilters()

  const isLoaded = filters.length > 0 && !error

  const visibleFilters = React.useMemo(() => filters
    .map((filter) => ({
      ...filter,
      options: filter.options.filter((option) => {
        if (filter.parentFilterId && option.parentId) {
          const parentValue = selectedFilters[filter.parentFilterId]
          if (parentValue && !isEmpty(parentValue)) {
            return parentValue.includes(option.parentId)
          } else {
            return true
          }
        }

        return true
      })
    })), [filters, selectedFilters])

  if (!isFiltersOpen) {
    return showFilterButtonWhenClosed ? <FilterButton /> : null
  }

  return (
    <StyledFilters data-testid="filters-sidebar" $standalone={standalone}>
      <ErrorBoundary error={error} fallback={<Flex height="100%"><ErrorText text="Failed to load filters" /></Flex>}>
        <FilterContent>
          {showFilterButtonWhenOpen && (
            <FilterHeader onClick={toggleFiltersOpen}>
              <h3>Filters</h3>
              <div><Icon name="Chevron" size={12}/></div>
            </FilterHeader>
          )}
          {!isLoaded && <LoadingIndicator />}
          {visibleFilters.map((filter) => (
            <FilterItem key={filter.name}>
              <FilterTitle>{filter.label}</FilterTitle>

              {filter.type === 'select' && (
                <Select
                  data-testid={`filter-${filter.name}`}
                  key={filter.name}
                  filter={filter}
                  onClear={clearFilter}
                  onChange={toggleFilter}
                  selection={selectedFilters[filter.name]}
                  isClearable={filter.isClearable}
                />
              )}

              {filter.type === 'multiselect' && (
                <MultiSelect
                  data-testid={`filter-${filter.name}`}
                  key={filter.name}
                  filter={filter}
                  onChange={addMultipleFilters}
                  selection={selectedFilters[filter.name]}
                />
              )}

              {filter.type === 'checkbox' && (
                <>
                  {filter.options.map((option) => (
                    <Checkbox
                      data-testid={`filter-${filter.name}`}
                      key={option.value}
                      filter={filter}
                      option={option}
                      onCheck={addFilter}
                      onUnCheck={removeFilterValue}
                      selection={selectedFilters[filter.name]}
                    />
                  ))}
                </>
              )}

              {filter.type === 'radio' && (
                <>
                  {filter.options.map((option) => (
                    <Radio
                      data-testid={`filter-${filter.name}`}
                      key={option.value}
                      filter={filter}
                      option={option}
                      onChange={toggleFilter}
                      selection={selectedFilters[filter.name] ?? undefined}
                    />
                  ))}
                </>
              )}

              {filter.type === 'static' && (
                <span>{filter.options.find(item => item.value === selectedFilters[filter.name])?.label}</span>
              )}

              {filter.type === 'text' && (
                <FilterInput
                  data-testid={`filter-${filter.name}`}
                  filter={filter}
                  onChange={updateFilterValue}
                  value={selectedFilters[filter.name] ?? undefined}/>
              )}

              {filter.type === 'multidimentional' && (
                <Multidimentional
                  data-testid={`filter-${filter.name}`}
                  key={filter.name}
                  filter={filter}
                  selectedFilters={selectedFilters}/>
              )}

            </FilterItem>
          ))}

          {isLoaded && (
            <Button
              data-testid="reset-filters-button"
              onClick={resetFilters}
              variation='secondary'
              style={{ width: '100%', margin: '15px 0' }}>
            Reset {visibleFilters.length > 1 ? 'all' : ''}
            </Button>
          )}
        </FilterContent>
      </ErrorBoundary>
    </StyledFilters>
  )
}

export default Filters
