import React from 'react'

import { GraphData } from 'src/ApiResponseTypes'
import BarChart from 'src/ui/BarChart'

import ProjectionGraphTooltip from './ProjectionTooltip'
import { getChartData, legendFormatter, xFormatter, yFormatter } from './utils'

const CustomTooltip = <ProjectionGraphTooltip yFormatter={yFormatter} />

type ProjectionGraphProps = {
  history?: GraphData,
  projections?: GraphData
}

const ProjectionGraph: React.FC<ProjectionGraphProps> = ({
  history,
  projections
}: ProjectionGraphProps) => {
  if (!history || !projections) {
    return <span>Not enough data</span>
  }

  if (Object.keys(history).length < 1 ||
    Object.keys(projections).length < 1) {
    return <span>Not enough data</span>
  }

  const chartData = React.useMemo(() => getChartData(history, projections), [history, projections])

  return (
    <BarChart
      customTooltip={CustomTooltip}
      dataPoints={['sales', 'projection']}
      data={chartData}
      yFormatter={yFormatter}
      xFormatter={xFormatter}
      tooltipFormatter={legendFormatter}
      legendFormatter={legendFormatter}
    />
  )
}

export default ProjectionGraph
