import React from 'react'
import { useUpdateEffect } from 'react-use'
import {
  ColumnDef,
  TableState
} from '@tanstack/react-table'

import { ApiStatusType } from 'src/ApiResponseTypes'
import useTableDataLoader from 'src/hooks/useTableDataLoader'
import { useFilters } from 'src/providers/FiltersProvider'
import Box from 'src/ui/Box'

import BaseTable, { BaseTableProps } from './BaseTable'
import useTable from './useTable'
import { getDefaultTableSettings } from './utils'

export const TABLE_QUERIES = ['page', 'sort', 'order']

type DataResponse<TData> = TData & {
  pages?: number,
  count?: number,
  status?: string
}

export type ConnectedTableProps<APIResponse, DataModel> = Omit<BaseTableProps<DataModel>, 'table'> & {
  id: string,
  responseKey: keyof APIResponse,
  dataUrl: string,
  columns: Array<ColumnDef<DataModel>>,
  initialState?: Partial<TableState>
}

const ConnectedTable = <APIResponse, DataModel>({
  id,
  dataUrl,
  columns,
  responseKey,
  initialState,
  ...rest
}: ConnectedTableProps<APIResponse, DataModel>): JSX.Element => {
  const { isLoading, data, loadData, error } = useTableDataLoader<DataResponse<APIResponse>>(dataUrl)
  const { selectedFilters } = useFilters()
  const tableData = React.useMemo(() => data ? data[responseKey] : [], [data])

  React.useEffect(() => {
    loadData(table.getState(), selectedFilters)
  }, [])

  const { table } = useTable<DataModel>({
    ...getDefaultTableSettings(),
    id,
    data: tableData as DataModel[],
    columns,
    manualPagination: true,
    manualSorting: true,
    pageCount: data?.pages,
    rowCount: data?.count,
    initialState
  })

  const tableState = table.getState()
  const { pagination: { pageIndex, pageSize }, sorting } = tableState

  useUpdateEffect(() => {
    loadData(tableState, selectedFilters)
  }, [
    pageIndex,
    pageSize,
    sorting,
    selectedFilters
  ])

  if (data?.status === ApiStatusType.ERROR) {
    return <Box><p>Error: The data could not be found</p></Box>
  }

  return (
    <BaseTable table={table} isLoading={isLoading} error={error} {...rest} />
  )
}

export default ConnectedTable
