import { isNumber } from 'lodash'
import numeral from 'numeral'
import styled, { css } from 'styled-components'

import { useLocales } from 'src/providers/LocaleProvider'
import { ReactChildren } from 'src/types'
import { ROUNDED_NUMERAL_FORMAT } from 'src/utils/formatting'

type StyleProps = { width?: string }

const StyledBox = styled.div<StyleProps>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  padding: 42px;

  ${({ width }) => width && css`
    width: ${width};
  `}

  h2 {
    font-size: 30px;
    font-weight: bold;
    margin: 20px 0 0 0;
    padding:0;
  }
`

type KpiEntryProps = StyleProps & {
  title: string,
  children?: ReactChildren,
  value?: number | string,
  unit?: string,
  style?: React.CSSProperties
}

const KpiEntry: React.FC<KpiEntryProps> = ({ title, value, unit, style, children }: KpiEntryProps) => {
  const { moneyFormatShort } = useLocales()
  return (
    <StyledBox style={style} data-testid={`kpi-${title}`}>
      <h3>{title}</h3>
      {children}
      {value !== undefined && (<h2>{isNumber(value) ? numeral(value).format(unit === 'currency' ? moneyFormatShort : ROUNDED_NUMERAL_FORMAT) : value}</h2>)}
    </StyledBox>
  )
}

export default KpiEntry
