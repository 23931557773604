import React from 'react'
import styled from 'styled-components'

import { SelectionState } from 'src/providers/FiltersProvider'

import KpiCardBase, { KpiCardProps } from './KpiCardBase'

export const getCardUnit = (cardKey: string): string => {
  if (cardKey === 'sales' ||
    cardKey === 'arpa') {
    return 'currency'
  }

  if (cardKey === 'pareto') {
    return '%'
  }

  return ''
}

const CardsContainer = styled.div`
  container: article / inline-size;
`

const CardsWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
`

export type KPICardWrapperProps = {
  data: KpiCardProps[],
  style?: React.CSSProperties,
  filters?: SelectionState,
  error?: Error
}

const KPICardWrapper = ({ data, style, filters, error }: KPICardWrapperProps): React.ReactElement => (
  <CardsContainer>
    <CardsWrapper key="card-row" style={style} className="CardsWrapper">
      {data.map((card, index) => (
        <KpiCardBase
          error={error}
          key={`${card.title}-${index}`}
          {...card}
          unit={getCardUnit(card.id)}
          filters={filters} />
      ))}
    </CardsWrapper>
  </CardsContainer>
)

export default KPICardWrapper
