import styled from 'styled-components'
import { gridTemplateAreas, GridTemplateAreasProps } from 'styled-system'

import { TABS_HEIGHT } from 'src/components/Tabs'
import { STYLED_CONFIG } from 'src/utils/styled-utils'

import { LAYOUT_GAP } from './Flex'

export const BASIC_LAYOUT = `
  "tab tab tab"
  "top top top"
  "left divider right"
`

type PageContentProps = GridTemplateAreasProps
const PageContent = styled('div').withConfig(STYLED_CONFIG)<PageContentProps>`
  height: 100%;
  width: calc(100% - ${LAYOUT_GAP}px);
  overflow: auto;
  background: ${props => props.theme.contentBackground};
  border-radius: ${props => props.theme.borderRadius};
  margin: 0 0 ${LAYOUT_GAP}px 0;
  border: 1px solid ${props => props.theme.borderColor};
  display: grid;
  grid-template-columns: calc(50% - 1px) 1px 50%;
  grid-template-rows: fit-content(${TABS_HEIGHT}) fit-content(120px) 1fr;
  align-content: start;
  justify-content: stretch;
  grid-template-areas: ${BASIC_LAYOUT};
  ${gridTemplateAreas};

  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: repeat(100%);
    grid-template-rows: auto auto auto 1px auto;
    grid-template-areas:
      "tab tab tab"
      "top top top"
      "left left left"
      "divider divider divider"
      "right right right";
  }

`

export default PageContent
