import { ReactNode } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styled from 'styled-components'

import { ReactChildren } from 'src/types'
import { GlobalTooltipStyles } from 'src/ui/ChartTooltip'

const StyledTooltip = styled(ReactTooltip)`
  ${GlobalTooltipStyles};
  max-width: 320px !important;
  z-index: 999;
  white-space: wrap;
`

type TooltipProps = {
  id: string,
  children?: ReactChildren | ReactNode
}

const Tooltip: React.FC<TooltipProps> = ({ id, children }: TooltipProps) => (
  <StyledTooltip id={id}>
    {children}
  </StyledTooltip>
)

export default Tooltip
