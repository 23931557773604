import React from 'react'
import { isArray, isEmpty } from 'lodash'
import styled from 'styled-components'

import { SelectionState } from 'src/providers/FiltersProvider'

import { Filter } from './types'

const StyledMultidimentional = styled.div`
  font-size: 14px;
  padding: 0px 16px;
`

const Section = styled.div`
  margin: 16px 0 0 0;
`

const HighlightSpan = styled('span')`
  display: inline-block;
  padding: 0 4px;
  color: ${props => props.theme.brandPurple} !important;
`

type MultidimentionalProps = {
  filter: Filter,
  selectedFilters?: SelectionState
}

/*
  TODO:
  This component is 100% geard to turnover_groups and turning it into Current value and potential.
  This needs to be refactored if we ever have more of these multidimentional filters
*/

const TITLE_TYPE = 'Current value'
const VALUE_TYPE = 'the potential'
const Multidimentional: React.FC<MultidimentionalProps> = ({ filter, selectedFilters, ...rest }: MultidimentionalProps) => {
  const filterData = React.useMemo(() => Object.entries(selectedFilters ?? {})
    .filter(([key, value]) => key.includes(filter.name) && !isEmpty(value))
  , [selectedFilters])

  return (
    <StyledMultidimentional {...rest}>
      {
        isEmpty(filterData)
          ? <span>-</span>
          : filterData.map(([key, value]) => {
            const title = key.replace(filter.name, '')
              .replace('[', '')
              .replace(']', '')

            return (
              <Section key={title}>
                <span key={VALUE_TYPE}>{TITLE_TYPE} is<HighlightSpan>{title === 'hunting' ? 'NB Target' : title}</HighlightSpan>and {VALUE_TYPE} </span>

                {isArray(value)
                  ? value.map((item, index) => (
                    <span key={item}>
                      {index === 0 ? 'is' : 'or'}
                      <HighlightSpan>{item}</HighlightSpan>
                    </span>
                  ))
                  : (<span key={value}>is<HighlightSpan key={value}>{value}</HighlightSpan></span>)
                }

              </Section>
            )
          })
      }
    </StyledMultidimentional>
  )
}

export default Multidimentional
