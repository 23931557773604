// http://localhost:3000/api/companies?business_id[]=C-002341326&business_id[]=C-002346748&business_id[]=C-002350490

import React from 'react'

import { Company } from 'src/ApiResponseTypes'
import CompaniesTable from 'src/components/CompaniesTable/CompaniesTable'
import { FiltersProvider, SelectionState } from 'src/providers/FiltersProvider'
import Box from 'src/ui/Box'
import Flex from 'src/ui/Flex'

type GroupTabProps = {
  company: Company

}

const GroupTab: React.FC<GroupTabProps> = ({ company }: GroupTabProps) => {
  const selectedFilters: SelectionState = { business_id: company.tree.map(company => company.business_id) }
  return (
    <FiltersProvider
      id="companies-group"
      initialValue={{ allFilters: [], selectedFilters }}>
      <Flex overflow="visible" gridArea="left" gridColumn="left / right">
        <Box>
          <CompaniesTable title="Group"/>
        </Box>
      </Flex>
    </FiltersProvider>
  )
}

export default GroupTab
