import React from 'react'
import numeral from 'numeral'
import styled from 'styled-components'

import { ReactChildren } from 'src/types'
import { DEFAULT_NUMERAL_FORMAT } from 'src/utils/formatting'
import { toTitleCase } from 'src/utils/toTitleCase'

const StyledTable = styled.table`
  font-size: 14px;
  padding:0;
  margin: 10px 0 0 0;
  border-collapse: collapse;

  td {
    padding: 3px 0;
  }

  td:first-child {
    padding-right: 20px;
  }
  td:last-child {
    text-align: right;
  }
`

// TODO: Check if we can find the proper typing from ReCharts library
type ChartTooltipTableProps = {
  payload: any,
  children?: ReactChildren
}

type DataPoint = {
  dataKey: string,
  name: string,
  color: string,
  value: number
}

const ChartTooltipTable: React.FC<ChartTooltipTableProps> = ({ payload, children }: ChartTooltipTableProps) => (
  <StyledTable>
    <tbody>
      {payload.map((datapoint: DataPoint) => (
        <tr key={datapoint.dataKey}>
          <td>{toTitleCase(datapoint.name)}:</td>
          <td>{numeral(datapoint.value).format(DEFAULT_NUMERAL_FORMAT)}</td>
        </tr>
      ))}
      {children}
    </tbody>
  </StyledTable>
)

export default ChartTooltipTable
