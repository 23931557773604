import styled from 'styled-components'
import { flex, FlexProps, gridArea, GridAreaProps } from 'styled-system'

import { STYLED_CONFIG } from 'src/utils/styled-utils'

type KpiEntryWrapperProps = GridAreaProps & FlexProps
const KpiEntryWrapper = styled('div').withConfig(STYLED_CONFIG)<KpiEntryWrapperProps>`
  background: ${props => props.theme.borderColor};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-template-rows: auto;
  align-content: start;
  grid-gap: 1px;
  ${gridArea};
  ${flex};

  > * {
    background: ${props => props.theme.contentBackground};
  }
`

export default KpiEntryWrapper
