import React from 'react'
import { isNumber } from 'lodash'
import numeral from 'numeral'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, YAxis } from 'recharts'
import styled, { useTheme } from 'styled-components'

import { KpiApiResponse } from 'src/ApiResponseTypes'
import { Path } from 'src/config/paths'
import { COMPANIES_STATUS_FILTERS } from 'src/pages/companies'
import { SelectionState } from 'src/providers/FiltersProvider'
import { useLocales } from 'src/providers/LocaleProvider'
import { useUserSettings } from 'src/providers/UserSettingsProvider'
import { getLabelStyles } from 'src/ui/BarChart'
import ErrorText from 'src/ui/ErrorText'
import LinkButton from 'src/ui/LinkButton'
import LoadingIndicator from 'src/ui/LoadingIndicator'
import { DEFAULT_NUMERAL_FORMAT, PERCENTAGE_NUMERAL_FORMAT, ROUNDED_NUMERAL_FORMAT } from 'src/utils/formatting'
import { createQueryParams } from 'src/utils/queryParams'

import Icon from '../Icon'

export type CardId = keyof Omit<KpiApiResponse, 'status' | 'cached_at' | 'currency'>

const NEGATIVE_IDS = ['declining', 'passive']
const FILTERABLE_STATUSES = COMPANIES_STATUS_FILTERS.map((filter) => filter.value)

type DescriptionMap = Partial<Record<CardId, string>>

const DESCRIPTIONS: DescriptionMap = {
  /* business_group_penetration: 'lorem ipsum dolor sit amet',
  new_accounts: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  offering_penetration: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  stable: 'Architecto quae nobis expedita aperiam blanditiis est repellendus quasi optio in sed. ',
  declining: 'Voluptatum eius dolore porro iusto corrupti distinctio explicabo',
  sales: 'Animi inventore voluptatum eius dolore porro iusto corrupti distinctio explicabo',
  growing: 'Dolore porro iusto corrupti distinctio explicabo',
  arpa: 'ARPA stands for Average Revenue Per Account',
  active: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit',
  passive: 'Animi inventore voluptatum eius dolore porro iusto corrupti distinctio explicabo' */
}

const yFormatter = (value: number): string => numeral(value)
  .format(ROUNDED_NUMERAL_FORMAT)
  .toUpperCase()

const percentageFormatter = (value: number): string => yFormatter(value * 100)

const MARGIN = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
}

export const Card = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  height: 370px;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.kpiBackground};
  border: 1px solid ${props => props.theme.borderColor};

  h2 {
    font-size: 14px;
    font-weight: bold;
  }

  h3 {
    font-size: 36px;
    font-weight: bold;
    margin: 24px 0 6px;
    padding:0;
  }

  .change {
    color: ${props => props.theme.text};
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 3px;
    font-size: 14px;

    span {
      padding-left: 5px;
    }
  }

  &.bg-danger .change {
    color: ${props => props.theme.danger};
    svg path {
      fill: ${props => props.theme.danger};
    }
  }

  &.bg-secondary .change {
    color: ${props => props.theme.secondary};
  }

  &.bg-success .change {
    color: ${props => props.theme.success};
    svg path {
      fill: ${props => props.theme.success};
    }
  }
`

export const CardTop = styled.div`
  display:flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0 5px;
  margin-bottom: 16px;

  > div {
    justify-content: center;
    align-items: center;
    padding-right: 16px;
  }

  h2 {
    display: flex;
    align-items: center;
    margin:0;
    padding:0;
  }

  h4 {
    padding:0;
    margin: 6px 0 0;
  }
`

const getColorClass = (change: number, reverse: boolean): string => {
  if (change === 0) {
    return 'bg-secondary'
  }

  return (reverse && change < 0) || (!reverse && change > 0)
    ? 'bg-success'
    : 'bg-danger'
}

export type KpiCardProps = {
  id: CardId,
  title: string,
  change?: number,
  comparison?: number,
  history?: Record<string, number>,
  current?: number,
  max?: number,
  unit: string,
  filters?: SelectionState,
  error?: Error
}

const KpiCardBase = ({
  id,
  title,
  current,
  max,
  history,
  change,
  unit: unitType,
  filters: viewFilters,
  error
}: KpiCardProps): React.ReactElement => {
  const theme = useTheme()
  const { moneyFormat } = useLocales()

  const { disableAnimations } = useUserSettings()

  const isMoneyFormat: boolean = unitType === 'currency'
  const isPercentageFormat: boolean = unitType === '%'
  const unit: string = isMoneyFormat
    ? numeral.localeData().currency.symbol
    : unitType

  if (error) {
    return (
      <Card className="KpiCard">
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <ErrorText text={error.message} />
        </div>
      </Card>
    )
  }
  if (history === undefined || change === undefined) {
    return (
      <Card className="KpiCard">
        <LoadingIndicator fullPage />
      </Card>
    )
  }

  const reverse = NEGATIVE_IDS.includes(id.toLocaleLowerCase())

  const chartData = Object.values(history).map(i => ({ value: i }))
  const filters = { status: id, ...viewFilters }
  const queryParams = createQueryParams(filters ?? {})
  const linkAddress: string | null = FILTERABLE_STATUSES.includes(id)
    ? `${Path.COMPANIES}?${queryParams}`
    : null

  const slideColorClass = getColorClass(change, reverse)
  const isPositive = slideColorClass.includes('success')
  const isNegative = slideColorClass.includes('danger')

  const accentColor = isNegative
    ? theme.danger
    : isPositive
      ? theme.success
      : theme.secondary

  const description = DESCRIPTIONS[id]
  return (
    <Card className={`KpiCard ${getColorClass(change, reverse)}`} data-testid="kpi-card">
      <CardTop>
        <div>
          <h2>{title}</h2>
          {description && (<h4>{description}</h4>)}
        </div>
        {linkAddress && (
          <LinkButton to={linkAddress} variation='tertiary' slim>
            Drill Down&nbsp;›
          </LinkButton>
        )}
      </CardTop>
      <ResponsiveContainer width="100%" minHeight="140px">
        <AreaChart
          width={330}
          height={330}
          data={chartData}
          margin={MARGIN}
        >
          <YAxis
            unit={unit}
            tickMargin={6}
            width={54}
            orientation="right"
            type='number'
            interval="preserveStartEnd"
            /* domain={max ? ['dataMin', max] : ['auto', 'auto']} */
            tickSize={5}
            tickFormatter={isPercentageFormat ? percentageFormatter : yFormatter}
            tick={getLabelStyles(theme)}
          />
          <CartesianGrid
            strokeDasharray='1'
            stroke={theme.borderColor}
            vertical={false}/>
          <defs>
            <linearGradient id={id} x1="0" y1="1" x2="0" y2="0">
              <stop offset="10%" stopColor={accentColor} stopOpacity={0.5} />
              <stop offset="60%" stopColor={accentColor} stopOpacity={1} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={!disableAnimations}
            dataKey="value"
            stroke={accentColor}
            strokeWidth={2}
            dot={{ strokeWidth: 1, r: 2, fill: accentColor }}
            fill={`url(#${id})`}
            type="linear" />
        </AreaChart>
      </ResponsiveContainer>
      <h3>
        {isNumber(current)
          ? numeral(current).format(isMoneyFormat
            ? moneyFormat
            : isPercentageFormat
              ? PERCENTAGE_NUMERAL_FORMAT
              : DEFAULT_NUMERAL_FORMAT)
          : current}  {!isMoneyFormat && unit} {max && ` / ${max}`}
      </h3>
      <div className="change">
        <Icon name={change > 0 ? 'TriangleUp' : 'TriangleDown'} />
        <span>
          {numeral(change).format(PERCENTAGE_NUMERAL_FORMAT)} YOY
        </span>
      </div>
    </Card>
  )
}

export default KpiCardBase
