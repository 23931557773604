import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Path, SITEMAP } from 'src/config/paths'
import { LAYOUT_GAP } from 'src/ui/Flex'

import MenuToggleButton from '../PageTop/MenuToggleButton'

import NavItem from './NavItem'

export const NAV_OPEN_WIDTH = 280
export const NAV_CLOSED_WIDTH = 68 // 50px + 3px margin horizontally

type StyledNavContainerProps = { $isOpen: boolean }

const NavContainer = styled('div') <StyledNavContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: ${props => props.$isOpen ? NAV_OPEN_WIDTH : NAV_CLOSED_WIDTH}px;
  background: ${props => props.theme.navBackground};
  margin-right: ${LAYOUT_GAP}px;
`

const NavLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding: 4px 8px;
  margin: 20px 8px 4px;
  color: ${props => props.theme.navLabel};
`

const BottomItems = styled.div<StyledNavContainerProps>`
  .Logo {
    width: 50px;
    margin-left: 10px;
  }
`

type NavigationProps = {
  navOpen: boolean
}

const Navigation: React.FC<NavigationProps> = ({ navOpen }: NavigationProps) => {
  return (
    <NavContainer $isOpen={navOpen} data-testid="main-navigation">
      <nav>
        <MenuToggleButton/>
        {SITEMAP.map((item) => (
          <div key={item.label}>
            {item.path
              ? <NavItem {...item} navOpen={navOpen}/>
              : (
                <>
                  {navOpen && (<NavLabel>{item.label}</NavLabel>)}
                  {item.children?.map((child) => <NavItem key={child.label} {...child} navOpen={navOpen}/>)}
                </>
              )
            }
          </div>
        ))}
      </nav>
      <BottomItems $isOpen={navOpen}>
        <NavItem
          path={Path.SETTINGS}
          icon="Settings"
          key="settings"
          label="Settings"
          navOpen={navOpen}/>
        <Link to="https://www.180ops.com/" target="_blank">
          <img className="Logo" src={require('../../images/Logo.png')} alt="180ops" title="180ops"/>
        </Link>
      </BottomItems>
    </NavContainer>
  )
}

export default Navigation
