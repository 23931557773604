import React from 'react'

import Icon from 'src/components/Icon'

import { ActionCol } from '../utils'

type ChevronButtonProps = {
  onClick: (event: unknown) => void,
  isOpen: boolean
}
// onClick={table.getToggleAllRowsExpandedHandler()}>
// isOpen=table.getIsAllRowsExpanded()>
const ChevronButton: React.FC<ChevronButtonProps> = ({ onClick, isOpen }: ChevronButtonProps) => {
  return (
    <ActionCol
      style={{ padding: '2px 8px 0 0' }}
      onClick={onClick}>
      <Icon
        size={12}
        name="Chevron"
        style={{ transform: isOpen ? 'rotateZ(90deg)' : '' }}/>
    </ActionCol>
  )
}

export default ChevronButton
