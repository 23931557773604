import React from 'react'

import { useUserSettings } from 'src/providers/UserSettingsProvider'
import { createApiQueryParams } from 'src/utils/queryParams'

export type ErrorMap = Record<string, string>

export const ErrorMessages: ErrorMap = {
  'Failed to fetch': 'Could not load the data'
}

type DataLoaderResponse<T> = {
  data: T | undefined,
  error?: Error
}

export default function useDataLoader<T> (url: string): DataLoaderResponse<T> {
  const [data, setData] = React.useState<T | undefined>()
  const [error, setError] = React.useState<Error | undefined>()
  const { currency } = useUserSettings()
  const fullUrl: string = `${url}?${createApiQueryParams({ currency: currency.external_id })}`

  React.useEffect(() => {
    fetch(fullUrl)
      .then(async (response) => await response.json())
      .then((json) => {
        setData(json as T)
      })
      .catch((error: Error) => {
        setError({
          ...error,
          message: ErrorMessages[error.message] ?? error.message
        })
      })
  }, [])

  return {
    data,
    error
  }
}
