import React from 'react'

import { AppStateProvider } from './providers/AppStateProvider'
import { LocaleProvider } from './providers/LocaleProvider'
import { UserSettingsProvider } from './providers/UserSettingsProvider'
import Router from './Router'

import './App.scss'

const App: React.FC = () => {
  return (
    <UserSettingsProvider>
      <LocaleProvider>
        <AppStateProvider>
          <Router />
        </AppStateProvider>
      </LocaleProvider>
    </UserSettingsProvider>
  )
}
export default App
