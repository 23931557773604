import React from 'react'
import { useLocalStorage, useThrottle, useUpdateEffect } from 'react-use'
import {
  ColumnDef,
  ColumnSizingState,
  PaginationState,
  SortingState,
  Table,
  TableOptions,
  TableState,
  useReactTable
} from '@tanstack/react-table'
import { isEmpty, isFunction } from 'lodash'

import { useQueries } from 'src/providers/QueryProvider'

import { ColumnPreset, getDefaultTableSettings } from './utils'

const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: 20 }
const DEFAULT_SORTING: SortingState = []
const DEFAULT_ROW_SELECTION = {}

type LocalTableState = {
  columnSizing?: ColumnSizingState
}

type UseTableResult<DataModel> = {
  table: Table<DataModel>
}

type UseTableProps<DataModel> = Pick<TableOptions<DataModel>,
  'getSubRows' |
  'getRowId' |
  'enableExpanding' |
  'enableSubRowSelection' |
  'manualPagination' |
  'manualSorting' |
  'pageCount' |
  'enableRowSelection' |
  'onPaginationChange' |
  'onSortingChange' |
  'onRowSelectionChange' |
  'state' |
  'filterFromLeafRows' |
  'rowCount'
> & {
  id: string,
  columns: Array<ColumnDef<DataModel>>,
  data: DataModel[],
  initialState?: Partial<TableState>
}

export default function useTable<DataModel> ({ id, columns, data, initialState, state, ...rest }: UseTableProps<DataModel>): UseTableResult<DataModel> {
  // TODO:
  // - Make id unique
  //  - These don't collide when we have the same table on multiple views

  const tableId = `table-${id}`.replaceAll('--', '-')
  const [tableState, saveTableState, clear] = useLocalStorage<LocalTableState>(tableId)

  const { queries, setQueries } = useQueries()

  const sortingFromQuery: SortingState | undefined = queries.sort && queries.order
    ? [{
      id: queries.sort as string,
      desc: queries.order === 'desc'
    }]
    : undefined

  const paginationFromQuery: PaginationState | undefined = queries.page
    ? {
      pageIndex: Number(queries.page),
      pageSize: 20 // TODO: Move this to query parameter too
    }
    : undefined

  const initialSorting: SortingState = (sortingFromQuery ?? initialState?.sorting ?? [])

  const [columnSizing, setColumnSizing] = React.useState<ColumnSizingState>(tableState?.columnSizing ?? {})
  const [pagination, setPagination] = React.useState<PaginationState>(paginationFromQuery ?? initialState?.pagination ?? DEFAULT_PAGINATION)
  const [sorting, setSorting] = React.useState<SortingState>(initialSorting ?? initialState?.sorting ?? DEFAULT_SORTING)
  const [rowSelection, setRowSelection] = React.useState(initialState?.rowSelection ?? DEFAULT_ROW_SELECTION)

  const throttledSizingState = useThrottle(columnSizing, 1000)
  React.useEffect(() => {
    if (isEmpty(throttledSizingState)) {
      clear()
      return
    }

    saveTableState(prev => ({
      ...prev,
      columnSizing: throttledSizingState
    }))
  }, [throttledSizingState])

  useUpdateEffect(() => {
    setQueries({
      page: `${pagination.pageIndex}`,
      ...(sorting[0] ? { sort: sorting[0].id } : { sort: undefined }),
      ...(sorting[0] ? { order: sorting[0].desc ? 'desc' : 'asc' } : { order: undefined })
    })
  }, [sorting, pagination.pageIndex])

  const handleColumnSizingChange = React.useCallback((data: any) => {
    setColumnSizing(prev => {
      if (isFunction(data)) {
        return data(prev)
      }
      return data
    })
  }, [])

  const columnsWithSelect: Array<ColumnDef<DataModel>> = React.useMemo(() => {
    const cols = columns
    if (rest.enableRowSelection) {
      cols.unshift({
        ...ColumnPreset.SELECT_COLUMN,
        id: 'select',
        enableResizing: false
      })
    }

    return cols
  }, [columns, rest.enableRowSelection])

  const table = useReactTable({
    ...getDefaultTableSettings(),
    data,
    columns: columnsWithSelect,
    state: {
      pagination,
      sorting,
      rowSelection,
      columnSizing,
      ...state
    },
    paginateExpandedRows: false,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onColumnSizingChange: handleColumnSizingChange,
    enableRowSelection: false,
    manualPagination: false,
    manualSorting: false,
    ...rest
  })

  return {
    table
  }
}
