import React from 'react'

import { Company } from 'src/ApiResponseTypes'
import PulseTable from 'src/components/PulseTable'
import RiskTable from 'src/components/RiskTable'
import Box from 'src/ui/Box'
import Flex from 'src/ui/Flex'

type PulseTabProps = {
  company: Company
}

const PulseTab: React.FC<PulseTabProps> = ({ company }: PulseTabProps) => (
  <Flex
    gridArea="left"
    flexDirection='column'
    style={{ overflow: 'auto' }}>
    <Box flex="0 0 auto">
      <PulseTable
        entity={company}
        autoHeight
        title='Sales pipeline / Events'/>
    </Box>
    <Box flex="0 0 auto">
      <RiskTable
        data={company.risk_factors}
        reverse
        autoHeight
        title="Churn risk drivers"/>
    </Box>
    <Box flex="0 0 auto">
      <RiskTable
        data={company.readiness_factors}
        autoHeight
        title="Buying readiness drivers"/>
    </Box>
  </Flex>

)

export default PulseTab
