import React from 'react'

import { FiltersResponse } from 'src/ApiResponseTypes'
import { API } from 'src/config/paths'
import useDataLoader from 'src/hooks/useDataLoader'
import { FiltersProvider } from 'src/providers/FiltersProvider'
import { ReactChildren } from 'src/types'

import { Filter, FilterType } from './types'

type BasicFiltersProps = {
  id: string,
  children: ReactChildren,
  customFilters?: Filter[],
  prependCustomFilters?: boolean
}

const FILTERS_URL = `${API}/api/attributes/filters`

const BasicFilters: React.FC<BasicFiltersProps> = ({ id, children, customFilters, prependCustomFilters }: BasicFiltersProps) => {
  const { data } = useDataLoader<FiltersResponse>(FILTERS_URL)

  const filters: Filter[] = React.useMemo(() => {
    if (!data) {
      return []
    }

    return [
      ...(prependCustomFilters && customFilters ? customFilters : []),
      ...(data.postal_codes
        ? [{
          label: 'Area',
          name: 'postal_codes',
          type: 'multiselect' as FilterType,
          options: data.postal_codes.map((code) => ({
            label: code.label,
            value: code.external_id
          }))
        }]
        : []),
      {
        label: 'Business Units',
        name: 'business_groups',
        type: 'multiselect',
        options: data.business_groups.map(({ label, external_id: value }) => ({
          label,
          value
        }))
      },
      {
        label: 'Offerings Groups',
        name: 'offerings',
        parentFilterId: 'business_groups',
        type: 'multiselect',
        options: data.offerings.map(({ label, external_id: value, parent_id }) => ({
          label,
          value,
          parentId: parent_id
        }))
      },
      {
        label: 'Account Responsible',
        name: 'accounts',
        type: 'multiselect',
        options: data.accounts.map(({ label, external_id: value }) => ({
          label,
          value
        }))
      },
      {
        label: 'Industries',
        name: 'industries',
        type: 'multiselect',
        options: data.industries.filter(item => item.parent_id === null).map(({ label, external_id: value }) => ({
          label,
          value
        }))
      },
      {
        label: 'Revenue Classification',
        name: 'turnover_group',
        type: 'multiselect',
        options: data.turnover_groups.map(({ label, turnover_group_id: value }) => ({
          label,
          value
        }))
      },
      ...(!prependCustomFilters && customFilters ? customFilters : [])
    ]
  }, [data])

  return (
    <FiltersProvider
      id={id}
      initialValue={{ allFilters: filters }}>
      {children}
    </FiltersProvider>
  )
}

export default BasicFilters
