import React from 'react'
import numeral from 'numeral'

import { TranslationEnglish } from 'src/config/TranslationEnglish'

import { useUserSettings } from './UserSettingsProvider'

numeral.register('locale', 'EUR', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'M',
    billion: 'B',
    trillion: 't'
  },
  ordinal: (number) => `${number}`,
  currency: {
    symbol: '€'
  }
})

numeral.register('locale', 'USD', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'M',
    billion: 'b',
    trillion: 't'
  },
  ordinal: (number) => `${number}`,
  currency: {
    symbol: '$'
  }
})

type LangModel = typeof TranslationEnglish

const defaultLangState: LangModel = TranslationEnglish

const LocaleContext = React.createContext(defaultLangState)

export const useLocales = (): LangModel => React.useContext(LocaleContext)

export const LocaleProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [langState, setLangState] = React.useState<LangModel>(defaultLangState)
  const { currency } = useUserSettings()

  React.useEffect(() => {
    numeral.locale(currency.external_id)
    setLangState({
      ...defaultLangState,
      moneyFormat: currency.external_id === 'USD' ? '$0,000' : '0,000$',
      moneyFormatShort: currency.external_id === 'USD' ? '$0.[00]a' : '0.[00]a$'
    })
  }, [currency])

  return (
    <LocaleContext.Provider value={langState}>
      {children}
    </LocaleContext.Provider>
  )
}
