import React, { ChangeEventHandler } from 'react'

import { SelectedFilter, SelectedFilterValue } from 'src/providers/FiltersProvider'

import { Filter, FilterOption } from './types'

type RadioProps = {
  filter: Filter,
  option: FilterOption,
  onChange: (changeObject: SelectedFilter) => void,
  selection?: SelectedFilterValue
}

const Radio: React.FC<RadioProps> = ({ filter, option: { value, label }, onChange, selection }: RadioProps) => {
  const handleRadioChange: ChangeEventHandler<HTMLInputElement> = React.useCallback((event) => {
    const value = event.currentTarget.value
    onChange({
      name: filter.name,
      value
    })
  }, [])

  return (
    <div>
      <label>
        <input
          type="radio"
          name={filter.name}
          value={value}
          onChange={handleRadioChange}
          checked={selection === value}/> {label}
      </label>
    </div>
  )
}

export default Radio
