import { Penetration } from 'src/ApiResponseTypes'
import { toTitleCase } from 'src/utils/toTitleCase'

import { PenetrationGraphDataObject } from './types'

export const xFormatter = (value: string): string => value
export const legendFormatter = (value: string): string => toTitleCase(value)

export const getChartData = (
  penetration: Penetration
): PenetrationGraphDataObject[] => Object.keys(penetration.companies)
  .map((key) => ({
    companies: penetration.companies[key],
    sales: penetration.sales[key],
    arpa: penetration.arpa[key],
    xTick: key
  }
  ))
