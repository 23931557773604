import {
  Table as ReactTable
} from '@tanstack/react-table'

import Header from './Header'

export type TableHeadProps<T> = {
  table: ReactTable<T>,
  style: React.CSSProperties
}

const TableHead = <T,>({ table, style }: TableHeadProps<T>): JSX.Element => {
  const headers = table.getHeaderGroups()[0].headers
  return (
    <>
      {headers.map((header, index) => (
        <Header
          style={style}
          key={header.id}
          header={header}
          index={index}
          table={table} />
      ))}
    </>
  )
}

export default TableHead
