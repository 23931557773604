import PageTop from 'src/components/PageTop'
import Box from 'src/ui/Box'
import Link from 'src/ui/Link'
import PageContent from 'src/ui/PageContent'

const NotFound: React.FC = () => (
  <>
    <PageTop pageTitle="Page not found"/>
    <PageContent>
      <Box gridArea="left" gridColumn="left / right">
        <h2>Page could not be found</h2>
        <p>Please check the link or go <Link to="/">back to home page</Link></p>
      </Box>
    </PageContent>
  </>

)
export default NotFound
