import { BrowserRouter, Route, Routes } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { ThemeProvider } from 'styled-components'

import Layout from './components/Layout'
import { Path } from './config/paths'
import BusinessGroups from './pages/business-groups'
import BusinessGroup from './pages/business-groups/business_group'
import Companies from './pages/companies'
import Company from './pages/companies/company'
/* import Industries from './pages/industries'
import Industry from './pages/industries/industry' */
import Kpi from './pages/kpi'
import GrowthPlanning from './pages/market-analysis'
import NotFound from './pages/not-found'
import Offerings from './pages/offerings'
import OfferingPage from './pages/offerings/offering'
import Settings from './pages/settings'
import { useAppState } from './providers/AppStateProvider'
import { useLocales } from './providers/LocaleProvider'
import { QuerysProvider } from './providers/QueryProvider'
import LoadingIndicator from './ui/LoadingIndicator'

const Router: React.FC = () => {
  const locales = useLocales()
  const { theme } = useAppState()

  if (isEmpty(locales)) {
    return <LoadingIndicator />
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <QuerysProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path={Path.KPI} element={<Kpi />} />
                <Route path={Path.MARKET_ANALYSIS} element={<GrowthPlanning />}>
                  <Route path={Path.MARKET_ANALYSIS_TAB} element={null} />
                </Route>

                <Route path={Path.BUSINESS_GROUPS} element={<BusinessGroups />} />
                <Route path={Path.BUSINESS_GROUP} element={<BusinessGroup />}>
                  <Route path={Path.BUSINESS_GROUP_TAB} element={null} />
                </Route>

                <Route path={Path.OFFERINGS} element={<Offerings />} />
                <Route path={Path.OFFERING} element={<OfferingPage />}>
                  <Route path={Path.OFFERING_TAB} element={null} />
                </Route>

                <Route path={Path.COMPANIES} element={<Companies />} />
                <Route path={Path.COMPANY} element={<Company />}>
                  <Route path={Path.COMPANY_TAB} element={null} />
                </Route >

                <Route path={Path.SETTINGS} element={<Settings />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </QuerysProvider>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  )
}

export default Router
