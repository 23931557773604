import React from 'react'
import { TableState } from '@tanstack/react-table'

import { SelectionState } from 'src/providers/FiltersProvider'
import { useUserSettings } from 'src/providers/UserSettingsProvider'
import { createApiQueryParams, QueryObject } from 'src/utils/queryParams'

import { ErrorMessages } from './useDataLoader'

type QueryGeneratorParameters = {
  pagination: TableState['pagination'],
  sorting: TableState['sorting'],
  selectedFilters: SelectionState,
  customParams: Record<string, string | number>
}

const getQueryUrl = ({
  pagination,
  sorting,
  selectedFilters,
  customParams
}: QueryGeneratorParameters): string => {
  const queryObj: QueryObject = {
    page: pagination.pageIndex + 1, // On table state first page is 0, API expects 1
    limit: pagination.pageSize
  }

  if (sorting[0] !== undefined) {
    const sortBy = sorting[0]
    queryObj.sort = sortBy.id
    queryObj.order = sortBy.desc ? 'desc' : 'asc'
  }

  return createApiQueryParams({ ...queryObj, ...selectedFilters, ...customParams })
}

type TableLoaderResponse<T> = {
  isLoading: boolean,
  data: T | undefined,
  loadData: (tableState: TableState, selectedFilters: SelectionState) => void,
  error?: Error
}

export default function useTableDataLoader<T> (baseUrl: string): TableLoaderResponse<T> {
  const [data, setData] = React.useState<T | undefined>()
  const [error, setError] = React.useState<Error | undefined>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const { currency } = useUserSettings()

  const loadData = React.useCallback((tableState: TableState, selectedFilters: SelectionState) => {
    setIsLoading(true)
    setError(undefined)

    const fullUrl = `${baseUrl}?${getQueryUrl({
      pagination: tableState.pagination,
      sorting: tableState.sorting,
      selectedFilters,
      customParams: {
        currency: currency.external_id
      }
    })}`

    fetch(fullUrl)
      .then(async (response) => await response.json())
      .then((json) => {
        setData(json as T)
        setIsLoading(false)
      })
      .catch((error: Error) => {
        setError({
          ...error,
          message: ErrorMessages[error.message] ?? error.message
        })
        setIsLoading(false)
      })
  }, [])

  return {
    isLoading,
    data,
    loadData,
    error
  }
}
