import React, { ChangeEventHandler } from 'react'
import { RowSelectionState } from '@tanstack/react-table'
import numeral from 'numeral'
import styled from 'styled-components'

import { BreakdownApiResponse, MatrixFilter } from 'src/ApiResponseTypes'
import Checkbox from 'src/ui/Checkbox'
import LoadingIndicator from 'src/ui/LoadingIndicator'
import { DEFAULT_NUMERAL_FORMAT } from 'src/utils/formatting'

const TabContent = styled.div`
  position: relative;
  font-size: 14px;
  width: 100%;
`

const CheckboxRow = styled.div`
  display: flex;

  >* {
    margin: 0 5px 0 0;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    width: 20%;
    padding: 10px;
    text-align: left;
    height: 40px;
  }

  .colored {
    background: ${props => props.theme.appBackground};
    color: ${props => props.theme.text};
  }

  .left-edge {
    padding-left: 40px;
  }

  .right-edge {
    color: ${props => props.theme.text};
    text-align: right;
    padding: 10px;
    font-size: 14px;
    text-transform: uppercase;
  }
`

const CurrentValue = styled(Row)`
  color: ${props => props.theme.text};
  position: absolute;
  transform-origin: top left;
  transform: rotate(-90deg) translate(10px, 8px);
  font-size: 14px;
  text-transform: uppercase;
`

type ResourcesTabProps = {
  initialSelection?: RowSelectionState,
  onChange: (selection: RowSelectionState) => void,
  breakdownData?: BreakdownApiResponse
}

const ResourcesTab: React.FC<ResourcesTabProps> = ({ onChange, initialSelection, breakdownData }: ResourcesTabProps) => {
  const handleClick: ChangeEventHandler<HTMLInputElement> = React.useCallback((event) => {
    const newState = {
      ...initialSelection,
      [event.target.name]: true
    }
    const shouldUnCheck = initialSelection?.[event.target.name]
    if (shouldUnCheck) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newState[event.target.name]
    }
    onChange(newState)
  }, [initialSelection])

  if (!breakdownData?.value_groups) {
    return <LoadingIndicator />
  }

  const groups: MatrixFilter = breakdownData?.value_groups
  const labels = Object.keys(groups).reverse()
  const items = Object.entries(groups)
    .reverse()
    .map(([id, object]: [string, string | string[]], index) => (
      <Row key={id}>
        {[['test', labels[index]], ...Object.entries(object)].map(([key, value], index) => {
          const itemId = `${id}?${key}`
          return index === 0
            ? (
              <span key={itemId} className="colored left-edge">
                {value === 'hunting' ? 'NB Target' : value}
              </span>
            )
            : (
              <CheckboxRow key={itemId}>
                <Checkbox
                  onChange={handleClick}
                  name={itemId}
                  checked={initialSelection?.[itemId] ?? false}
                /> {numeral(value).format(DEFAULT_NUMERAL_FORMAT)}
              </CheckboxRow>
            )
        })}
      </Row>
    ))

  return (
    <TabContent>
      <Row key='upper-header'>
        <span key="title0"></span>
        <span key="title1" className="colored"></span>
        <span key="title2" className="colored"></span>
        <span key="title3" className="colored"></span>
        <span key="title4" className="colored right-edge">Potential</span>
      </Row>
      <Row key='header'>
        <span></span>
        {labels.reverse().slice(1).map((label) => {
          return (
            <span key={label} className="colored">
              {label}
            </span>
          )
        })}
      </Row>
      {items}
      <CurrentValue className="colored">Current value</CurrentValue>
    </TabContent>
  )
}

export default ResourcesTab
