import React from 'react'

import { BusinessGroup, Offering } from 'src/ApiResponseTypes'
import PulseTable from 'src/components/PulseTable'
import RiskTable from 'src/components/RiskTable'
import Box from 'src/ui/Box'
import Flex from 'src/ui/Flex'

type PulseTabProps = {
  entity: BusinessGroup | Offering
}

const PulseTab: React.FC<PulseTabProps> = ({
  entity
}: PulseTabProps) => {
  return (
    <>
      <Flex
        gridArea="left"
        flexDirection='column'
        overflow={['visible', 'auto']}>
        <Box flex="0 0 auto">
          <PulseTable entity={entity} autoHeight={false} title="Sales pipeline / Events"/>
        </Box>
        <Box flex="0 0 auto">
          <RiskTable data={entity.risk_factors} autoHeight={false} title="Churn risk drivers"/>
        </Box>
        <Box flex="0 0 auto">
          <RiskTable data={entity.readiness_factors} reverse autoHeight={false} title="Buying readiness drivers"/>
        </Box>
      </Flex>
    </>
  )
}
export default PulseTab
