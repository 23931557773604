import { isNumber } from 'lodash'
import numeral from 'numeral'
import styled from 'styled-components'

import { useLocales } from 'src/providers/LocaleProvider'
import { ReactChildren } from 'src/types'
import { ROUNDED_NUMERAL_FORMAT } from 'src/utils/formatting'

type StyleProps = { width?: string }

const StyledBox = styled.div<StyleProps>`
  font-size: 14px;
  p {
    font-size: 14px;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
    margin:0;
    padding:0;
  }

  h3 {
    font-size: 14px;
    font-weight: normal;
    margin:0;
    padding:0;
  }
`

type KpiRowEntryProps = StyleProps & {
  title: string,
  children?: ReactChildren,
  value?: number | string,
  unit?: string,
  style?: React.CSSProperties
}

const KpiRowEntry: React.FC<KpiRowEntryProps> = ({ title, value, unit, style, children }: KpiRowEntryProps) => {
  const { moneyFormatShort } = useLocales()
  return (
    <StyledBox style={style} data-testid={`kpi-${title}`}>
      <h3>{title}</h3>
      {children}
      {value !== undefined && (
        <h2>
          {isNumber(value)
            ? numeral(value).format(unit === 'currency'
              ? moneyFormatShort
              : ROUNDED_NUMERAL_FORMAT)
            : value}
          {unit !== 'currency' ? unit : ''}
        </h2>)}
    </StyledBox>
  )
}

export default KpiRowEntry
