import React from 'react'

import { SelectionState } from 'src/providers/FiltersProvider'
import { useUserSettings } from 'src/providers/UserSettingsProvider'
import { createApiQueryParams } from 'src/utils/queryParams'

import { ErrorMessages } from './useDataLoader'

type DataLoaderResponse<T> = {
  data: T | undefined,
  loadData: (selectedFilters?: SelectionState) => void,
  error?: Error
}

export default function useAsyncDataLoader<T> (url: string): DataLoaderResponse<T> {
  const [data, setData] = React.useState<T | undefined>()
  const [error, setError] = React.useState<Error | undefined>()
  const { currency } = useUserSettings()

  const loadData = React.useCallback((selectedFilters?: SelectionState) => {
    setError(undefined)

    const fullUrl: string = `${url}?${createApiQueryParams({ ...selectedFilters, currency: currency.external_id })}`

    fetch(fullUrl)
      .then(async (response) => await response.json())
      .then((json) => {
        setData(json as T)
      })
      .catch((error: Error) => {
        setError({
          ...error,
          message: ErrorMessages[error.message] ?? error.message
        })
      })
  }, [])

  return {
    data,
    loadData,
    error
  }
}
