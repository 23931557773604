import {
  Table as ReactTable
} from '@tanstack/react-table'

import TableRow from './TableRow'

export type TableBodyProps<T> = {
  table: ReactTable<T>
}

const TableBody = <T,>({ table }: TableBodyProps<T>): JSX.Element => {
  const rows = table.getRowModel().rows
  const canExpand = table.getCanSomeRowsExpand()
  return (
    <>
      {rows.map((row, index) => (
        <TableRow key={row.id} row={row} index={index} canExpand={canExpand}/>
      ))}
    </>
  )
}

export default TableBody
