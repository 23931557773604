import React from 'react'

import { OfferingsApiResponse } from 'src/ApiResponseTypes'
import { Filter } from 'src/components/Filters/types'
import PageTop from 'src/components/PageTop'
import { API } from 'src/config/paths'
import useDataLoader from 'src/hooks/useDataLoader'
import { FiltersProvider } from 'src/providers/FiltersProvider'
import Box from 'src/ui/Box'
import LoadingIndicator from 'src/ui/LoadingIndicator'
import PageContent from 'src/ui/PageContent'

import BusinessGroupsTable from './BusinessGroupsTable'

const FILTERS: Filter[] = [{
  label: 'Search',
  name: 'search',
  type: 'text',
  options: []
}]

const DATA_URL = `${API}/api/offering`

const BusinessGroups: React.FC = () => {
  const { data, error } = useDataLoader<OfferingsApiResponse>(DATA_URL)

  if (data === undefined && !error) {
    return <LoadingIndicator fullPage />
  }

  return (
    <>
      <FiltersProvider
        id="business-groups-list"
        initialValue={{ allFilters: FILTERS }}>
        <PageTop pageTitle="Business units"/>
        <PageContent>
          <Box gridArea="left" gridColumn="left / right">
            <BusinessGroupsTable
              error={error}
              businessGroups={data?.business_groups}
              showFilters />
          </Box>
        </PageContent>
      </FiltersProvider>
    </>
  )
}

export default BusinessGroups
