export const TranslationEnglish = {
  moneyFormat: '$ 0,000',
  moneyFormatShort: '$ 0.[00]a',
  active: 'Active customers',
  passive: 'Passive customers',
  growing: 'Growing customers',
  declining: 'Declining customers',
  sales: 'Net sales',
  arpa: 'ARPA',
  business_group_penetration: 'Business unit penetration',
  offering_penetration: 'Offering penetration',
  new_accounts: 'New customers',
  stable: 'Stable customers',
  pareto: '80 % sales'
}

export type LangModel = typeof TranslationEnglish
