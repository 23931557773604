import React from 'react'
import { useLocation } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'

import { createQueryParams, getQueryParams } from 'src/utils/queryParams'

const defaultQuerysState: QuerysProviderResult = {
  queries: {},
  setQueries: () => {}
}

const QueryManager = React.createContext(defaultQuerysState)

export type SelectedQueryValue = string | string[] | undefined

type QueryName = string
export type QueryState = Record<QueryName, SelectedQueryValue>

type QuerysProviderResult = {
  queries: QueryState,
  setQueries: (newState: QueryState) => void
}

export const useQueries = (): QuerysProviderResult => React.useContext(QueryManager)

export type QuerysProviderProps = {
  children?: React.ReactNode,
  initialValue?: {
    queries?: QueryState
  }
}

export const QuerysProvider = ({ children, initialValue }: QuerysProviderProps): JSX.Element => {
  const location = useLocation()
  const { search, pathname } = location
  const queries = getQueryParams(search) ?? {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queriesObject, setQueries] = React.useState<QueryState>(queries ?? initialValue?.queries ?? {})

  useUpdateEffect(() => {
    setQueries(queries ?? {})
  }, [pathname])

  // TODO: On page change clear queries state
  const handleSetQueries = React.useCallback((newQueries: QueryState) => {
    setQueries(prev => {
      const allQueries = { ...prev, ...newQueries }
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      Object.keys(allQueries).forEach(key => allQueries[key] === undefined && delete allQueries[key])
      if (allQueries.page && Number(allQueries.page) === 0) {
        delete allQueries.page
      }
      const q = createQueryParams(allQueries)

      // TODO: Check if there's a better way to do this line
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${q ? '?' + q : ''}`
      history.pushState({ path: newurl }, '', newurl)

      return allQueries
    })
  }, [])

  return (
    <QueryManager.Provider value={{
      queries,
      setQueries: handleSetQueries
    }}>
      {children}
    </QueryManager.Provider>
  )
}
