import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { getActivePages, getActivePaths } from './utils'

const StyledBreadcrumb = styled.div`
  margin: 0px 0 5px 0;
`

const TopLevelItem = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  color: ${props => props.theme.text}
`

type BreadcrumbProps = {
  pageTitle?: string
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ pageTitle }: BreadcrumbProps) => {
  const location = useLocation()
  const { pathname } = location
  const activePaths = getActivePaths(pathname).splice(1)
  const pages = getActivePages(activePaths)
  const page = pages[0]

  if (page.label === pageTitle) {
    return null
  }

  return (
    <StyledBreadcrumb data-testid="breadcrumb">
      <TopLevelItem to={page.path ?? ''} key={page.path}>
        {page.label}
      </TopLevelItem>
    </StyledBreadcrumb>
  )
}

export default Breadcrumb
