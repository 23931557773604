import {
  Table
} from '@tanstack/react-table'

import BaseTable, { BaseTableProps } from '../BaseTable'

export { default as ExpandColumn } from './ExpandColumn'

type RowData<T> = {
  [key: string]: any, // TODO: This should be something like keyof T
  subRows?: T[]
}

export type ExpandableTableProps<T> = BaseTableProps<T> & {
  table: Table<T>
}

const ExpandableTable = <T extends RowData<T>,>({ table, ...rest }: ExpandableTableProps<T>): JSX.Element => (
  <BaseTable
    table={table}
    {...rest} />
)

export default ExpandableTable
