import React from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { CompanyApiResponse } from 'src/ApiResponseTypes'
import ErrorPage from 'src/components/ErrorPage'
import PageTop from 'src/components/PageTop'
import Tabs, { Tab } from 'src/components/Tabs'
import { API, Path } from 'src/config/paths'
import useDataLoader from 'src/hooks/useDataLoader'
import LoadingIndicator from 'src/ui/LoadingIndicator'
import PageContent from 'src/ui/PageContent'

import GroupTab from './tabs/GroupTab'
import OverviewTab from './tabs/OverviewTab'
import PulseTab from './tabs/PulseTab'

const Company: React.FC = () => {
  const { companyId, tabId } = useParams()
  const dataUrl = `${API}/api/companies/${companyId}`
  const { data, error } = useDataLoader<CompanyApiResponse>(dataUrl)

  if (error || data?.status === 'error' || !data?.company) {
    return <ErrorPage title={`Company with id ${companyId} not found`}/>
  }

  if (data === undefined) {
    return <LoadingIndicator fullPage/>
  }

  const { company } = data

  const tabs: Tab[] = [
    {
      path: generatePath(Path.COMPANY, { companyId: companyId ?? null }),
      label: 'Overview'
    },
    {
      path: generatePath(Path.COMPANY_TAB, { companyId: companyId ?? null, tabId: 'pulse' }),
      label: 'Pulse'
    }
  ]

  if (company.tree.length > 1) {
    tabs.push({
      path: generatePath(Path.COMPANY_TAB, { companyId: companyId ?? null, tabId: 'group' }),
      label: 'Group'
    })
  }

  return (
    <>
      <PageTop pageTitle={company.label}/>
      <PageContent>
        <Tabs tabs={tabs} gridArea='tab'/>
        {tabId === undefined && (
          <OverviewTab company={company}/>
        )}

        {tabId === 'pulse' && (
          <PulseTab company={company}/>
        )}

        {tabId === 'group' && (
          <GroupTab company={company}/>
        )}
      </PageContent>
    </>
  )
}

export default Company
