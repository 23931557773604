import { ReactSVG } from 'react-svg'
import styled, { CSSProperties, useTheme } from 'styled-components'
import { margin, MarginProps, size, SizeProps } from 'styled-system'

import Analytics from 'src/images/Analytics.svg'
import ArrowLeft from 'src/images/ArrowLeft.svg'
import ArrowRight from 'src/images/ArrowRight.svg'
import BagIcon from 'src/images/BagIcon.svg'
import BarsIcon from 'src/images/BarsIcon.svg'
import BasketIcon from 'src/images/BasketIcon.svg'
import Building from 'src/images/Building.svg'
import ChartIcon from 'src/images/ChartIcon.svg'
import Chevron from 'src/images/Chevron.svg'
import CloudDownload from 'src/images/CloudDownload.svg'
import Cube from 'src/images/Cube.svg'
import DonutIcon from 'src/images/DonutIcon.svg'
import Earth from 'src/images/Earth.svg'
import FilterIcon from 'src/images/FilterIcon.svg'
import Folder from 'src/images/Folder.svg'
import GroupDuotoneLineIcon from 'src/images/GroupDuotoneLineIcon.svg'
import GroupIcon from 'src/images/GroupIcon.svg'
import House from 'src/images/House.svg'
import Info from 'src/images/Info.svg'
import InfoIcon from 'src/images/InfoIcon.svg'
import LineChartIcon from 'src/images/LineChartIcon.svg'
import Logo from 'src/images/Logo.png'
import MenuToggle from 'src/images/MenuToggle.svg'
import Moon from 'src/images/Moon.svg'
import Move from 'src/images/Move.svg'
import Move_down from 'src/images/Move_down.svg'
import Move_light from 'src/images/Move_light.svg'
import Move_up from 'src/images/Move_up.svg'
import PackageIcon from 'src/images/PackageIcon.svg'
import SceneIcon from 'src/images/SceneIcon.svg'
import Settings from 'src/images/Settings.svg'
import Shapes from 'src/images/Shapes.svg'
import Share from 'src/images/Share.svg'
import ShareDown from 'src/images/ShareDown.svg'
import Sort_arrow from 'src/images/Sort_arrow.svg'
import Sort_arrow_light from 'src/images/Sort_arrow_light.svg'
import Sort_down from 'src/images/Sort_down.svg'
import Sort_down_light from 'src/images/Sort_down_light.svg'
import Sort_up from 'src/images/Sort_up.svg'
import Sort_up_alt from 'src/images/Sort_up_alt.svg'
import Sort_up_light from 'src/images/Sort_up_light.svg'
import SortedAsc from 'src/images/SortedAsc.svg'
import SortedDesc from 'src/images/SortedDesc.svg'
import StoreIcon from 'src/images/StoreIcon.svg'
import Sun from 'src/images/Sun.svg'
import Threedot from 'src/images/Threedot.svg'
import ThreedotOutline from 'src/images/ThreedotOutline.svg'
import TriangleDown from 'src/images/TriangleDown.svg'
import TriangleUp from 'src/images/TriangleUp.svg'
import UnSorted from 'src/images/UnSorted.svg'
import Warning from 'src/images/Warning.svg'
import { STYLED_CONFIG } from 'src/utils/styled-utils'

// type IconsRecord = Record<string, string>
const Icons = {
  Analytics,
  ArrowLeft,
  ArrowRight,
  BagIcon,
  BarsIcon,
  BasketIcon,
  Building,
  ChartIcon,
  Chevron,
  CloudDownload,
  Cube,
  DonutIcon,
  Earth,
  FilterIcon,
  Folder,
  GroupDuotoneLineIcon,
  GroupIcon,
  House,
  Info,
  InfoIcon,
  LineChartIcon,
  Logo,
  MenuToggle,
  Moon,
  Move_down,
  Move_light,
  Move_up,
  Move,
  PackageIcon,
  SceneIcon,
  Shapes,
  Share,
  ShareDown,
  Sort_arrow_light,
  Sort_arrow,
  Sort_down_light,
  Sort_down,
  Sort_up_alt,
  Sort_up_light,
  Sort_up,
  SortedAsc,
  SortedDesc,
  StoreIcon,
  Sun,
  Threedot,
  ThreedotOutline,
  TriangleDown,
  TriangleUp,
  UnSorted,
  Warning,
  Settings
} as const

export type IconKey = keyof typeof Icons

type StyledIconProps = { color: string }
export const StyledIcon = styled('div').withConfig(STYLED_CONFIG)<StyledIconProps>`
  display: inline-block;
  ${margin}
  ${size}

  svg {
    ${size}
  }

  svg circle,
  svg rect,
  svg path {
    stroke: ${props => props.color};
    fill-color: ${props => props.color};
  }
`

export type IconProps = SizeProps & MarginProps & {
  id?: string,
  name: IconKey,
  color?: string,
  title?: string,
  style?: CSSProperties,
  className?: string,
  onClick?: () => void
}

const Icon = ({ name, color, className, title, ...rest }: IconProps): JSX.Element | null => {
  const theme = useTheme()
  const iconColor = color ?? theme.text

  if (Icons[name] === undefined) {
    console.error(`Icon with name ${name} not found`)
    return null
  }

  return (
    <>
      <StyledIcon
        color={iconColor ?? '#ffffff'}
        role={name}
        className={className}
        data-tooltip-id="global-tooltip"
        data-tooltip-content={title}
        size={24}
        {...rest}>
        <ReactSVG src={Icons[name]} />
      </StyledIcon>
    </>
  )
}
export default Icon
