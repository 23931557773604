import moment from 'moment'
import numeral from 'numeral'

import { GraphData, ISODate } from 'src/ApiResponseTypes'
import { DEFAULT_NUMERAL_FORMAT } from 'src/utils/formatting'
import { toTitleCase } from 'src/utils/toTitleCase'

import { SalesGraphDataObject } from './types'

export const xFormatter = (value: string): string => value
export const yFormatter = (value: number): string => numeral(value).format(DEFAULT_NUMERAL_FORMAT)
export const legendFormatter = (value: string): string => toTitleCase(value)

export const getChartData = (history: GraphData, averages: GraphData): SalesGraphDataObject[] => {
  return Object.keys(averages)
    .map((key) => key as keyof GraphData)
    .map((key) => {
      const date = moment(key)
      const average: number = averages[key]

      const salesKey: keyof GraphData = date.format('YYYY-MM-DD') as ISODate
      const salesValue = history[salesKey] ?? undefined

      return {
        average,
        sales: salesValue,
        xTick: moment(key).format('M/YY'),
        date: date.format('MMMM - YYYY')
      }
    })
}
