import React from 'react'
import { TooltipProps } from 'recharts'

import ChartTooltip from 'src/ui/ChartTooltip'
import ChartTooltipTable from 'src/ui/ChartTooltipTable'
import Text from 'src/ui/Text'

type ProjectionGraphTooltipProps = TooltipProps<number, string> & {
  yFormatter: (value: number) => string
}

const ProjectionGraphTooltip: React.FC<ProjectionGraphTooltipProps> = ({ payload, yFormatter }: ProjectionGraphTooltipProps) => {
  if (!payload?.[1]) {
    return null
  }
  const diff = payload[1].payload.diffPercentage
  const month = payload[0].payload.month

  return (
    <ChartTooltip>
      <h3>{month}</h3>
      <ChartTooltipTable payload={payload}>
        {!isNaN(Number(diff)) && (
          <tr key='diff'>
            <td>Difference</td>
            <td><Text danger={diff < 0} success={diff > 0}>{diff > 1 ? '+' : null}{diff}%</Text></td>
          </tr>
        )}
      </ChartTooltipTable>
    </ChartTooltip>
  )
}

export default ProjectionGraphTooltip
