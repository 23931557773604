import React from 'react'
import { generatePath } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'
import numeral from 'numeral'

import { Company, OfferingsApiResponse } from 'src/ApiResponseTypes'
import KpiEntry from 'src/components/KpiEntry'
import KpiEntryWrapper from 'src/components/KpiEntry/KpiEntryWrapper'
import KpiRow from 'src/components/KpiEntry/KpiRow'
import KpiRowEntry from 'src/components/KpiEntry/KpiRowEntry'
import ProjectionGraph from 'src/components/ProjectionGraph'
import SalesGraph from 'src/components/SalesGraph'
import ExpandableTable from 'src/components/Table/ExpandableTable'
import useTable from 'src/components/Table/useTable'
import { ColumnPreset } from 'src/components/Table/utils'
import { API, Path } from 'src/config/paths'
import useDataLoader from 'src/hooks/useDataLoader'
import { useLocales } from 'src/providers/LocaleProvider'
import Box from 'src/ui/Box'
import Flex, { FlexDivider } from 'src/ui/Flex'
import Link from 'src/ui/Link'
import Tag from 'src/ui/Tag'

const riskThreshold = 0.75

type DataByBusinessgroup = {
  sales: number,
  potential: number,
  risk: number,
  readiness: number,
  external_id: string,
  subRows?: DataByBusinessgroup[]
}

type CompanyOverviewTabProps = {
  company: Company
}

const DATA_URL = `${API}/api/offering`
const CompanyOverviewTab: React.FC<CompanyOverviewTabProps> = ({ company }: CompanyOverviewTabProps) => {
  const { moneyFormat } = useLocales()
  const moneyUnit = numeral.localeData().currency.symbol
  const { data } = useDataLoader<OfferingsApiResponse>(DATA_URL)

  const columns: Array<ColumnDef<DataByBusinessgroup>> = React.useMemo(() => [
    {
      id: 'label',
      accessorKey: 'label',
      header: 'Name',
      meta: { flex: 2 }
    },
    {
      id: 'risk',
      accessorKey: 'risk',
      header: 'Risk',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => getValue<number>() > riskThreshold
        ? <Tag variation='danger'>Risk</Tag>
        : null
    },
    {
      id: 'sales',
      accessorKey: 'sales',
      header: 'Net sales',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue<number>()).format(moneyFormat)
    },
    {
      id: 'readiness',
      accessorKey: 'readiness',
      header: 'Readiness',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue, row }) => {
        const isQuoted = Object.keys(company.quoted ?? {}).includes(row.original.external_id)

        return (
          isQuoted
            ? <Tag variation='info'>Quoted</Tag>
            : getValue<number>() > riskThreshold
              ? <Tag>Readiness</Tag>
              : null
        )
      }
    },
    {

      id: 'potential',
      accessorKey: 'potential',
      header: 'Potential',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(moneyFormat)
    }
  ], [])

  const tableData = React.useMemo(() => {
    const {
      sales_by_business_group,
      sales_by_offering,
      potential_by_business_group,
      potential_by_offering,
      risk_by_offering,
      risk_by_business_group,
      readiness_by_business_group,
      readiness_by_offering
    } = company

    const businessGroups: DataByBusinessgroup[] = (data?.business_groups ?? [])
      .map((bg) => ({
        external_id: `bg-${bg.external_id}`,
        label: bg.label,
        sales: sales_by_business_group[bg.external_id],
        potential: potential_by_business_group[bg.external_id],
        risk: risk_by_business_group[bg.external_id]?.score ?? null,
        readiness: readiness_by_business_group[bg.external_id]?.score ?? null,
        subRows: data?.offerings
          .filter((offering) => offering.business_group.external_id === bg.external_id)
          .map((offering) => {
            return {
              external_id: offering.external_id,
              label: offering.label,
              sales: sales_by_offering[offering.external_id],
              potential: potential_by_offering[offering.external_id],
              risk: risk_by_offering[offering.external_id]?.score ?? null,
              readiness: readiness_by_offering[offering.external_id]?.score ?? null
            }
          })
      }))

    return businessGroups
  }, [company, data])

  const { table } = useTable<DataByBusinessgroup>({
    id: 'company-by-business-group',
    columns,
    data: tableData,
    getSubRows: row => row.subRows,
    getRowId: row => row.external_id
  })

  return (
    <>
      <Flex
        gridArea="left"
        flexDirection='column'
        overflow="visible">
        <Box flex="0 0 auto">
          <ExpandableTable
            isLoading={data === undefined}
            table={table}
            title="Business groups"/>
        </Box>
        <Box title="About" flex="0 0 auto">
          <KpiRow>
            {company.industry.label && (
              <KpiRowEntry
                title="Industry">
                <span>{company.industry.label}</span>
              </KpiRowEntry>
            )}
            <KpiRowEntry
              title="Revenue"
              value={company.turnover ?? 0}
              unit="currency"/>
            <KpiRowEntry
              title="Headcount"
              value={company.staff ?? 'unknown'} unit=""/>
            <KpiRowEntry
              title="Profit"
              value={company.profit ?? 0} unit="%"/>
            <KpiRowEntry
              title="Revenue development"
              value={company.turnover_development ?? 0}
              unit="%"/>
            {company.accounts.length > 0 && (
              <KpiRowEntry
                title="Accountables">
                <div>
                  {company.accounts.map((user) => (
                    <div key={user.external_id}>
                      {user.label}
                      {user.business_group && (
                        <span>{' '}(<Link to={generatePath(Path.BUSINESS_GROUP, { businessGroupId: user.business_group.external_id ?? null })}>{user.business_group.label})</Link></span>
                      )}
                    </div>
                  ))}
                </div>
              </KpiRowEntry>
            )}
          </KpiRow>
        </Box>
      </Flex>
      <FlexDivider gridArea="divider" />
      <Flex gridArea="right" flexDirection='column' overflow="visible">
        <KpiEntryWrapper>
          <KpiEntry title="Net sales (LTM)" value={company.sales ?? 0} unit="currency"/>
          <KpiEntry title="12 month projection" value={company.projection ?? 0} unit="currency"/>
          <KpiEntry title="Potential" value={company.potential ?? 0} unit="currency"/>
        </KpiEntryWrapper>
        <Box
          title={`Projected (${moneyUnit})`}
          flex="0 0 auto">
          <ProjectionGraph projections={company.projections} history={company.history}/>
        </Box>
        <Box
          title={`Rolling 12 months (${moneyUnit})`}
          flex="0 0 auto">
          <SalesGraph averages={company.averages} history={company.history}/>
        </Box>
      </Flex>
    </>
  )
}

export default CompanyOverviewTab
