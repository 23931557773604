import React from 'react'
import { isString } from 'lodash'
import styled from 'styled-components'
import { flex, FlexProps, gridArea, GridAreaProps, gridColumn, GridColumnProps, height, HeightProps, overflow, OverflowProps, padding, PaddingProps, width, WidthProps } from 'styled-system'

import Icon, { StyledIcon } from 'src/components/Icon'
import { ReactChildren } from 'src/types'
import { STYLED_CONFIG } from 'src/utils/styled-utils'

import Tooltip from './Tooltip'

type StyledBoxProps = WidthProps
& PaddingProps
& FlexProps
& OverflowProps
& GridAreaProps
& HeightProps
& GridColumnProps

const BoxTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  h3 {
    padding:0;
    margin:0;
  }

  ${StyledIcon} {
    margin-right: 4px;
  }
`

const BoxTitle = styled.h3`
  padding-top:0;
  margin-top:0;
  font-size: 16px;
`

export const StyledBox = styled('div')
  .withConfig(STYLED_CONFIG)<StyledBoxProps>`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 42px;
  border-top: none;
  ${height};
  ${width};
  ${flex};
  ${padding};
  ${overflow};
  ${gridArea};
  ${gridColumn};

  &:not(:first-child) {
    border-top: ${props => props.theme.borderStrongWidth} solid ${props => props.theme.borderColorStrong};
  }
`

type BoxProps = StyledBoxProps & {
  children: ReactChildren,
  title?: string,
  description?: string | ReactChildren
}

const Box: React.FC<BoxProps> = ({ children, title, description, ...rest }: BoxProps) => (
  <StyledBox {...rest}>
    {title && (
      <BoxTop>
        {description && (
          isString(description)
            ? <Icon name="Info" title={description} />
            : (
              <>
                <Icon name="Info" data-tooltip-id={`${title}`}/>
                <Tooltip id={`${title}`}>
                  {description}
                </Tooltip>
              </>)
        )}
        <BoxTitle>
          {title}
        </BoxTitle>
      </BoxTop>
    )}
    {children}
  </StyledBox>
)

export default Box
