import React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { RowSelectionState } from '@tanstack/react-table'
import hash from 'object-hash'
import styled from 'styled-components'

import { BreakdownApiResponse, BreakdownIndustry, BreakdownOffering } from 'src/ApiResponseTypes'
import CustomerByTurnoverGraph from 'src/components/CustomersByTurnoverGraph'
import ErrorPage from 'src/components/ErrorPage'
import Filters from 'src/components/Filters'
import FilterButton from 'src/components/Filters/FilterButton'
import KpiEntry from 'src/components/KpiEntry'
import KpiEntryWrapper from 'src/components/KpiEntry/KpiEntryWrapper'
import PageTop from 'src/components/PageTop'
import PenetrationGraph from 'src/components/PenetrationGraph'
import Tabs, { Tab } from 'src/components/Tabs'
import { API, Path } from 'src/config/paths'
import useAsyncDataLoader from 'src/hooks/useAsyncDataLoader'
import { useFilters } from 'src/providers/FiltersProvider'
import Box from 'src/ui/Box'
import Button from 'src/ui/Button'
import Flex, { FlexDivider } from 'src/ui/Flex'
import LinkButton from 'src/ui/LinkButton'
import LoadingIndicator from 'src/ui/LoadingIndicator'
import PageContent from 'src/ui/PageContent'
import { createQueryParams } from 'src/utils/queryParams'

import IndustriesTab from './tabs/IndustriesTab'
import OfferingsTab from './tabs/OfferingsTab'
import ResourcesTab from './tabs/ResourcesTab'
import MarketAnalysisFilters from './MarketAnalysisFilters'

type LocalMarketAnalysisState = {
  selectedIndustries?: RowSelectionState,
  selectedOfferings?: RowSelectionState,
  selectedResources?: RowSelectionState
}

const SelectionBubble = styled.div`
  border-radius: 20px;
  min-width: 20px;
  height: 20px;
  background: ${props => props.theme.brandPink};
  color: ${props => props.theme.invertText};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin: 0 0 0 6px;
  padding: 2px 6px;
`

const DATA_URL = `${API}/api/breakdown`

const GrowthPlanning: React.FC = () => {
  const { tabId } = useParams()
  const { data, loadData, error } = useAsyncDataLoader<BreakdownApiResponse>(DATA_URL)
  const { selectedFilters } = useFilters()

  const [marketAnalysisState, saveMarketAnalysisState] = useLocalStorage<LocalMarketAnalysisState>('market-analysis-tool', {})
  const [selectedIndustries, setSelectedIndustries] = React.useState<RowSelectionState>(marketAnalysisState?.selectedIndustries ?? {})
  const [selectedOfferings, setSelectedOfferings] = React.useState<RowSelectionState>(marketAnalysisState?.selectedOfferings ?? {})
  const [selectedResources, setSelectedResources] = React.useState<RowSelectionState>(marketAnalysisState?.selectedResources ?? {})
  const [companiesUrl, setCompaniesUrl] = React.useState<string>('')

  const tabs: Tab[] = React.useMemo(() => {
    const amountOfSelectedOfferings = Object.keys(selectedOfferings ?? {}).length
    const amountOfSelectedIndustries = Object.keys(selectedIndustries ?? {}).length
    const amountOfSelectedResources = Object.keys(selectedResources ?? {}).length
    return [
      {
        path: generatePath(Path.MARKET_ANALYSIS),
        label: 'Offering groups',
        children: <>{amountOfSelectedOfferings > 0 && (<SelectionBubble>{amountOfSelectedOfferings}</SelectionBubble>)}</>
      },
      {
        path: generatePath(Path.MARKET_ANALYSIS_TAB, { tabId: 'industry' }),
        label: 'Industry classification',
        children: <>{amountOfSelectedIndustries > 0 && (<SelectionBubble>{amountOfSelectedIndustries}</SelectionBubble>)}</>
      },
      {
        path: generatePath(Path.MARKET_ANALYSIS_TAB, { tabId: 'resource' }),
        label: 'Growth planning',
        children: <>{amountOfSelectedResources > 0 && (<SelectionBubble>{amountOfSelectedResources}</SelectionBubble>)}</>
      }
    ]
  }, [selectedIndustries, selectedOfferings, selectedResources])

  const handleIndustryChange = React.useCallback((selection: RowSelectionState) => {
    setSelectedIndustries(selection)
  }, [])

  const handleOfferingChange = React.useCallback((selection: RowSelectionState) => {
    setSelectedOfferings(selection)
  }, [])

  const handleResourceChange = React.useCallback((selection: RowSelectionState) => {
    setSelectedResources(selection)
  }, [])

  const handleResetClick = React.useCallback(() => {
    setSelectedIndustries({})
    setSelectedOfferings({})
    setSelectedResources({})
  }, [])

  React.useEffect(() => {
    const filters = {
      industries: Object.keys(selectedIndustries ?? {}),
      offerings: Object.keys(selectedOfferings ?? {}),
      ...selectedFilters
    }

    const arrays: string[][] = Object.keys(selectedResources ?? {})
      .map(i => i.split('?'))

    const resourceObj: Record<string, string | string[]> = {}
    arrays.forEach((arr: string[]) => {
      const key = `value_groups[${arr[0]}]`
      const value = arr[1]
      resourceObj[key] = [...(resourceObj[key] ?? []), value]
    })

    loadData({ ...resourceObj, ...filters })
    setCompaniesUrl(createQueryParams({ ...resourceObj, ...filters }))

    saveMarketAnalysisState({
      selectedIndustries,
      selectedOfferings,
      selectedResources
    })
  }, [selectedIndustries, selectedOfferings, selectedResources, selectedFilters])

  const offeringsHash = `${hash(JSON.stringify(data?.offerings ?? {}))}`
  const offerings: BreakdownOffering[] = React.useMemo(() => Object.values(data?.offerings ?? {})
    , [offeringsHash])

  const industriesHash = `${hash(JSON.stringify(data?.industries ?? {}))}`
  const industries: BreakdownIndustry[] = React.useMemo(() => Object.values(data?.industries ?? {})
    , [industriesHash])

  if (error) {
    return <ErrorPage title={error.message} />
  }

  if (!data) {
    return <LoadingIndicator fullPage />
  }

  return (
    <Flex>
      <Flex flexDirection="column">
        <PageTop pageTitle="Advanced Filtering">
          <FilterButton hideWhenOpen/>
          <Button slim onClick={handleResetClick} variation='secondary'>Reset selections</Button>
          <LinkButton slim to={`/companies?${companiesUrl}`} variation='primary'>See the companies</LinkButton>
        </PageTop>
        <PageContent>
          <Tabs tabs={tabs} gridArea="tab" />
          <Flex
            gridArea="left"
            flexDirection="column"
            overflow={['visible', 'auto']}>
            <Flex>
              {tabId === undefined && (
                <OfferingsTab
                  offerings={offerings}
                  onChange={handleOfferingChange}
                  initialSelection={selectedOfferings}
                />
              )}

              {tabId === 'industry' && (
                <IndustriesTab
                  industries={industries}
                  onChange={handleIndustryChange}
                  initialSelection={selectedIndustries}
                />
              )}

              {tabId === 'resource' && (
                <ResourcesTab
                  breakdownData={data}
                  onChange={handleResourceChange}
                  initialSelection={selectedResources}
                />
              )}
            </Flex>
          </Flex>

          <FlexDivider gridArea="divider" />
          <Flex
            flexDirection="column"
            gridArea="right"
            overflow={['visible', 'auto']}>
            <KpiEntryWrapper>
              <KpiEntry
                width="33.33%"
                title="Customers"
                value={data.totals.active ?? 0} unit="" />
              <KpiEntry
                width="33.33%"
                title="Net sales"
                value={data.totals.sales ?? 0} unit="currency" />
              <KpiEntry
                width="33.33%"
                title="Growth potential"
                value={data.totals.potential ?? 0} unit="currency" />
            </KpiEntryWrapper>

            <Box flex="0 0 auto">
              <h3>Number of customers, Net Sales and Potential by Company Size</h3>
              <CustomerByTurnoverGraph data={data} />
            </Box>

            <Box flex="0 0 auto">
              <h3>Offering penetration, number of customers, net sales and average ARPA</h3>
              <PenetrationGraph penetration={data.penetration} />
            </Box>
          </Flex>

        </PageContent>
      </Flex>
      <Filters standalone />
    </Flex>
  )
}

const GrowthWithPlanning: React.FC = () => (
  <MarketAnalysisFilters id="market-analysis">
    <GrowthPlanning />
  </MarketAnalysisFilters>
)

export default GrowthWithPlanning
