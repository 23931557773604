import styled from 'styled-components'

import { Company, Offering } from 'src/ApiResponseTypes'

type Entity = {
  label: string
}

const Whitespace = styled.span`
  display: block;
  border: solid 1px rgba(0 0 0 / 25%);
  padding: 0.2rem 0.5rem;
  min-height: 1.5rem;
  background-color: black;
  color: white;
  border-radius: 30px;
  width: 30px;
  height: 30px;

  &.more-potential {
    background-color: #3bb001;
  }

  &.more-sales {
    background-color: #ffc107;
    color: black;
  }

  &.no-potential {
    background-color: white !important;
    color: black;
  }
`

export const getWhitespaceColumn = (entity: Entity | Offering, row: Company): JSX.Element => {
  const classes = []
  let title = ''

  const company = row.label
  const offeringOrBusinessUnit = entity.label

  if (row.sales !== null && row.sales <= 0) {
    classes.push('no-sales')
    title = `No sales in ${offeringOrBusinessUnit} for ${company}`
  }

  if (row.potential !== null && row.potential <= 0) {
    classes.push('no-potential')
    title = `No potential in ${offeringOrBusinessUnit} for ${company}`
  }

  if (row.sales !== null && row.potential !== null && row.sales < row.potential) {
    classes.push('more-potential')
    title = `More potential in ${offeringOrBusinessUnit} for ${company}`
  }

  if (row.sales !== null && row.potential !== null && row.sales > row.potential) {
    classes.push('more-sales')
    title = `More sales in ${offeringOrBusinessUnit} for ${company}`
  }

  return (
    <Whitespace
      data-tooltip-id="global-tooltip"
      data-tooltip-content={title}
      className={classes.join(' ')}/>
  )
}
