import React, { ChangeEventHandler } from 'react'
import { useDebounce, useUpdateEffect } from 'react-use'
import { isArray, isEmpty } from 'lodash'
import styled from 'styled-components'

import { SelectedFilter, SelectedFilterValue } from 'src/providers/FiltersProvider'

import { Filter } from './types'

export const StyledInput = styled.input`
  background: ${props => props.theme.contentBackground};
  color: ${props => props.theme.text};
  width: 100%;
  height: 59px;
  padding: 10px 18px;
  border-radius: 30px;
  border: 1px solid ${props => props.theme.borderColor};

  &:focus {
    border: 1px solid ${props => props.theme.brandPink} !important;
    outline: none;
  }
`

type FilterInputProps = {
  value?: SelectedFilterValue,
  filter: Filter,
  onChange: (changeObject: SelectedFilter) => void
}

const FilterInput: React.FC<FilterInputProps> = ({ value: selectedFilterValue, filter, onChange, ...rest }: FilterInputProps) => {
  const value: string = isArray(selectedFilterValue)
    ? selectedFilterValue[0]
    : selectedFilterValue ?? ''

  const [inputValue, setInputValue] = React.useState(value)
  const [debouncedValue, setDebouncedValue] = React.useState('')

  useDebounce(() => {
    setDebouncedValue(inputValue)
  }, 300, [inputValue])

  useUpdateEffect(() => {
    onChange({
      name: filter.name,
      value: debouncedValue
    })
  }, [debouncedValue])

  useUpdateEffect(() => {
    if (isEmpty(value)) {
      setInputValue('')
    }
  }, [value])

  const handleTextChange: ChangeEventHandler<HTMLInputElement> = React.useCallback((e) => {
    setInputValue(e.target.value)
  }, [])

  return (
    <StyledInput
      placeholder='Search...'
      type="text"
      onChange={handleTextChange}
      value={inputValue}
      {...rest} />
  )
}

export default FilterInput
