import styled, { css } from 'styled-components'
import { margin, MarginProps } from 'styled-system'

import { ReactChildren } from 'src/types'
import { STYLED_CONFIG } from 'src/utils/styled-utils'

export const ButtonStyles = css`
  font-size: 14px;
  text-decoration: none;
  padding: 12px 16px;
  display: inline-block;
  border: 2px solid ${props => props.theme.buttonPrimaryBorder};
  background-color: ${props => props.theme.buttonPrimaryBg};
  color: ${props => props.theme.buttonPrimaryText};
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg circle,
  svg rect,
  svg path {
    stroke: ${props => props.theme.invertText};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${props => props.theme.buttonPrimaryHoverBg};
    border-color: ${props => props.theme.buttonPrimaryHoverBorder};
    color: ${props => props.theme.buttonPrimaryHoverText};
  }

  &.secondary {
    background-color: ${props => props.theme.buttonSecondaryBg};
    border-color: ${props => props.theme.buttonSecondaryBorder};
    color: ${props => props.theme.buttonSecondaryText};

    &:hover {
      background-color: ${props => props.theme.buttonSecondaryHoverBg};
      border-color: ${props => props.theme.buttonSecondaryHoverBorder};
      color: ${props => props.theme.buttonSecondaryHoverText};

      svg circle,
      svg rect,
      svg path {
        stroke: ${props => props.theme.invertText};
      }
    }
  }

  &.tertiary {
    border-width: 1px;
    color: ${props => props.theme.buttonTrietaryText};
    background-color: ${props => props.theme.buttonTrietaryBg};
    border-color: ${props => props.theme.buttonTrietaryBorder};

    svg circle,
    svg rect,
    svg path {
      stroke: ${props => props.theme.buttonTrietaryText};
    }

    &:hover {
      background-color: ${props => props.theme.buttonTrietaryHoverBg};
      color: ${props => props.theme.buttonTrietaryHoverText};
      border-color: ${props => props.theme.buttonTrietaryHoverBorder};

      svg circle,
      svg rect,
      svg path {
        stroke: ${props => props.theme.invertText};
      }
    }
  }

  &:disabled {
    background-color: ${props => props.theme.buttonDisabledBg};
    color: ${props => props.theme.buttonDisabledText};
    border: 2px solid ${props => props.theme.buttonDisabledBorder};
    cursor: default;
    pointer-events: none;
  }

  &.slim {
    padding: 8px 20px;
  }
`

export type ButtonType = 'primary' | 'secondary' | 'tertiary'
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & MarginProps & {
  children: ReactChildren,
  onClick?: () => void,
  variation?: ButtonType,
  slim?: boolean
}

export const StyledButton = styled('button')
  .withConfig(STYLED_CONFIG)<ButtonProps>`
  ${ButtonStyles}
  ${margin}
`

const Button: React.FC<ButtonProps> = ({
  children,
  variation,
  slim = false,
  ...rest
}: ButtonProps): JSX.Element => {
  const classess = [variation ?? 'primary', slim && 'slim']
  return (
    <StyledButton
      className={classess.join(' ')} {...rest}>
      {children}
    </StyledButton>
  )
}
export default Button
