export type ISODate = `${number}-${number}-${number}`
export type ISODatetime = `${number}-${number}-${number}T${number}:${number}:${number}Z`

// General API response when an error was encountered
export enum ApiStatusType {
  OK = 'ok',
  ERROR = 'error',
  REDIRECT = 'redirect'
}

export enum ActivityStatus {
  ACTIVE = 'active',
  PASSIVE = 'passive',
  HUNTING = 'hunting',
  OTHER = 'other'
}

export enum ActivityStatusInt {
  ACTIVE = 3,
  PASSIVE = 2,
  HUNTING = 1,
  OTHER = 0
}

export enum GrowthStatus {
  NEW_ACCOUNT = 'new_account',
  GROWING = 'growing',
  STABLE = 'stable',
  DECLINING = 'declining',
  NONE = 'none'
}

export enum CompanySort {
  LABEL = 'label',
  BUSINESS_ID = 'business_id',
  INDUSTRY = 'industry',
  TURNOVER = 'turnover',
  TURNOVER_DEVELOPMENT = 'turnover_development',
  POSTAL_CODE = 'postal_code',
  SALES = 'sales',
  COMPARISON = 'comparison',
  STAFF = 'staff',
  PROFIT = 'profit',
  POTENTIAL = 'potential',
  PROJECTION = 'projection',
  RISK = 'risk',
  READINESS = 'readiness'
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

// Growth threshold or the ratio of current and comparison period sales that
// marks the growth status: ratio < 94% is declining, 94% <= ratio < 106% is
// stable and ratio >= 106% is growing
export const GROWTH_THRESHOLD = 0.06

export type HealthApiResponse = {
  status: ApiStatusType.OK,
  message: 'ok',
  started: ISODatetime
}

export type ErrorApiResponse = {
  status: ApiStatusType,
  message: string,
  data?: Record<string, any>,
  stack?: string | string[]
}

export type MatrixFilter = Record<string, string | string[]>

export type OfferingsQuotedData = Record<string, ISODate>
export type GraphData = Record<ISODate, number>
export type GroupedData = Record<string, number>

type StringFilter = undefined | null | string | string[]
type NumberFilter = undefined | null | number | string

export type CompanyApiFilters = {
  currency?: string,
  activity_status?: ActivityStatus,
  business_id?: string,
  business_groups?: StringFilter,
  offerings?: StringFilter,
  company_id?: number,
  external_id?: StringFilter,
  search?: null | string
}

export type CompaniesApiFilters = {
  business_id?: string,
  business_groups?: StringFilter,
  offerings?: StringFilter,
  currency?: string,
  company_id?: number,
  external_id?: string,
  label?: string,
  accounts?: StringFilter, // @DEPRECATED
  industries?: StringFilter,
  members?: StringFilter,
  activity_status?: ActivityStatus | ActivityStatus[],
  growth_status?: GrowthStatus | GrowthStatus[],
  value_groups?: MatrixFilter,
  postal_codes?: StringFilter,
  sort: CompanySort,
  order: SortOrder,
  p?: NumberFilter,
  limit?: NumberFilter,
  search?: StringFilter
}

export type IndustriesApiFilters = {
  label?: string,
  accounts?: string | string[], // @DEPRECATED
  members?: StringFilter,
  business_groups?: StringFilter,
  offerings?: StringFilter,
  activity_status?: ActivityStatus | ActivityStatus[],
  growth_status?: GrowthStatus | GrowthStatus[],
  value_groups?: MatrixFilter,
  postal_codes?: StringFilter,
  search?: StringFilter
}

export type OfferingFilters = {
  label?: string,
  accounts?: StringFilter, // @DEPRECATED
  members?: StringFilter,
  industries?: StringFilter,
  business_groups?: StringFilter,
  offerings?: StringFilter,
  value_groups?: MatrixFilter,
  postal_codes?: StringFilter,
  search?: StringFilter
}

export type BusinessGroupFilters = {
  label?: string,
  accounts?: StringFilter,
  industries?: StringFilter,
  business_groups?: StringFilter,
  offerings?: StringFilter,
  value_groups?: MatrixFilter,
  postal_codes?: StringFilter,
  search?: null | string
}

// endpoint: /api/breakdown
export type BreakdownApiResponse = {
  business_groups: BusinessGroup,
  industries: Record<string, BreakdownIndustry>,
  offerings: Record<string, BreakdownOffering>,
  penetration?: Penetration,
  turnover_groups: TurnoverGroups,
  value_groups?: MatrixFilter,
  status: ApiStatusType.OK,
  cached_at?: null | ISODatetime,
  currency?: string,
  totals: Totals
}

// endpoint: /api/offering
export type OfferingsApiResponse = {
  business_groups: BusinessGroup[],
  offerings: Offering[],
  status: ApiStatusType.OK,
  currency?: string,
  cached_at?: null | ISODatetime
}

export type Penetration = {
  companies: Record<string, number>,
  sales: Record<string, number>,
  arpa: Record<string, number>
}

export type Totals = Record<TurnoverKey, number>

export type CompanyTreeItem = {
  business_id: string,
  parent_id: null | string,
  label: string,
  level: number
}

export type OfferingApiResponse = {
  status: ApiStatusType,
  currency?: string,
  offering?: Offering
}

export type BusinessGroupApiResponse = {
  offering?: BusinessGroup,
  status: ApiStatusType,
  currency?: string,
  cached_at?: null | ISODatetime
}

// endpoint: /api/kpi
export type KpiApiResponse = {
  status: ApiStatusType,
  sales?: KpiData,
  arpa?: KpiData,
  business_group_penetration?: KpiData,
  offering_penetration?: KpiData,
  new_accounts?: KpiData,
  stable?: KpiData,
  growing?: KpiData,
  declining?: KpiData,
  passive?: KpiData,
  active?: KpiData,
  pareto?: KpiData,
  currency?: string,
  cached_at?: null | ISODatetime
}

// endpoint: /api/companies
export type CompaniesApiResponse = {
  status: ApiStatusType,
  companies: any[] | Company[],
  count: number,
  page: number,
  pages: number,
  limit: number,
  sort: CompanySort,
  order: SortOrder,
  search: null | string,
  currency?: string,
  cached_at?: null | ISODatetime
}

// endpoint: /api/companies/[companyId]
export type CompanyApiResponse = {
  status: ApiStatusType,
  company?: Company,
  currency?: string,
  cached_at?: null | ISODatetime
}

// endpoint: /api/attributes/filters
export type FiltersResponse = {
  status: ApiStatusType,
  turnover_groups: FilterTurnoverGroup[],
  accounts: Account[],
  business_groups: BusinessGroup[],
  offerings: Offering[],
  industries: Industry[],
  cached: string,
  currencies: Currency[],
  postal_codes?: PostalCode[]
}

export type PostalCode = {
  label: string,
  external_id: string
}

export type Currency = {
  label: string,
  external_id: string
}

export type FilterTurnoverGroup = {
  turnover_group_id: string,
  label: string
}

export type Account = {
  external_id: string,
  label: string,
  members: Account[],
  business_group: BusinessGroup
}

export type TurnoverKey = 'active'
| 'passive'
| 'hunting'
| 'sales'
| 'potential'

export type TurnoverGroups = Record<TurnoverKey, TurnoverGroup>

// @NOTE: this is client-specific and has to be changed for production
export type TurnoverGroup = {
  '0-1M€': string,
  '1-5M€': string,
  '5-50M€': string,
  '50-100M€': string,
  '100-500M€': string,
  '500M€-1B€': string,
  '>1B€': string,
  'null': string,
  'unknown': string
}

export type BusinessGroup = {
  external_id: string,
  label: string,
  sales: number,
  potential: number,
  risk: number,
  has_risk: number,
  has_readiness: number,
  averages: GraphData,
  projections?: GraphData,
  projection: number,
  history?: GraphData,
  industries: Record<string, BreakdownIndustry>,
  active: number,
  passive: number,
  hunting: number,
  billing_at_risk: number,
  pulse: Pulse,
  risk_factors: RiskFactors,
  readiness_factors: RiskFactors,
  parent: never
}

export type OfferingParent = {
  external_id: string,
  label: string
}

export type Offering = {
  parent_id?: string,
  external_id: string,
  offering_id: string,
  label: string,
  sales: number,
  potential: number,
  risk: number,
  has_risk: number,
  risks: number,
  readiness: null,
  has_readiness: number,
  projection: number,
  history?: GraphData,
  active: number,
  passive: number,
  hunting: number,
  billing_at_risk: number,
  business_group: BusinessGroup,
  parent?: OfferingParent,
  industries: Record<string, BreakdownIndustry>,
  projections?: GraphData,
  averages: GraphData,
  penetration: Penetration,
  pulse: Pulse,
  risk_factors: RiskFactors,
  readiness_factors: RiskFactors
}

export type KpiData = {
  current: number,
  comparison: number,
  change: number,
  history: GraphData
}

export type BreakdownIndustry = {
  external_id: string,
  parent_id: string,
  label: string,
  level: number,
  sales: number,
  comparison: number,
  potential: number,
  active: number,
  passive: number,
  hunting: number,
  other: number
}

export type BreakdownOffering = {
  active: number,
  offering_id: string,
  business_group_id: string,
  comparison: number,
  external_id: string,
  hunting: number,
  label: string,
  passive: number,
  potential: number,
  sales: number
}

export type IndustriesObj = Record<string, Industry>
export type IndustrySmall = {
  industry_id: string,
  external_id: string,
  label: string
}

export type Industry = {
  external_id: string,
  parent_id: string,
  label: string,
  level: number,
  sales: number,
  potential: number,
  risk: number,
  has_risk: number,
  readiness: number,
  has_readiness: number,
  projection: number,
  active: number,
  passive: number,
  hunting: number
}

export type Risk = {
  risk_type: string,
  label: string,
  is_positive: boolean,
  factor: number,
  severity: string,
  type: string
}

export type Readiness = Risk

export type RiskFactors = Record<string, Risk>
export type ReadinessFactors = Record<string, Readiness>

export type PulseValue = {
  label: string,
  sales: number,
  value: number
}
export type Pulse = Record<string, PulseValue>

export type CompanyPulseValue = {
  external_id: string,
  label: string,
  modifier: string,
  count: number,
  sum: number
}
export type CompanyPulse = Record<string, CompanyPulseValue>

export type RiskByType = {
  score: number,
  sales: number
}

export type GroupedRiskByType = Record<string, RiskByType>

export type Company = {
  // External company attributes
  business_id: string,
  label: string,
  postal_code: null | string,
  industry: IndustrySmall,
  turnover: null | number,
  turnover_development: null | number,
  staff: null | number,
  profit: null | number,
  currency: null | string,
  accounts: Account[],

  // Company group view
  tree: CompanyTreeItem[],

  // Offerings and offering groups
  business_groups: BusinessGroup[],
  offerings: Offering[],

  // Company sales data
  sales: null | number, // Sales within 12 last months
  comparison: null | number, // Comparison period sales (12 months before the last 12 months)
  first_sales: ISODate, // First sales date
  last_sales: ISODate, // Last sales date
  sales_by_business_group: GroupedData,
  sales_by_offering: GroupedData,
  history: GraphData, // Discrete 12 month sales history
  averages: GraphData, // Rolling 12 month average sales history

  // Status flags
  activity_status: ActivityStatus,
  growth_status?: null | GrowthStatus,

  // Company potential
  potential: null | number,
  potential_by_business_group: GroupedData,
  potential_by_offering: GroupedData,

  // Company projection
  projection: null | number,
  projections: GraphData,
  projection_by_business_group: GroupedData,
  projection_by_offering: GroupedData,

  // Company risk
  risk: null | number,
  max_risk: null | number,
  risk_factors: RiskFactors,
  risk_by_business_group: GroupedRiskByType,
  risk_by_offering: GroupedRiskByType,

  // Company readiness
  readiness: null | number,
  max_readiness: null | number,
  readiness_factors: RiskFactors,
  readiness_by_business_group: GroupedRiskByType,
  readiness_by_offering: GroupedRiskByType,

  // Company pulse
  pulse: CompanyPulse,
  quoted?: OfferingsQuotedData
}
