type RiskDescription = {
  label: string,
  description?: string,
  url?: string
}

type RiskRecord = Record<string, RiskDescription>
const RiskDescriptions: RiskRecord = {
  'VIX Close': {
    label: 'VIX Close',
    description: 'Business volatility index (Fear index or fear gauge).',
    url: 'https://en.wikipedia.org/wiki/VIX'
  },
  Compatible_Freight__c: {
    label: 'Freight Compatible',
    description: 'Is this business compatible with Posti freight offering',
    url: ''
  },
  Product_groups_purchased: {
    label: 'Product groups purchased',
    url: ''
  },
  european_news_index: {
    label: 'Economic uncertainty index',
    description: 'European policy-related economic uncertainty',
    url: 'https://www.policyuncertainty.com/europe_monthly.html'
  },
  Id_Count_lead: {
    label: 'Number of leads',
    description: 'How many unique sales leads are in CRM for this customer.',
    url: ''
  },
  Compatible_Parcel__c: {
    label: 'Parcel Compatible',
    url: ''
  },
  RISK_Max: {
    label: 'RISK Max',
    description: 'Highest risk score for this customer.',
    url: ''
  },
  Import__c: {
    label: 'Does import',
    url: ''
  },
  Customer_s_Register_is_over_10k__c: {
    label: '10k+ customer register size',
    url: ''
  },
  B2B_Online_Shop__c: {
    label: 'B2B e-commerce',
    description: 'Is this business running a B2B e-commerce',
    url: ''
  },
  Age_of_customership: {
    label: 'Customer lifetime',
    url: ''
  },
  Case_Count: {
    label: 'Customer support ticket count',
    description: 'Customer service - amount of customer cases.',
    url: ''
  },
  Customer_s_Register_is_over_10k: {
    label: '10k+ customer register size',
    url: ''
  },
  development_of_turnover: {
    label: 'Turnover development',
    description: 'Development of company turnover. ',
    url: ''
  },
  Export__c: {
    label: 'Does export',
    url: ''
  },
  Finland_bci: {
    label: 'Business Confidence Index (Finland)',
    description: 'Business Confidence Index',
    url: 'https://data.oecd.org/leadind/business-confidence-index-bci.htm'
  },
  Finland_cci: {
    label: 'Customer Confidence Index (Finland)',
    description: 'Customer Confidence Index',
    url: 'https://data.oecd.org/leadind/consumer-confidence-index-cci.htm'
  },
  Finland_inflation: {
    label: 'Inflation (Finland)',
    description: 'Inflation rate for Finland.',
    url: ''
  },
  Finland_unemployment: {
    label: 'Unemployment rate (Finland)',
    description: 'Uneployment rate for Finland.',
    url: ''
  },
  Has_B2C_Operations: {
    label: 'B2C business',
    description: 'Is this a B2C business, selling to consumers.',
    url: ''
  },
  'Lead coun': {
    label: 'Lead monetary value',
    description: 'Total monetery value of leads in CRM',
    url: ''
  },
  'Lead count': {
    label: 'Lead monetary value',
    description: 'Total monetery value of leads in CRM',
    url: ''
  },
  opportunity_amount_eur: {
    label: 'Open opportunity value',
    description: 'Value of open opportunities.',
    url: ''
  },
  Purchase_months: {
    label: 'Purchase activity',
    description: 'Amount of months the customer has made purchases.',
    url: ''
  },
  Retailer_Network__c: {
    label: 'Part of retail network',
    description: 'Is this business part of a retail chain or ',
    url: ''
  },
  revenue_growth_eur: {
    label: 'Revenue growth',
    description: 'Revenue growth (in euros)',
    url: ''
  },
  revenue_percentage_growth: {
    label: 'Revenue growth percentage',
    description: 'Revenue growth (in percentages)',
    url: ''
  },
  Risk: {
    label: 'Risk score',
    description: 'Average risk score',
    url: ''
  },
  'task count': {
    label: 'Task count',
    description: 'Total number of tasks in CRM.',
    url: ''
  }
}

export default RiskDescriptions
