import React from 'react'

import { BusinessGroup, FiltersResponse, Offering } from 'src/ApiResponseTypes'
import { Filter } from 'src/components/Filters/types'
import { API } from 'src/config/paths'
import useDataLoader from 'src/hooks/useDataLoader'
import { FiltersProvider } from 'src/providers/FiltersProvider'
import { ReactChild } from 'src/types'
import Box from 'src/ui/Box'

type CustomersTabProps = {
  entity: BusinessGroup | Offering,
  lockedFilters?: Record<string, string | string[]>,
  children: ReactChild
}

const FILTER_URL = `${API}/api/attributes/filters`
const CustomersTab: React.FC<CustomersTabProps> = ({ lockedFilters, children }: CustomersTabProps) => {
  const { data: filterData } = useDataLoader<FiltersResponse>(FILTER_URL)

  const allFilters: Filter[] = React.useMemo(() => {
    if (!filterData) {
      return []
    }

    return [{
      label: 'Status',
      name: 'status',
      type: 'multiselect',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Passive', value: 'passive' },
        { label: 'NB Target', value: 'hunting' },
        { label: 'New', value: 'new_accounts' },
        { label: 'Stable', value: 'stable' },
        { label: 'Growing', value: 'growing' },
        { label: 'Declining', value: 'declining' }
      ]
    },
    {
      label: 'Search',
      name: 'search',
      type: 'text',
      options: []
    }]
  }, [filterData])

  return (
    <FiltersProvider
      id="offering-customers"
      initialValue={{ allFilters, lockedFilters: { ...lockedFilters } }}>
      <Box gridArea="left" gridColumn="left / right">
        {children}
      </Box>
    </FiltersProvider>
  )
}

export default CustomersTab
