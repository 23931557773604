import { groupBy } from 'lodash'

import { BreakdownIndustry } from 'src/ApiResponseTypes'

export type IndustryRow = BreakdownIndustry & {
  subRows?: BreakdownIndustry[]
}

export const createIndustryTree = (industries: BreakdownIndustry[]): IndustryRow[] => {
  const groupedByParent = groupBy(industries, 'parent_id')

  const expandableList: IndustryRow[] = []
  industries.forEach((industry) => {
    const parentId = industry.parent_id
    if (parentId === null) {
      let subRows: IndustryRow[] = []

      const myId = industry.external_id
      const hasChildren = groupedByParent[myId] !== undefined

      if (hasChildren) {
        const children = groupedByParent[myId]
        subRows = children.map((child) => {
          const childId = child.external_id
          const grandChildren = groupedByParent[childId]
          return {
            ...child,
            subRows: grandChildren
          }
        })
      }

      expandableList.push({
        ...industry,
        subRows
      })
    }
  })

  return expandableList
}
