import React from 'react'

import CompaniesTable from 'src/components/CompaniesTable/CompaniesTable'
import BasicFilters from 'src/components/Filters/BasicFilters'
import { Filter } from 'src/components/Filters/types'
import PageTop from 'src/components/PageTop'
import Box from 'src/ui/Box'
import PageContent from 'src/ui/PageContent'

export const COMPANIES_STATUS_FILTERS = [
  { label: 'Active', value: 'active' },
  { label: 'Passive', value: 'passive' },
  { label: 'NB Target', value: 'hunting' },
  { label: 'New', value: 'new_accounts' },
  { label: 'Stable', value: 'stable' },
  { label: 'Growing', value: 'growing' },
  { label: 'Declining', value: 'declining' }
]

const Companies: React.FC = () => {
  return (
    <>
      <PageTop pageTitle='Companies' />
      <PageContent>
        <Box gridArea="left" gridColumn="left / right">
          <CompaniesTable showFilters />
        </Box>
      </PageContent>
    </>
  )
}

const CUSTOM_FILTERS: Filter[] = [{
  label: 'Status',
  name: 'status',
  type: 'select',
  isClearable: true,
  options: COMPANIES_STATUS_FILTERS
}, {
  label: 'Search',
  name: 'search',
  type: 'text',
  options: []
}, {
  label: 'Value groups',
  name: 'value_groups',
  type: 'multidimentional',
  options: []
}]

const CompaniesWithFilters: React.FC = () => (
  <BasicFilters
    id="companies-list"
    customFilters={CUSTOM_FILTERS}>
    <Companies />
  </BasicFilters>
)

export default CompaniesWithFilters
