import React from 'react'
import ReactDropdown from 'rc-dropdown'
import { DropdownProps } from 'rc-dropdown/lib/Dropdown'
import styled from 'styled-components'

import Icon from 'src/components/Icon'

import 'rc-dropdown/assets/index.css'

const StyledReactDropdown = styled(ReactDropdown)`
  cursor: pointer;
`

type CustomDropdownProps = Omit<DropdownProps, 'children'> & {
  overlay: DropdownProps['overlay']
}

const Dropdown: React.FC<CustomDropdownProps> = ({ overlay }: CustomDropdownProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const handleCloseMenu = React.useCallback((e: MouseEvent) => {
    if (e.target instanceof Element && e.target.getAttribute('role') === 'menuitem') {
      return
    }

    if (e.target instanceof Element && e.target.getAttribute('class') === 'checkmark') {
      return
    }

    // Not the best way to detect if click is on the Threedot icon, but works for now.
    if (e.target instanceof SVGElement) {
      return
    }

    setIsOpen(false)
  }, [])

  const handleToggle = React.useCallback(() => {
    setIsOpen(prev => !prev)
  }, [])

  React.useEffect(() => {
    document.addEventListener('mousedown', handleCloseMenu)
    return () => {
      document.removeEventListener('mousedown', handleCloseMenu)
    }
  }, [isOpen])

  return (
    <StyledReactDropdown
      visible={isOpen}
      overlay={overlay}
      placement="bottomLeft"
      trigger={['click']}>
      <Icon name="Threedot" onClick={handleToggle} data-id="trigger"/>
    </StyledReactDropdown>
  )
}

export default Dropdown
