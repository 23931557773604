import { TooltipProps } from 'recharts'

import { toTitleCase } from 'src/utils/toTitleCase'

import ChartTooltip from './ChartTooltip'
import ChartTooltipTable from './ChartTooltipTable'

type BasicChartTooltipProps = Omit<TooltipProps<number, string>, 'label'> & {
  label?: string
}

const BasicChartTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }: BasicChartTooltipProps) => {
  if (active && payload?.length) {
    return (
      <ChartTooltip>
        <h3>{toTitleCase(label)}</h3>
        <ChartTooltipTable payload={payload} />
      </ChartTooltip>
    )
  }

  return null
}

export default BasicChartTooltip
