import styled, { css } from 'styled-components'

export const GlobalTooltipStyles = css`
  background: ${props => props.theme.contentBackground} !important;
  color: ${props => props.theme.text} !important;
  padding: 24px !important;
  border-radius: 8px !important;
  border: none;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.18);
  opacity: 1 !important;
  font-size: 14px;
  font-weight: 400;

  > * {
    color: ${props => props.theme.text} !important;
    font-size: 14px;
  }
  > a {
    text-decoration: none !important;
  }
`

const ChartTooltip = styled.div`
  ${GlobalTooltipStyles}
`

export default ChartTooltip
