import React from 'react'

import Icon from 'src/components/Icon'

import Flex from './Flex'
import Text from './Text'

type ErrorTextProps = {
  text: string
}

const ErrorText: React.FC<ErrorTextProps> = ({ text }: ErrorTextProps) => (
  <Flex alignItems="center" justifyContent="center">
    <Icon name="Warning" size={18} mr={2}/>
    <Text secondary fontSize={14}>{text}</Text>
  </Flex>
)

export default ErrorText
