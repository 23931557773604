import React from 'react'
import { generatePath } from 'react-router-dom'
import { ColumnDef, SortingState } from '@tanstack/react-table'
import numeral from 'numeral'

import { BusinessGroup, Offering } from 'src/ApiResponseTypes'
import SimpleTable, { SimpleTableProps } from 'src/components/Table/SimpleTable'
import useTable from 'src/components/Table/useTable'
import { ColumnPreset } from 'src/components/Table/utils'
import { Path } from 'src/config/paths'
import { useFilters } from 'src/providers/FiltersProvider'
import { useLocales } from 'src/providers/LocaleProvider'
import Link from 'src/ui/Link'
import Text from 'src/ui/Text'
import { DEFAULT_NUMERAL_FORMAT } from 'src/utils/formatting'

const INITIAL_SORTING: SortingState = [{
  id: 'label',
  desc: false
}]

type OfferingsTableProps = Omit<SimpleTableProps<Offering>, 'table'> & {
  offerings?: Offering[]
}

const OfferingsTable: React.FC<OfferingsTableProps> = ({ offerings, ...rest }: OfferingsTableProps) => {
  const { moneyFormat } = useLocales()
  const columns: Array<ColumnDef<Offering>> = React.useMemo(() => [
    {
      id: 'label',
      accessorKey: 'label',
      header: 'Name',
      cell: ({ row: { original: data } }) => <Link to={generatePath(Path.OFFERING, { offeringId: data.external_id })}>{data.label}</Link>,
      meta: { flex: 2 }
    },
    {
      id: 'business_group',
      accessorKey: 'business_group',
      header: 'Business unit',
      cell: (item) => {
        const group = item.getValue<BusinessGroup>()
        return <Link to={generatePath(Path.BUSINESS_GROUP, { businessGroupId: group.external_id })}>{group.label}</Link>
      },
      meta: { flex: 1 }
    },
    {
      id: 'has_risk',
      accessorKey: 'has_risk',
      header: () => 'At Churn risk',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(DEFAULT_NUMERAL_FORMAT)
    },
    {
      id: 'has_readiness',
      accessorKey: 'has_readiness',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      header: () => 'Ready to buy',
      cell: ({ getValue }) => numeral(getValue()).format(DEFAULT_NUMERAL_FORMAT)
    },
    {
      id: 'sales',
      accessorKey: 'sales',
      header: 'Net sales',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(moneyFormat)
    },
    {
      id: 'projection',
      accessorKey: 'projection',
      header: 'Projected',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ row, getValue }) => {
        // TODO: Move to config
        const data = row.original
        const diffThreshold = 0.05
        const diffToSales = (data.projection / data.sales) - 1 || 0

        const hasDanger = Math.abs(diffToSales) > diffThreshold && data.projection < data.sales
        const hasSuccess = Math.abs(diffToSales) > diffThreshold && data.projection > data.sales

        return (
          <Text danger={hasDanger} success={hasSuccess}>
            {numeral(getValue()).format(moneyFormat)}
          </Text>
        )
      }
    },
    {
      id: 'potential',
      accessorKey: 'potential',
      header: 'Potential',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(moneyFormat)
    },
    {
      id: 'active',
      accessorKey: 'active',
      header: 'Active',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(DEFAULT_NUMERAL_FORMAT)
    },
    {
      id: 'passive',
      accessorKey: 'passive',
      header: 'Passive',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(DEFAULT_NUMERAL_FORMAT)
    },
    {
      id: 'hunting',
      accessorKey: 'hunting',
      header: 'NB Targets',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(DEFAULT_NUMERAL_FORMAT)
    },
    {
      id: 'risk',
      accessorKey: 'risk',
      header: () => 'Billing at risk',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: ({ getValue }) => numeral(getValue()).format(moneyFormat)
    }
  ], [])

  const { selectedFilters } = useFilters()
  const { table } = useTable({
    id: 'offerings-list',
    columns,
    data: offerings ?? [],
    initialState: {
      sorting: INITIAL_SORTING
    },
    state: {
      globalFilter: selectedFilters.search
    }
  })

  return <SimpleTable table={table} title="Offering groups" {...rest}/>
}

export default OfferingsTable
