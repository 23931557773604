import { Row } from '@tanstack/react-table'
import styled from 'styled-components'
import { padding, PaddingLeftProps } from 'styled-system'

import { ReactChildren } from 'src/types'
import { STYLED_CONFIG } from 'src/utils/styled-utils'

import ChevronButton from './ChevronButton'

type StyledExpandColumnProps = PaddingLeftProps
export const StyledExpandColumn = styled('div')
  .withConfig(STYLED_CONFIG)<StyledExpandColumnProps>`
  display: flex;
  alignItems: center;
  ${padding};
`

export const TextContent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`

export type ExpandColumnProps<T> = {
  row: Row<T>,
  children: ReactChildren
}

const ExpandColumn = <T,>({ row, children }: ExpandColumnProps<T>): JSX.Element => (
  <StyledExpandColumn paddingLeft={row.getCanExpand() ? `${row.depth * 16}px` : `${row.depth * 16 + 12}px`} >
    {row.getCanExpand() && (
      <ChevronButton
        onClick={row.getToggleExpandedHandler()}
        isOpen={row.getIsExpanded()}/>
    )}
    <TextContent>
      {children}
    </TextContent>
  </StyledExpandColumn>
)

export default ExpandColumn
