import React from 'react'

import { KpiApiResponse } from 'src/ApiResponseTypes'
import Filters from 'src/components/Filters'
import BasicFilters from 'src/components/Filters/BasicFilters'
import FilterButton from 'src/components/Filters/FilterButton'
import FilterCount from 'src/components/Filters/FilterCount'
import { KpiCardWrapper } from 'src/components/KpiCard'
import { CardId, KpiCardProps } from 'src/components/KpiCard/KpiCardBase'
import { getCardUnit } from 'src/components/KpiCard/KpiCardWrapper'
import PageTop from 'src/components/PageTop'
import { API } from 'src/config/paths'
import { LangModel } from 'src/config/TranslationEnglish'
import useAsyncDataLoader from 'src/hooks/useAsyncDataLoader'
import { useFilters } from 'src/providers/FiltersProvider'
import { useLocales } from 'src/providers/LocaleProvider'
import Box from 'src/ui/Box'
import Flex, { LAYOUT_GAP } from 'src/ui/Flex'

const SALES_CARDS: CardId[] = [
  'sales',
  'arpa',
  'business_group_penetration',
  'offering_penetration'
]

const ACCOUNT_CARDS: CardId[] = [
  'new_accounts',
  'stable',
  'growing',
  'declining',
  'passive',
  'active',
  'pareto'
]

export type CardIdToLabel = Record<CardId, keyof LangModel>

const REQUEST_CARDS = {
  kpi: [
    ...SALES_CARDS,
    ...ACCOUNT_CARDS
  ]
}

const Kpi: React.FC = () => {
  const { selectedFilters, allFilters } = useFilters()
  const dataUrl = `${API}/api/kpi`

  const { data, loadData, error } = useAsyncDataLoader<KpiApiResponse>(dataUrl)
  const locales = useLocales()

  const offeringsAmount = allFilters.find((item) => item.name === 'offerings')?.options.length
  const businessUnitsAmount = allFilters.find((item) => item.name === 'business_groups')?.options.length
  const getMaxValue = React.useCallback((cardKey: string, current: number): number | undefined => {
    if (cardKey === 'offering_penetration') {
      return offeringsAmount
    }

    if (cardKey === 'business_group_penetration') {
      return businessUnitsAmount
    }
  }, [offeringsAmount, businessUnitsAmount])

  React.useEffect(() => {
    loadData({ ...REQUEST_CARDS, ...selectedFilters })
  }, [selectedFilters])

  const salesCardData: KpiCardProps[] = SALES_CARDS
    .map((cardKey) => ({
      ...(data?.[cardKey]),
      max: getMaxValue(cardKey, data?.[cardKey]?.current ?? 0),
      id: cardKey,
      title: locales[cardKey] ?? cardKey,
      unit: getCardUnit(cardKey)
    }))

  const accountsCardData: KpiCardProps[] = ACCOUNT_CARDS
    .map((cardKey) => ({
      ...(data?.[cardKey]),
      max: getMaxValue(cardKey, data?.[cardKey]?.current ?? 0),
      id: cardKey,
      title: locales[cardKey] ?? cardKey,
      unit: getCardUnit(cardKey)
    }))

  return (
    <Flex>
      <Flex flexDirection='column'>
        <PageTop pageTitle='Home'>
          <FilterCount />
          <FilterButton hideWhenOpen />
        </PageTop>
        <div style={{
          flex: '1 1 auto',
          overflow: 'auto',
          padding: `0 ${LAYOUT_GAP}px ${LAYOUT_GAP}px 0`
        }}>
          <Flex flex="0 0 auto" overflow="visible">
            <Box title="KPIs" padding="0">
              <KpiCardWrapper error={error} data={salesCardData} filters={selectedFilters} />
            </Box>
          </Flex>
          <Flex flex="1 0 auto" overflow="visible">
            <Box title="Accounts" padding="24px 0 0 0">
              <KpiCardWrapper error={error} data={accountsCardData} filters={selectedFilters} />
            </Box>
          </Flex>
        </div>
      </Flex>
      <Filters standalone />
    </Flex>
  )
}

const KpiWithFilters: React.FC = () => (
  <BasicFilters id="kpi-list">
    <Kpi />
  </BasicFilters>
)

export default KpiWithFilters
