import React from 'react'
import { ColumnDef } from '@tanstack/react-table'
import numeral from 'numeral'

import { BusinessGroup, Company, Offering, PulseValue } from 'src/ApiResponseTypes'
import SimpleTable from 'src/components/Table/SimpleTable'
import useTable from 'src/components/Table/useTable'
import { ColumnPreset } from 'src/components/Table/utils'
import { DEFAULT_NUMERAL_FORMAT } from 'src/utils/formatting'

type PulseTableProps = {
  entity: Company | Offering | BusinessGroup,
  autoHeight?: boolean,
  title?: string
}

const PulseTable: React.FC<PulseTableProps> = ({ entity, autoHeight, title }: PulseTableProps) => {
  const moneyUnit = numeral.localeData().currency.symbol
  const columns: Array<ColumnDef<PulseValue>> = React.useMemo(() => [
    {
      id: 'name',
      accessorKey: 'label',
      header: 'Status',
      meta: {
        flex: 1
      }
    },
    {
      id: 'value',
      accessorKey: 'value',
      header: '6 months',
      meta: {
        flex: 1,
        ...ColumnPreset.TEXT_RIGHT.meta
      },
      cell: (row) => numeral(row.getValue()).format(DEFAULT_NUMERAL_FORMAT)
    },
    {
      id: 'sales',
      accessorKey: 'sales',
      header: `${moneyUnit} / last 12 months`,
      meta: {
        flex: 1,
        ...ColumnPreset.TEXT_RIGHT.meta
      },
      cell: (row) => numeral(row.getValue()).format(DEFAULT_NUMERAL_FORMAT)
    }
  ], [])
  // Company pulse value looks different than other pulse values, unify them here
  const pulseItems: PulseValue[] = React.useMemo(() => entity.pulse
    ? Object.values(entity.pulse).map((item) => {
      const isCompanyPulse: boolean = item.modifier !== undefined && item.count !== undefined && item.sum !== undefined
      return isCompanyPulse
        ? {
          label: item.label,
          sales: item.sum,
          value: item.count
        }
        : item
    })
    : []
  , [entity])

  const { table: pulseTable } = useTable<PulseValue>({
    id: 'pulse',
    columns,
    data: pulseItems
  })

  return (
    <SimpleTable table={pulseTable} autoHeight={autoHeight} title={title}/>
  )
}

export default PulseTable
