import React from 'react'
import { ColumnDef } from '@tanstack/react-table'

import { Risk, RiskFactors } from 'src/ApiResponseTypes'
import RiskDescriptions from 'src/config/RiskDescriptions'
import Flex from 'src/ui/Flex'
import { toTitleCase } from 'src/utils/toTitleCase'

import SimpleTable from './Table/SimpleTable'
import useTable from './Table/useTable'
import { ColumnPreset } from './Table/utils'
import Icon from './Icon'

const getSortedFilteredList = (data: RiskFactors, type: string): Risk[] => {
  return Object.entries(data ?? [])
    .filter(([key, item]) => item.type === type && item.factor !== null)
    .sort(([key, a], [key2, b]) => a.factor > b.factor ? 1 : -1)
    .reverse()
    .map(([key, item]) => ({
      ...item,
      label: key
    }))
}

type RRTableProps = {
  data: RiskFactors | undefined,
  reverse?: boolean,
  autoHeight?: boolean,
  title?: string
}

const RiskTable: React.FC<RRTableProps> = ({
  data,
  reverse = false,
  autoHeight = false,
  title
}: RRTableProps) => {
  const tableData: Risk[] = React.useMemo(() => data
    ? [...(getSortedFilteredList(data, 'positive')), ...(getSortedFilteredList(data, 'negative'))]
    : [], [data])

  const columns: Array<ColumnDef<Risk>> = React.useMemo(() => [
    {
      id: 'name',
      accessorKey: 'label',
      header: 'Name',
      cell: ({ getValue }) => {
        const val = getValue<string>()
        const risk = RiskDescriptions[val]
        const cellText = risk ? risk.label : toTitleCase(val)
        return (
          <Flex alignItems="center">
            <span>
              {cellText}
            </span>
            {risk.description && (
              <Icon
                ml="4px"
                name="Info"
                size="16px"
                title={risk.description}/>
            )}
          </Flex>
        )
      },
      meta: {
        flex: 1
      }
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: 'Type',
      meta: {
        flex: 1,
        ...ColumnPreset.TEXT_RIGHT.meta
      },
      cell: (item) => {
        const value = item.getValue()
        const isPositive = reverse ? !item.row.original.is_positive : item.row.original.is_positive
        return value === 'positive'
          ? <Icon name='SortedDesc' color={isPositive ? '#067a16' : '#ff0000'}/>
          : <Icon name='SortedAsc' color={isPositive ? '#067a16' : '#ff0000'}/>
      }
    },
    {
      id: 'severity',
      accessorKey: 'severity',
      header: 'Severity',
      meta: {
        flex: 1,
        ...ColumnPreset.TEXT_RIGHT.meta
      }
    }
  ], [reverse])

  const { table } = useTable<Risk>({
    id: 'risk',
    columns,
    data: tableData
  })

  return <SimpleTable table={table} autoHeight={autoHeight} title={title}/>
}

export default RiskTable
