import { Table } from '@tanstack/react-table'
import styled from 'styled-components'

import Icon, { StyledIcon } from '../Icon'

const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(33px + 33px + 5px);
`

const PaginationButton = styled.button`
  border: none;
  font-size: 16px;
  background: transparent;
  cursor: pointer;
  border-radius: 33px;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledIcon} {
    margin-bottom: 7px;
  }

  &:hover {
    background: ${props => props.theme.navItemActiveBg};
    color: ${props => props.theme.navItemHoverText};

    ${StyledIcon} path {
      stroke: ${props => props.theme.navItemHoverText};
    }
  }

  &:disabled {
    pointer-events: none;

    ${StyledIcon} path {
      stroke: ${props => props.theme.borderColor};
    }
  }
`

type PaginationProps<T> = {
  table: Table<T>
}

const Pagination = <T,>({ table }: PaginationProps<T>): JSX.Element | null => {
  if (table.getPageCount() < 2) {
    return null
  }

  return (
    <StyledPagination>
      <PaginationButton
        data-testid="pagination-previous-page"
        onClick={() => { table.previousPage() }}
        disabled={!table.getCanPreviousPage()}
      >
        <Icon name="ArrowLeft" size={12} ml={0}/>
      </PaginationButton>
      <PaginationButton
        data-testid="pagination-next-page"
        onClick={() => { table.nextPage() }}
        disabled={!table.getCanNextPage()}
      >
        <Icon name="ArrowRight" size={12} ml={1}/>
      </PaginationButton>
    </StyledPagination>
  )
}

export default Pagination
