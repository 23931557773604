import styled from 'styled-components'

import { ReactChildren } from 'src/types'
import { LAYOUT_GAP } from 'src/ui/Flex'

import Breadcrumb from './Breadcrumb'

const PageTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0px;
  margin-right: ${LAYOUT_GAP}px;
`

const PageActions = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px 0 0;

  > * {
    margin: 0 8px 0 0;
  }
`

type PageTopProps = {
  pageTitle?: string,
  children?: ReactChildren
}

const PageTop: React.FC<PageTopProps> = ({ children, pageTitle }: PageTopProps) => (
  <PageTopWrapper>
    <div>
      <Breadcrumb pageTitle={pageTitle}/>
      {pageTitle && <h1>{pageTitle}</h1>}
    </div>
    <PageActions>
      {children}
    </PageActions>
  </PageTopWrapper>
)

export default PageTop
