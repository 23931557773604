import React from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { OfferingApiResponse } from 'src/ApiResponseTypes'
import ErrorPage from 'src/components/ErrorPage'
import CustomersTab from 'src/components/OfferingTabs/CustomersTab'
import OverviewTab from 'src/components/OfferingTabs/OverviewTab'
import PulseTab from 'src/components/OfferingTabs/PulseTab'
import PageTop from 'src/components/PageTop'
import Tabs, { Tab } from 'src/components/Tabs'
import { API, Path } from 'src/config/paths'
import useDataLoader from 'src/hooks/useDataLoader'
import LoadingIndicator from 'src/ui/LoadingIndicator'
import PageContent from 'src/ui/PageContent'

import CustomersTable from './CustomersTable'

const OfferingPage: React.FC = () => {
  const {
    offeringId, tabId
  } = useParams()

  const dataUrl = `${API}/api/offering/${offeringId}`
  const { data } = useDataLoader<OfferingApiResponse>(dataUrl)

  const tabs: Tab[] = [
    {
      path: generatePath(Path.OFFERING, { offeringId: offeringId ?? null }),
      label: 'Overview'
    },
    {
      path: generatePath(Path.OFFERING_TAB, { offeringId: offeringId ?? null, tabId: 'pulse' }),
      label: 'Pulse'
    },
    {
      path: generatePath(Path.OFFERING_TAB, { offeringId: offeringId ?? null, tabId: 'customers' }),
      label: 'Customers'
    }
  ]

  if (data === undefined) {
    return <LoadingIndicator fullPage/>
  }

  if (!data.offering) {
    return <ErrorPage title={`Offering not found with id ${offeringId}`} />
  }

  const { offering } = data

  return (
    <>
      <PageTop pageTitle={offering.label}/>
      <PageContent>
        <Tabs tabs={tabs} gridArea="tab"/>

        {tabId === undefined && (
          <OverviewTab entity={offering} type="offerings" />
        )}

        {tabId === 'pulse' && (
          <PulseTab entity={offering} />
        )}

        {tabId === 'customers' && (
          <CustomersTab
            entity={offering}
            lockedFilters={{ offerings: offering.external_id }}>
            <CustomersTable entity={offering}/>
          </CustomersTab>
        )}

      </PageContent>
    </>
  )
}

export default OfferingPage
