import React from 'react'
import { useLocalStorage } from 'react-use'

import { Currency } from 'src/ApiResponseTypes'

export type UserModel = {
  currency: Currency,
  changeCurrency: (currency: Currency) => void,
  disableAnimations: boolean,
  handleAnimationChange: (state: string) => void
}

const defaultCurrency: Currency = {
  label: 'EUR',
  external_id: 'EUR'
}

const defaultUserState: UserModel = {
  currency: defaultCurrency,
  changeCurrency: () => { },
  handleAnimationChange: () => { },
  disableAnimations: false
}

const UserContext = React.createContext(defaultUserState)

export const useUserSettings = (): UserModel => React.useContext(UserContext)

type UserStateStorage = {
  currency?: Currency,
  disableAnimations: boolean
}
const DEFAULT_STATE: UserStateStorage = { currency: defaultCurrency, disableAnimations: false }

export const UserSettingsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [userState, saveUserState] = useLocalStorage<UserStateStorage>('user-state', DEFAULT_STATE)
  const [selectedCurrency, setSelectedCurrency] = React.useState<Currency>(userState?.currency ?? defaultCurrency)
  const [disableAnimations, setDisableAnimations] = React.useState<boolean>(userState?.disableAnimations ?? DEFAULT_STATE.disableAnimations)

  const handleCurrencyChange = React.useCallback((currency: Currency) => {
    setSelectedCurrency(currency)
    saveUserState(prev => ({
      disableAnimations: false,
      ...prev,
      currency
    }))
  }, [])

  const handleAnimationChange = React.useCallback((state: string) => {
    const newVal = state === 'disabled'
    setDisableAnimations(newVal)
    saveUserState(prev => ({
      ...prev,
      disableAnimations: newVal
    }))
  }, [])

  const val = {
    currency: selectedCurrency,
    changeCurrency: handleCurrencyChange,
    disableAnimations,
    handleAnimationChange
  }

  return (
    <UserContext.Provider value={val}>
      {children}
    </UserContext.Provider>
  )
}
