import {
  flexRender,
  Row
} from '@tanstack/react-table'
import styled, { css } from 'styled-components'

import Tooltip from 'src/ui/Tooltip'
import { STYLED_CONFIG } from 'src/utils/styled-utils'

import { ExpandColumn } from './ExpandableTable'
import { COLUMN_RESIZER_WIDTH } from './Header'
import { ROW_HEIGHT } from './utils'

const StyledTableRow = styled.div`
  display: contents;

  &:last-child > * {
    border-bottom: none;
  }
`

type TextContentProps = { isResizeable: boolean }
export const TextContent = styled('div')
  .withConfig(STYLED_CONFIG)<TextContentProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props => props.isResizeable && css`
    padding-right: ${COLUMN_RESIZER_WIDTH}px;
  `}
`

const TableColumnComponent = styled.div`
  height: ${ROW_HEIGHT}px;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 14px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  padding: 0;

  &.selected {
    background-color: ${props => props.theme.navItemActiveBg};
    border-bottom: 1px solid ${props => props.theme.navItemActiveText};
  }
`

type TableRowProps<T> = {
  row: Row<T>,
  index: number,
  canExpand: boolean
}

const TableRow = <T,>({ row, index, canExpand }: TableRowProps<T>): JSX.Element => {
  const classes: string[] = []
  classes.push(index % 2 === 0 ? 'odd' : 'even')

  if (row.getIsSelected()) {
    classes.push('selected')
  }

  const expandChevronColumnIndex = row.getCanSelect() ? 1 : 0
  return (
    <StyledTableRow role="row">
      {row.getVisibleCells().map((cell, cellIndex) => {
        const cellContent = flexRender(cell.column.columnDef.cell, cell.getContext())
        /* TODO:
          This needs the logic on when to show tooltip.
          ideally this would be if the css text-overlay kicks in, so if user sees ellipsis -> show tooltip on hover

          const cellText = `${cell.getValue<string>()}`
          const shouldShowTooltip = cellText.length > 8
        */
        const shouldShowTooltip = false

        return (
          <TableColumnComponent
            data-testid={`row-${index}-column-${cellIndex}`}
            style={cell.column.columnDef.meta?.columnStyle}
            className={classes.join(' ')}
            key={cell.id}>
            <TextContent
              data-tooltip-id={`${index}-${cellIndex}`}
              data-tooltip-hidden={!shouldShowTooltip}
              data-tooltip-delay-show={200}
              isResizeable={cell.column.getCanResize()}>
              {canExpand && cellIndex === expandChevronColumnIndex
                ? (<ExpandColumn row={row}><>{cellContent}</></ExpandColumn>)
                : cellContent
              }
            </TextContent>
            {shouldShowTooltip && (
              <Tooltip id={`${index}-${cellIndex}`}>
                {cellContent}
              </Tooltip>
            )}
          </TableColumnComponent>
        )
      }
      )}
    </StyledTableRow>
  )
}

export default TableRow
