import React from 'react'
import { useLocalStorage, useUpdateEffect } from 'react-use'
import { isArray, isEmpty, omit } from 'lodash'

import { Filter } from 'src/components/Filters/types'
import { TABLE_QUERIES } from 'src/components/Table/ConnectedTable'

import { QueryState, useQueries } from './QueryProvider'

const defaultFiltersState: FiltersProviderResult = {
  selectedFilters: {},
  allFilters: [],
  toggleFilter: () => {},
  addFilter: () => {},
  addMultipleFilters: () => {},
  removeFilterValue: () => {},
  updateFilterValue: () => {},
  clearFilter: () => {},
  resetFilters: () => {},
  isFiltersOpen: true,
  toggleFiltersOpen: () => {}
}
const FiltersManager = React.createContext(defaultFiltersState)

export type MultiFilterChangeObject = {
  name: string,
  values: SelectedFilterValue
}

export type FilterChangeObject = {
  name: string,
  value: string | string[] | undefined
}

export type SelectedFilterValue = string | string[] | undefined

export type SelectedFilter = {
  name: string,
  value: string
}

type FilterName = string
export type SelectionState = Record<FilterName, SelectedFilterValue>

type FiltersProviderResult = {
  selectedFilters: SelectionState,
  allFilters: Filter[],
  toggleFilter: (props: SelectedFilter) => void,
  addFilter: (props: SelectedFilter) => void,
  addMultipleFilters: (props: MultiFilterChangeObject) => void,
  removeFilterValue: (props: SelectedFilter) => void,
  updateFilterValue: (props: SelectedFilter) => void,
  clearFilter: (name: string) => void,
  resetFilters: () => void,
  isFiltersOpen: boolean,
  toggleFiltersOpen: () => void
}

export const useFilters = (): FiltersProviderResult => React.useContext(FiltersManager)

export type FiltersProviderProps = {
  id: string,
  children?: React.ReactNode,
  initialValue?: Pick<FiltersProviderResult, 'allFilters'> & {
    selectedFilters?: QueryState,
    lockedFilters?: SelectionState
  }
}

type FiltersState = {
  isOpen: boolean
}

export const FiltersProvider = ({ id, children, initialValue }: FiltersProviderProps): JSX.Element => {
  const { queries, setQueries } = useQueries()

  const initialFilters = { ...initialValue?.selectedFilters, ...omit(queries, TABLE_QUERIES) }
  const [selectedFilters, setSelectedFilters] = React.useState<SelectionState>(initialFilters)
  const allFilters: Filter[] = initialValue?.allFilters ?? defaultFiltersState.allFilters

  const filterId = `${id}-filters`
  const [filtersState, saveFiltersState] = useLocalStorage<FiltersState>(filterId)
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(filtersState?.isOpen ?? false)

  const lockedFilters: SelectionState | undefined = initialValue?.lockedFilters

  useUpdateEffect(() => {
    const visibleFilters: QueryState = {}
    Object.entries(selectedFilters)
      .forEach(([name, value]) => {
        const hasValue = isArray(value) ? value.length > 0 : !isEmpty(value)
        if (hasValue) {
          visibleFilters[name] = value
        } else {
          visibleFilters[name] = undefined
        }
      })

    setQueries(visibleFilters)
  }, [selectedFilters])

  const handleToggleFilters = React.useCallback(() => {
    setIsFiltersOpen(prev => {
      const newState = !prev
      saveFiltersState({ isOpen: newState })
      return newState
    })
  }, [])

  const toggleFilter = React.useCallback(({ name, value }: SelectedFilter) => {
    setSelectedFilters(prev => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const addFilter = React.useCallback(({ name, value }: SelectedFilter) => {
    setSelectedFilters(prev => {
      const prevFilterValue = prev[name]
      const newVal: string[] = prevFilterValue
        ? [...(isArray(prevFilterValue) ? prevFilterValue : [prevFilterValue]), value]
        : [value]

      return {
        ...prev,
        [name]: newVal
      }
    })
  }, [])

  const addMultipleFilters = React.useCallback(({ name, values }: MultiFilterChangeObject) => {
    setSelectedFilters(prev => ({
      ...prev,
      [name]: values
    }))
  }, [])

  const updateFilterValue = React.useCallback(({ name, value }: SelectedFilter) => {
    setSelectedFilters(prev => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const removeFilterValue = React.useCallback(({ name, value }: SelectedFilter) => {
    setSelectedFilters(prev => {
      const prevValue = prev[name]
      if (!isArray(prevValue) || prevValue.length === 1) {
        return omit(prev, name)
      }

      return {
        ...prev,
        [name]: prevValue.filter(val => val !== value)
      }
    })
  }, [])

  const clearFilter = React.useCallback((name: string) => {
    setSelectedFilters(prev => ({
      ...prev,
      [name]: undefined
    }))
  }, [])

  const resetFilters = React.useCallback(() => {
    setSelectedFilters(prev => {
      const newState: SelectionState = { }
      Object.entries(prev)
        .forEach(([key, value]) => {
          newState[key] = undefined
        })

      return newState
    })
  }, [])

  const allViewFilters = React.useMemo(() => ({ ...selectedFilters, ...lockedFilters }), [selectedFilters, lockedFilters])
  return (
    <FiltersManager.Provider value={{
      selectedFilters: allViewFilters,
      allFilters,
      toggleFilter,
      addFilter,
      addMultipleFilters,
      removeFilterValue,
      updateFilterValue,
      clearFilter,
      resetFilters,
      isFiltersOpen,
      toggleFiltersOpen: handleToggleFilters
    }}>
      {children}
    </FiltersManager.Provider>
  )
}
