import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import Icon from 'src/components/Icon'
import { NavigationItem } from 'src/config/paths'

const NavItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;

  >:first-child {
    width: 50px;
    height: 50px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    flex: 1;
  }
`

type StyledNavLinkProps = { $isOpen: boolean }
const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
  text-decoration: none;
  font-weight: 400;
  color: ${props => props.theme.navItemText};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 48px;
  height: 48px;
  width: ${props => props.$isOpen ? 'auto' : '48px'};
  margin: ${props => props.$isOpen ? '4px 9px' : '4px 10px'};
  font-size: 16px;

  &:hover {
    background: ${props => props.theme.navItemHoverBg};
    color: ${props => props.theme.navItemHoverText};

    svg rect,
    svg circle,
    svg path {
      stroke: ${props => props.theme.navItemHoverText} !important;
    }
  }

  &.active {
    background: ${props => props.theme.navItemActiveBg};
    color: ${props => props.theme.navItemActiveText};

    svg rect,
    svg circle,
    svg path {
      stroke: ${props => props.theme.navItemActiveText} !important;
    }
  }
`

export type NavItemProps = NavigationItem & {
  navOpen: boolean
}

const NavItem: React.FC<NavItemProps> = (navItem: NavItemProps) => {
  const { icon, label, path, navOpen } = navItem

  if (!path) {
    return null
  }

  return (
    <StyledNavLink to={path} $isOpen={navOpen}>
      <NavItemRow>
        {icon !== undefined && <Icon name={icon} />}
        {navOpen && (<span>{label}</span>)}
      </NavItemRow>
    </StyledNavLink>
  )
}
export default NavItem
