import { isArray, isEmpty } from 'lodash'

export type QueryObject = Record<string, any>

export const createQueryParams = (obj: QueryObject): string => {
  const query: Record<string, any> = new URLSearchParams()

  Object.entries(obj)
    .forEach(([key, value]) => {
      if (!isEmpty(value)) {
        query.append(key, value)
      }
    })

  return `${decodeURIComponent(query.toLocaleString())}`
}

export const createApiQueryParams = (obj: QueryObject): string => {
  const query: Record<string, any> = new URLSearchParams()

  Object.entries(obj).forEach(([key, value]) => {
    if (isArray(value)) {
      value.forEach((val) => {
        query.append(`${key}[]`, val)
      })
    } else if (value !== undefined) {
      query.append(key, value)
    }
  })

  return `${decodeURIComponent(query.toLocaleString())}`
}

export const getQueryParams = (query: string): QueryObject | undefined => {
  const searchParams = new URLSearchParams(query)
  const parameters = Array.from(searchParams.entries())
  const filters: QueryObject = {}
  parameters.forEach(([key, val]) => {
    const isArray = val.split(',').length > 1
    if (isArray) {
      filters[key] = val.split(',')
    } else {
      filters[key] = val
    }
  })

  if (!isEmpty(filters)) {
    return filters
  }
}
