import React from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { BusinessGroupApiResponse } from 'src/ApiResponseTypes'
import ErrorPage from 'src/components/ErrorPage'
import CustomersTab from 'src/components/OfferingTabs/CustomersTab'
import OverviewTab from 'src/components/OfferingTabs/OverviewTab'
import PulseTab from 'src/components/OfferingTabs/PulseTab'
import PageTop from 'src/components/PageTop'
import Tabs, { Tab } from 'src/components/Tabs'
import { API, Path } from 'src/config/paths'
import useDataLoader from 'src/hooks/useDataLoader'
import LoadingIndicator from 'src/ui/LoadingIndicator'
import PageContent from 'src/ui/PageContent'

import CustomersTable from './CustomersTable'

const BusinessGroup: React.FC = () => {
  const { businessGroupId, tabId } = useParams()

  const dataUrl = `${API}/api/offering/${businessGroupId}`
  const { data, error } = useDataLoader<BusinessGroupApiResponse>(dataUrl)

  const tabs: Tab[] = [
    {
      path: generatePath(Path.BUSINESS_GROUP, { businessGroupId: businessGroupId ?? null }),
      label: 'Overview'
    },
    {
      path: generatePath(Path.BUSINESS_GROUP_TAB, { businessGroupId: businessGroupId ?? null, tabId: 'pulse' }),
      label: 'Pulse'
    },
    {
      path: generatePath(Path.BUSINESS_GROUP_TAB, { businessGroupId: businessGroupId ?? null, tabId: 'customers' }),
      label: 'Customers'
    }
  ]

  if (data === undefined && !error) {
    return <LoadingIndicator fullPage />
  }

  // Fetch fails before we reach here
  if (error || !data?.offering) {
    return <ErrorPage title={`Business group not found with id ${businessGroupId}`} />
  }

  const { offering: businessGroup } = data // Why is this called "offering", should it be "business_group"?
  return (
    <>
      <PageTop pageTitle={businessGroup.label}/>
      <PageContent>
        <Tabs tabs={tabs} gridArea="tab"/>
        {tabId === undefined && (
          <OverviewTab entity={businessGroup} type="business_groups" />
        )}

        {tabId === 'pulse' && (
          <PulseTab entity={businessGroup} />
        )}

        {tabId === 'customers' && (
          <CustomersTab
            entity={businessGroup}
            lockedFilters={{ business_groups: businessGroup.external_id }}>
            <CustomersTable entity={businessGroup}/>
          </CustomersTab>
        )}

      </PageContent>
    </>
  )
}

export default BusinessGroup
