import React from 'react'

import { API } from 'src/config/paths'
import { useFilters } from 'src/providers/FiltersProvider'
import { MenuItem } from 'src/ui/Menu'
import { createApiQueryParams } from 'src/utils/queryParams'

import Icon from '../Icon'

const LoadCompaniesToExcel: React.FC = () => {
  const { selectedFilters } = useFilters()
  const apiParams = createApiQueryParams(selectedFilters ?? {})
  const linkUrl = `${API}/api/companies/export.xlsx?${apiParams}`

  const handleClick = React.useCallback(() => {
    window.open(linkUrl)
  }, [linkUrl])

  return (
    <MenuItem onClick={handleClick}>
      <Icon name="ShareDown" size={20} /> Load to Excel
    </MenuItem>
  )
}

export default LoadCompaniesToExcel
