import styled, { useTheme } from 'styled-components'

import Icon from 'src/components/Icon'
import { useAppState } from 'src/providers/AppStateProvider'

const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 25px 10px;
`

const MenuToggleButton: React.FC = () => {
  const { toggleNav } = useAppState()
  const theme = useTheme()

  return (
    <MenuButton onClick={toggleNav} data-testid="menu-toggle-btn">
      <Icon name="MenuToggle" color={theme.menuIcon} />
    </MenuButton>
  )
}

export default MenuToggleButton
