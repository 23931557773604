import { ChangeEventHandler } from 'react'
import styled from 'styled-components'

const StyledCheckbox = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  padding:0;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    border-radius: 3px;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: ${props => props.theme.lightGray};
    display: block;
    border: 1px solid ${props => props.theme.text};
    box-sizing: border-box;
  }

  input:checked ~ .checkmark {
    background-color: ${props => props.theme.brandPurple};
    border: 1px solid ${props => props.theme.brandPurple};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 4px;
    top: -1px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg) translate(-4%, 7%);
  }

  .indeterminate {
    position: absolute;
    border-radius: 3px;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    display: block;
    box-sizing: border-box;
    background: ${props => props.theme.brandPurple};
  }

  .indeterminate:after {
    position: absolute;
    content: '';
    left: 3px;
    top: 50%;
    width: 10px;
    height: 3px;
    background: ${props => props.theme.invertText};
    transform: translateY(-50%);
  }
`

type CheckboxProps = {
  checked?: boolean,
  indeterminate?: boolean,
  onChange?: ChangeEventHandler<HTMLInputElement>,
  name?: string,
  readOnly?: boolean
}

const Checkbox = ({ checked, onChange, name, indeterminate, readOnly }: CheckboxProps): JSX.Element => (
  <StyledCheckbox className="container">
    <input
      readOnly={readOnly}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      name={name}/>
    <span className="checkmark" />
    {!checked && indeterminate && (<span className="indeterminate" />)}
  </StyledCheckbox>
)

export default Checkbox
