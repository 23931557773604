import React from 'react'

import { ReactChildren } from 'src/types'
import Box from 'src/ui/Box'
import PageContent from 'src/ui/PageContent'

import PageTop from './PageTop'

type ErrorPageProps = {
  title: string,
  children?: ReactChildren
}

const ErrorPage: React.FC<ErrorPageProps> = ({ children, title }: ErrorPageProps) => {
  return (
    <>
      <PageTop pageTitle={title}/>
      {children && (
        <PageContent>
          <Box gridArea="left" gridColumn="left / right">
            {children}
          </Box>
        </PageContent>
      )}
    </>
  )
}

export default ErrorPage
