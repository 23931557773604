import React, { MouseEvent } from 'react'
import {
  flexRender,
  Header as ReactTableHeader,
  Table as ReactTable
} from '@tanstack/react-table'
import styled, { css } from 'styled-components'

import Icon from 'src/components/Icon'
import { STYLED_CONFIG } from 'src/utils/styled-utils'

import ChevronButton from './ExpandableTable/ChevronButton'

export const COLUMN_RESIZER_WIDTH = 14
const TextContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`

const ColumnResizeHandle = styled.div`
  height: 100%;
  width: ${COLUMN_RESIZER_WIDTH}px;
  cursor: col-resize;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 0;

  &:after {
    content: '';
    width: 1px;
    height: 100%;
    background: ${props => props.theme.borderColor};
  }

  &:hover:after,
  &.isResizing:after {
    width: 2px;
    background: ${props => props.theme.info};
  }
`

const THead = styled.div`
  color: ${props => props.theme.text};
  user-select: none;
  border-top: 1px solid ${props => props.theme.borderColor};
  border-bottom: 1px solid ${props => props.theme.borderColor};
  background: ${props => props.theme.contentBackground};
  position: relative;
  z-index: 10;

  .sort-icon {
    width: 18px;
    margin: 4px -3px 0 4px;
    flex-shrink: 0;

    svg {
      max-width: 100%;
      max-height: 100%;

      rect, path {
        stroke: ${props => props.theme.borderColor};
      }
    }
  }
`

export const MultilineHeader = styled.div`
  font-size: 14px;
`

const TheadWrapper = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  font-weight: normal;
  white-space: nowrap;
  border-right: 1px solid ${props => props.theme.contentBackground};
  font-size: 16px;
`

type TextContentProps = { isResizeable: boolean }
export const TheadContent = styled('div')
  .withConfig(STYLED_CONFIG)<TextContentProps>`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0;

  ${props => props.isResizeable && css`
    width: calc(100% - ${COLUMN_RESIZER_WIDTH}px);
  `}

  &.canSort {
    cursor: pointer;
  }
`

type HeaderProps<T> = {
  header: ReactTableHeader<T, unknown>,
  table: ReactTable<T>,
  index: number,
  style: React.CSSProperties
}

const Header = <T,>({ table, header, index, style }: HeaderProps<T>): JSX.Element => {
  const [isResizing, setIsResizing] = React.useState(false)
  // TODO remove external react state, use react tables internal state instead
  // --> header.column.getIsResizing()

  const handleResizeDoubleClick = React.useCallback(() => {
    table.resetColumnSizing()
  }, [])

  const stopDrag = React.useCallback(() => {
    setIsResizing(false)
    document.removeEventListener('mouseup', stopDrag)
  }, [])

  const dragging = React.useCallback((event: MouseEvent<HTMLDivElement>) => {
    if (isResizing) {
      const resizeHandler = header.getResizeHandler()
      resizeHandler(event)
    }
  }, [isResizing])

  const handleResizeStart = React.useCallback((event: MouseEvent<HTMLDivElement>) => {
    const headerKey = header.id

    // TODO: Clean up this function
    let found = false
    const headersToLock: Array<ReactTableHeader<T, unknown>> = []
    table.getHeaderGroups()[0].headers.forEach(header => {
      if (!found) {
        headersToLock.push(header)
      }
      if (header.id === headerKey) {
        found = true
      }
    })

    const lockedWidths: Record<string, number> = {}
    headersToLock.forEach((header) => {
      const th = document.querySelector(`[data-headerid="${header.id}"]`)
      const size = th?.getBoundingClientRect().width
      lockedWidths[header.id] = size ?? 0
    })

    table.setColumnSizing(prev => ({ ...prev, ...lockedWidths }))
    setIsResizing(true)
    document.addEventListener('mouseup', stopDrag)
  }, [])

  const headers = table.getHeaderGroups()[0].headers
  const canExpand = table.getCanSomeRowsExpand()
  const expandChevronColumnIndex = table.options.enableRowSelection ? 1 : 0

  return (
    <THead
      role="columnheader"
      key={header.id}
      data-headerid={header.id}
      style={style}>
      <TheadWrapper>

        <TheadContent
          {...{
            className: header.column.getCanSort()
              ? 'canSort'
              : '',
            onClick: header.column.getToggleSortingHandler()
          }}
          isResizeable={header.column.getCanResize()}
        >
          {canExpand && index === expandChevronColumnIndex && (
            <ChevronButton
              onClick={table.getToggleAllRowsExpandedHandler()}
              isOpen={table.getIsAllRowsExpanded()}/>
          )}
          <TextContent {...{ style: header.column.columnDef.meta?.headerStyle }}>
            {flexRender(
              header.column.columnDef.header,
              header.getContext()
            )}
          </TextContent>
          {{
            asc: <Icon className="sort-icon" name="Move_down" />,
            desc: <Icon className="sort-icon" name="Move_up" />
          }[header.column.getIsSorted() as string] ?? null}
          {header.column.getCanSort() && table.getState().sorting.length === 0 ? <Icon className="sort-icon" name="Move" /> : null}
        </TheadContent>

        {index !== headers.length - 1 && header.column.getCanResize() && (
          <ColumnResizeHandle
            onDoubleClick={handleResizeDoubleClick}
            onMouseDown={handleResizeStart}
            onMouseMove={dragging}
            onTouchStart={header.getResizeHandler()}
            className={`${
              isResizing ? 'isResizing' : ''
            }`}
          />)}
      </TheadWrapper>
    </THead>
  )
}
export default Header
