import React from 'react'
import {
  PaginationState,
  Table as ReactTable
} from '@tanstack/react-table'
import numeral from 'numeral'
import styled from 'styled-components'

import { ReactChildren } from 'src/types'
import Dropdown from 'src/ui/Dropdown'
import ErrorText from 'src/ui/ErrorText'
import Flex from 'src/ui/Flex'
import LoadingIndicator from 'src/ui/LoadingIndicator'
import Menu from 'src/ui/Menu'
import { DEFAULT_NUMERAL_FORMAT } from 'src/utils/formatting'

import Filters from '../Filters'
import FilterButton from '../Filters/FilterButton'
import FilterCount from '../Filters/FilterCount'

import PageSizeSelect from './PageSizeSelect'
import Pagination from './Pagination'
import TableBody from './TableBody'
import TableHead from './TableHead'
import { ROW_HEIGHT, VERTICAL_ROW_HEIGHT } from './utils'

const TABLE_BOTTOM_HEIGHT = 48
const TABLE_TITLE_HEIGHT = 50

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${props => props.theme.contentBackground};
  overflow: hidden;
  min-height: calc(${TABLE_BOTTOM_HEIGHT}px + ${ROW_HEIGHT * 2}px);
  display: flex;
  flex-direction: column;
`

const StyledTable = styled.div`
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TableTitleRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  overflow: hidden;
  height: ${TABLE_TITLE_HEIGHT}px;
`

const TableTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
`

const TableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin: 0 0 0 12px;
  }
`

const FilterActions = styled(Flex)`
  align-items: flex-start;
  flex-direction: column;
  width: auto;
`

const TableAndFilters = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - ${TABLE_TITLE_HEIGHT}px);
  flex-direction: row;

  > *:last-child:not(:only-child) {
    margin-left: 0;
    border-left: 1px solid ${props => props.theme.borderColor};
    padding: 16px;
    flex: 0 0 auto;
  }
`

const TableComponent = styled.div`
  width: 100%;
  margin: 0;
  font-size: 15px;
  color: ${props => props.theme.text};
  display: grid;
  overflow: auto;
`

const TableFooter = styled.div`
  height: ${TABLE_BOTTOM_HEIGHT}px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px;
  border-top: 1px solid ${props => props.theme.borderColor};
  flex: 0 0 auto;

  p {
    font-size: 12px;
    padding:0;
    margin:0;
  }
`
const FooterPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin-right: 12px;
  }
`

const LoadingIndicatorWrapper = styled.div`
  height: 100%;
  width: 100%;
  grid-column: 1 / -1;
  grid-row: 20 / 2;
`

const EmptyTableIndicatorWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  grid-column: 1 / -1;
  grid-row: 20 / 2;
  min-height: ${ROW_HEIGHT}px;
`

export type TableState = {
  pagination?: PaginationState
}

export type BaseTableProps<T> = {
  table: ReactTable<T>,
  autoHeight?: boolean,
  isLoading?: boolean,
  title?: string,
  actions?: ReactChildren,
  secondaryActions?: ReactChildren,
  showFilters?: boolean,
  error?: Error
}

export const MemoizedTableBody = React.memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
) as typeof TableBody

const BaseTable = <T,>({
  table,
  autoHeight = false,
  isLoading = false,
  title,
  actions,
  secondaryActions,
  showFilters = false,
  error
}: BaseTableProps<T>): JSX.Element => {
  const [scrollPos, setScrollPos] = React.useState(0)
  const { columnSizingInfo, columnSizing, pagination } = table.getState()
  const headers = table.getFlatHeaders()

  const hasVerticalColumns: boolean = React.useMemo(() => headers.find((h) => h.column.columnDef.meta?.type) !== undefined, [headers])

  const gridLayout: string = React.useMemo(() => {
    // Column is flexible if meta.flex has a value AND user has not resized it or columns after it
    const flexColumns = table.getFlatHeaders()
      .filter((header) => header.getContext().column.columnDef.meta?.flex && !columnSizing[header.id])

    const hasFlexColumns = flexColumns.length > 0

    let columns = ''

    headers.forEach((header, index) => {
      const headerId = header.getContext().column.columnDef.id ?? ''
      const hasBeenResized = columnSizing[headerId]
      let flexVal = header.getContext().column.columnDef.meta?.flex

      if (!hasFlexColumns && index === 0) {
        flexVal = 1
      }

      if (columnSizing[header.id]) {
        // User has set the width
        columns += `${header.getSize()}px `
      } else if (index === headers.length - 1 && !hasFlexColumns) {
        // Last column should take the rest of the space
        columns += `minmax(${header.getSize()}px, 1fr) `
      } else {
        columns += flexVal !== undefined && !hasBeenResized
          ? `minmax(${header.getSize()}px, ${flexVal}fr) `
          : `${header.getSize()}px `
      }
    })

    return columns
  }, [columnSizingInfo, columnSizing, headers])

  const handleScroll = React.useCallback((el: any) => {
    const scrollTop: number = el.target.scrollTop
    setScrollPos(scrollTop)
  }, [])

  const firstItemIndex = pagination.pageIndex * pagination.pageSize
  const lastItemIndex = (pagination.pageIndex + 1) * pagination.pageSize
  const totalRowCount = table.getRowCount()
  const rowSpace = Math.max(totalRowCount * ROW_HEIGHT, ROW_HEIGHT)
  const isEmpty = totalRowCount === 0

  const conditionalStyles = autoHeight ? { height: `${rowSpace + ROW_HEIGHT + TABLE_BOTTOM_HEIGHT}px` } : {}

  return (
    <TableWrapper role="table" data-testid={`table-${title ?? 'unnamed'}`}>
      <TableTitleRow>
        <div>{title && (<TableTitle>{title}</TableTitle>)}</div>
        <TableActions>
          {actions}
          <FilterActions>
            {showFilters && (<FilterButton />)}
            {showFilters && (<FilterCount />)}
          </FilterActions>
          {secondaryActions && (
            <Dropdown overlay={(<Menu mode="vertical">{secondaryActions}</Menu>)}/>
          )}
        </TableActions>
      </TableTitleRow>
      <TableAndFilters>
        <StyledTable style={conditionalStyles}>
          <TableComponent
            onScroll={handleScroll}
            {...{
              style: {
                gridTemplateColumns: gridLayout,
                height: isLoading || error || isEmpty ? '100%' : 'auto'
              }
            }}>
            <TableHead
              style={{
                transition: 'transform 0.1s ease',
                transform: `translateY(${scrollPos}px)`,
                height: hasVerticalColumns ? `${VERTICAL_ROW_HEIGHT}px` : `${ROW_HEIGHT}px`
              }}
              table={table} />
            {!isLoading && isEmpty && !error && (
              <EmptyTableIndicatorWrapper>
                <strong>No data</strong>
              </EmptyTableIndicatorWrapper>
            )}
            {!isLoading && error && (
              <EmptyTableIndicatorWrapper>
                <ErrorText text={error.message} />
              </EmptyTableIndicatorWrapper>
            )}
            {isLoading && (
              <LoadingIndicatorWrapper>
                <LoadingIndicator fullPage/>
              </LoadingIndicatorWrapper>
            )}
            {!isLoading && (
              columnSizingInfo.isResizingColumn !== false
                ? (
                  <MemoizedTableBody table={table} />
                )
                : (
                  <TableBody table={table} />
                )
            )}
          </TableComponent>

          <TableFooter>
            <FooterPagination>
              <div>
                <p>
                  {firstItemIndex} - {lastItemIndex < totalRowCount ? lastItemIndex : totalRowCount } of <span data-testid="table-total-value">{numeral(totalRowCount).format(DEFAULT_NUMERAL_FORMAT)}</span>
                </p>
              </div>
              <Pagination table={table}/>
            </FooterPagination>
            <PageSizeSelect table={table}/>
          </TableFooter>
        </StyledTable>
        {showFilters && (
          <Filters showFilterButtonWhenOpen={false} error={error}/>
        )}
      </TableAndFilters>
    </TableWrapper>
  )
}

export default BaseTable
