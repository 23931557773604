import React from 'react'

import { ReactChild } from 'src/types'
import Box from 'src/ui/Box'

type ErrorBoundaryProps = {
  error?: Error,
  fallback?: ReactChild | string,
  children: ReactChild
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ error, children, fallback }: ErrorBoundaryProps) => {
  if (error) {
    console.error(error.stack)
  }
  if (error) {
    if (fallback) {
      return (
        <>
          {fallback}
        </>
      )
    }

    return (
      <Box>
        <h3>{error.message}</h3>
      </Box>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

export default ErrorBoundary
